import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import Input from '../../../../forms/components/Input';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectCrew, selectStowaways, updateCrew, updatePortCall } from '../../../slices/portCallSlice';
import "../styles/specify-crew-number.scss";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const validationSchema = Yup.object({
  arrivalCrewQuantity: Yup.number().nullable().min(0, "Must be a positive number"),
  departureCrewQuantity: Yup.number().nullable().min(0, "Must be a positive number"),
  arrivalStowaways: Yup.number().nullable().min(0, "Must be a positive number"),
  departureStowaways: Yup.number().nullable().min(0, "Must be a positive number")
})

function SpecifyCrewNumber() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { arrivalCrewQuantity, departureCrewQuantity } = useSelector(selectCrew);
  const {arrivalStowaways, departureStowaways} = useSelector(selectStowaways);

  const [initialValues, setInitialValues] = useState({
    arrivalCrewQuantity,
    departureCrewQuantity,
    arrivalStowaways,
    departureStowaways
  });

  useEffect(() => {
    setInitialValues({
      arrivalCrewQuantity,
      departureCrewQuantity,
      arrivalStowaways,
      departureStowaways
    });
  }, [arrivalCrewQuantity, departureCrewQuantity, arrivalStowaways, departureStowaways]);

  useEffect(() => {   
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef]);


  const handleSubmit = async (values, onSubmitProps) => {   
    const { arrivalCrewQuantity, departureCrewQuantity, arrivalStowaways, departureStowaways } = values;
    dispatch(updateCrew({
      uid: params.uid, 
      data: {
        arrivalCrewQuantity: arrivalCrewQuantity || initialValues?.arrivalCrewQuantity,
        departureCrewQuantity: departureCrewQuantity || initialValues?.departureCrewQuantity
      }
    }));
    dispatch(updatePortCall({
      arrivalStowaway: parseInt(arrivalStowaways),
      departureStowaway: parseInt(departureStowaways)
    }));
  };

  return (
    <>
        <div className="specify-crew-number">
          <PageHeader
            title={"Crew"}
            subtitle={`How many crew members are on board?`} />
          <Formik
            innerRef={formRef}
            onSubmit={handleSubmit}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}>
            <Form className="specify-crew-number-form">
              <div className="specify-crew-number-form__row">
                <Input name="arrivalCrewQuantity" type="number" min={0} placeholder="0" label="At arrival" />
                <Input name="departureCrewQuantity" type="number" min={0} placeholder="0" label="At departure" />
              </div>

              <div className="specify-stowaways-form">
                <label>How many stowaways are on board?</label>
                <div className="specify-stowaways-form__row">
                  <Input name="arrivalStowaways" type="number" min={0} placeholder="0" label="At arrival" />
                  <Input name="departureStowaways" type="number" min={0} placeholder="0" label="At departure" />
                </div>
              </div>

              <SkipAndNextButtons
                next={`/${params.uid}/crew/arrival-crew`}
                back={`/${params.uid}/crew/confirm`}
                onClick={()=>navigate(`/${params.uid}/crew/arrival-crew`)}
            />
            </Form>
          </Formik>
        </div>   
    </>
  );
}

export default SpecifyCrewNumber;
