import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { format } from 'date-fns';
import { API_REQUEST_FORMAT_DATE, formatDateInTable } from '../../../../global/utils/datetimes';
import { selectPassengers, updatePassengers } from '../../../slices/portCallSlice';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import Table from '../../../../tables/components/Table';
import Button from '../../../../layout/components/Button';
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import Modal from "../../../../layout/components/Modal";
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import DatePicker from '../../../../forms/components/DatePicker';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { genderOptions, idNatureOptions } from '../../../../global/utils/globalOptions';
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import { useParams } from 'react-router-dom';
import PlaceOfBirthDropdown from '../../../../forms/components/place-of-birth/components/PlaceOfBirthDropdown';
import SearchPortsDropdown from "../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown";
import "../styles/passengers-list-table.scss";
import DragAndDrop from "../../../../forms/components/DragAndDrop";
import {DocumentCategory, DocumentSubCategory} from "../../documents/constants";
import { validateSchema } from '../../../../global/utils/validateForms';
import ConfirmationModal from '../../../../layout/components/ConfirmationModal';
import { cleanEmptyData } from '../../../../global/utils/cleanEmptyData';
import {DatePickerTimeInput} from "@navozyme/uikit/dist/molecules/DatePicker";
import {Field} from "@navozyme/uikit/dist/atoms/Field";


function DeparturePassengersTable(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const passengers = useSelector(selectPassengers);

  const [isModalOpen, setIsModalOpen] = useState(false)


  const columns = [
    {
      Header: "Family Name",
      accessor: "surname",
    },
    {
      Header: "Given Names",
      accessor: "name"
    },
    {
      Header: "Nationality",
      accessor: "nationality",
      wrap: true,
    },
    {
      Cell: formatDateInTable,
      Header: "Date of Birth",
      accessor: "birthDate"
    },
    {
      Header: "Place of Birth",
      accessor: "birthPlace",
      width: "200"
    },
    {
      Header: "Gender",
      accessor: "gender"
    },
    {
      Header: "Nature Of Identity Number",
      accessor: "IDNature",
      width: "250"
    },
    {
      Header: "ID Number",
      accessor: "IDNumber"
    },
    {
      Header: "Issuing State",
      accessor: "IDIssuingState"
    },
    {
      Cell: formatDateInTable,
      Header: "Expiry Date",
      accessor: "IDExpiryDate"
    },
    {
      Header: "Port of embarkation",
      accessor: "portOfEmbarkationDropdown.name",
      width: "250"
    },
    {
      Header: "Visa number",
      accessor: "europeanVISANumber"
    },
    {
      Header: "Port of disembarkation",
      accessor: "portOfDisembarkationDropdown.name",
      width: "250"
    },
    {
      Header: "Transit passenger (Y/N)",
      accessor: "transitPassenger",
      width: "250",
      Cell: props => { return props.value ? "Yes" : "No" }
    }
  ]

  let initialValues = {
    surname: "",
    name: "",
    rank: "",
    nationality: "",
    gender: "",
    birthDate: "",
    birthPlace: "",
    IDNature: "",
    IDNumber: "",
    IDExpiryDate: "",
    IDIssuingState: "",
    portOfEmbarkation: "",
    europeanVISANumber: "",
    portOfDisembarkation: "",
    transitPassenger: false
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const validationSchema = Yup.object({
    surname: Yup.string().required("*This field is required"),
    name: Yup.string().required("*This field is required"),
    rank: Yup.string().required("*Please select one"),
    nationality: Yup.string().required("*This field is required"),
    gender: Yup.string().required("*Please select one"),
    birthDate: Yup.string().required("*This field is required"),
    IDNature: Yup.string().required("*Please select one"),
    IDNumber: Yup.string().required("*This field is required"),
    IDExpiryDate: Yup.string().required("*This field is required"),
    IDIssuingState: Yup.string().required("*Please select one")
  });

  const handleSubmit = async (values, onSubmitProps) => {
    const finalValues = {
      ...values,
      IDExpiryDate: values.IDExpiryDate instanceof Date ? format(new Date(values.IDExpiryDate), API_REQUEST_FORMAT_DATE) : '',
      birthDate: values.birthDate instanceof Date ? format(new Date(values.birthDate), API_REQUEST_FORMAT_DATE) : '',
      birthPlace: values.birthPlace.value ? values.birthPlace.value : values.birthPlace
    }

    if (values.portOfEmbarkationDropdown) {
      finalValues.portOfEmbarkation = values.portOfEmbarkationDropdown.unlocode;
    }

    if (values.portOfDisembarkationDropdown) {
      finalValues.portOfDisembarkation = values.portOfDisembarkationDropdown.unlocode;
    }

    cleanEmptyData(finalValues);
    if (isEditingRow) {
      const newMembers = passengers.departure.map((member, i) => {
        if (i === editingRow) {
          return finalValues
        }
        return member;
      })

      dispatch(updatePassengers({
        uid: params.uid,
        data: {
          departure: newMembers
        }
      }));
      setIsModalOpen(false);
      setEditingRow(null);
    } else {
      dispatch(updatePassengers({
        uid: params.uid,
        data: {
          departure: passengers.departure.concat(finalValues)
        }
      }));
      setIsModalOpen(false);
    }
  };

  const transitPassengersOptions = booleanOptionsForRadioGroup()

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const row = isEditingRow ? passengers.departure[editingRow] : {};
  initialValues = {
    surname: row.surname ? row.surname : "",
    name: row.name ? row.name : "",
    rank: row.rank ? row.rank : "",
    nationality: row.nationality ? row.nationality : "",
    gender: row.gender ? row.gender : "",
    birthDate: row.birthDate ? new Date(row.birthDate) : null,
    birthPlace: row.birthPlace ? { label: row.birthPlace, value: row.birthPlace } : "",
    IDNature: row.IDNature ? row.IDNature : "",
    IDNumber: row.IDNumber ? row.IDNumber : "",
    IDExpiryDate: row.IDExpiryDate ? new Date(row.IDExpiryDate) : null,
    IDIssuingState: row.IDIssuingState ? row.IDIssuingState : "",
    portOfEmbarkationDropdown: row.portOfEmbarkationDropdown ? row.portOfEmbarkationDropdown : "",
    europeanVISANumber: row.europeanVISANumber ? row.europeanVISANumber : "",
    portOfDisembarkationDropdown: row.portOfDisembarkationDropdown ? row.portOfDisembarkationDropdown : "",
    transitPassenger: row.transitPassenger ? row.transitPassenger : ""
  };

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);




  return (
    <div className="passenger-list-table">
      <div className='passenger-list-table__header'>
        <div className="passenger-list-table__header-title">
          <h2 className='passenger-list-title'>Passengers list on departure</h2>
          <DragAndDrop
              title={"Complete the table or upload the passengers list in PDF format."}
              openModalButtonText ={"Upload passengers list"}
              uploadButtonText={"Upload"}
              multiple={false}
              acceptedFormats={[".pdf"]}
              category={"preArrivalDocuments"}
              documentTypeId={"DUE-CAPTAIN-0012"}
              documentCategory={DocumentCategory.DUE_DOCUMENTS}
              documentSubCategory={DocumentSubCategory.Captain}
              documentTypeName={"Departure Passenger List"}
          />
        </div>
      </div>
      <Table
        useRowNumbers
        title={'Passengers list (departure)'}
        addButtonText={'Add passenger'}
        data={passengers?.departure}
        columns={columns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex
          })
        }}
      />
      <Modal
        className={`add-passenger-member-modal`}
        title={"Add a passenger"}
        showModal={isModalOpen}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}>
        <ConfirmationModal
          showModal= {isConfirmationModalOpen}
          handleCloseModal= {setIsConfirmationModalOpen}
          willDisableSave= {formValues}
          onAcceptModal= {() => {
            setIsConfirmationModalOpen(false);
            setIsModalOpen(false);
            handleSubmit(formValues)
          }}
          className='' />
        <div className="add-passenger-member-modal__content">
          <Formik
            onSubmit={handleSubmit}
            enableReinitialize
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validate={(values) => {
              return validateSchema(values, validationSchema, setFormValues, setIsConfirmationModalOpen, setIsModalOpen)
            }}>
            {({values, setValues, errors})=>{
              return (
                <Form className="add-passenger-member-form">
                  <div className="add-passenger-member-form__row">
                    <Input name="surname" placeholder="Type one" label="Family Name" />
                    <Input name="name" placeholder="Type one" label="Given Names" />
                  </div>
                  <div className="add-passenger-member-form__row">
                    <MultiSelect
                      name="nationality"
                      options={countryListAllIsoData}
                      isMulti={false}
                      label="Nationality"
                      placeholder="Choose" />
                    <MultiSelect
                      name="gender"
                      options={genderOptions}
                      isMulti={false}
                      label="Gender"
                      placeholder="Choose" />
                  </div>
                  <div className="add-passenger-member-form__row">
                    <Field label="Date of birth" className="field_date_picker">
                      <DatePickerTimeInput
                          key={initialValues?.birthDate + "birthDate"}
                          name="birthDate"
                          sizeInput="large"
                          maxDate={new Date()}
                          showTime={false}
                          defaultValue={
                            initialValues?.birthDate
                          }
                          action={({ isoDate }) =>
                              setValues({...values, birthDate: new Date(isoDate)})
                          }
                          hasError={errors?.birthDate}
                          errorMessage={errors?.birthDate}
                      />
                    </Field>
                    <PlaceOfBirthDropdown name="birthPlace" dropdownID="departurePassengerBirthPlace" />
                  </div>
                  <div className="add-passenger-member-form__row">
                    <MultiSelect
                      name="IDNature"
                      options={idNatureOptions}
                      isMulti={false}
                      label="Type of identity or travel document"
                      placeholder="Choose one" />
                    <Input name="IDNumber" placeholder="Type one" label="Serial number of identity or travel document" />
                  </div>
                  <div className="add-passenger-member-form__row">
                    <Field label="Expiry date of identity or travel document" className="field_date_picker">
                      <DatePickerTimeInput
                          key={initialValues?.IDExpiryDate + "IDExpiryDate"}
                          name="IDExpiryDate"
                          sizeInput="large"
                          minDate={new Date()}
                          showTime={false}
                          defaultValue={
                            initialValues?.IDExpiryDate
                          }
                          action={({ isoDate }) =>
                              setValues({...values, IDExpiryDate: new Date(isoDate)})
                          }
                          hasError={errors?.IDExpiryDate}
                          errorMessage={errors?.IDExpiryDate}
                      />
                    </Field>
                    <MultiSelect
                      name="IDIssuingState"
                      options={countryListAllIsoData}
                      isMulti={false}
                      label="Issuing State of identity or travel document"
                      placeholder="Choose" />
                    {/* <Input name="IDIssuingState" placeholder="Type one" label="Issuing State of identity or travel document" /> */}
                  </div>
                  <div className="add-passenger-member-form__row">
                    <SearchPortsDropdown
                      name="portOfEmbarkationDropdown"
                      dropdownID="portOfEmbarkationDropdown"
                      label="Port of embarkation"
                      placeholder="Type One" />
                    <Input name="europeanVISANumber" placeholder="Type one" label="Visa Number if appropriate" />
                  </div>
                  <div className="add-passenger-member-form__row">
                    <SearchPortsDropdown
                      name="portOfDisembarkationDropdown"
                      dropdownID="portOfDisembarkationDropdown"
                      label="Port of disembarkation"
                      placeholder="Type One" />
                    <RadioButtonsCustom name="transitPassenger" label="Are they a transit passenger or not" options={transitPassengersOptions} />
                  </div>
                  {
                    isEditingRow ? (
                      <div className="add-member__form-submission-btns">
                        <div className="discard-btn" onClick={() => {
                          setIsModalOpen(false);
                          setTimeout(() => setEditingRow(null), 300)
                        }}>Discard</div>
                        <Button type="submit">Change Information</Button>
                      </div>
                    ) : (
                      <div className="add-member__form-submission-btns">
                        <Button iconLeft={plusIcon} type="submit">Add</Button>
                      </div>
                    )
                  }
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to <span>delete all the <br />crew members</span> from the list?</h3>}
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          setShowConfirmDeleteAllMembersModal(false);

          dispatch(updatePassengers({
            uid: params.uid,
            data: {
              departure: []
            }
          }));
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this crew member?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          })
        }}
        onDeleteClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          })


          const departure = [...passengers.departure];
          departure.splice(confirmDeleteMemberModalData.index, 1);
          dispatch(updatePassengers({
            uid: params.uid,
            data: {
              departure
            }
          }));
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
    </div >
  );
}

export default DeparturePassengersTable;
