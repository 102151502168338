export const TypeOfCargoList = [
    {
        label: "Liquid bulk",
        value: "Liquid bulk",
    },
    {
        label: "Solid bulk",
        value: "Solid bulk",
    },
    { label: "General cargo", value: "General cargo" },
]

export const StowagePositionList = [
    { label: "Port", value: "P" },
    { label: "Starboard", value: "S" },
    { label: "Centre", value: "C" },
]

export const StowageLevelList = [
    { label: "Deck", value: "deck" },
    { label: "Hold", value: "hold" },
    { label: "Tween deck 1", value: "tweendeck1" },
    { label: "Tween deck 2", value: "tweendeck2" },
    { label: "Tween deck 3", value: "tweendeck3" },
    { label: "Tween deck 4", value: "tweendeck4" },
    { label: "Tween deck 5", value: "tweendeck5" },
    { label: "Tween deck 6", value: "tweendeck6" },
    { label: "Tween deck 7", value: "tweendeck7" },
    { label: "Tween deck 8", value: "tweendeck8" },
    { label: "Tween deck 9", value: "tweendeck9" },
];

export const TableColumns = [
    {
        Header: "",
        disableSortBy: true,
        accessor: "action",
    },
    {
        Header: "Booking or B/L",
        disableSortBy: true,
        accessor: "bookingNumber", // accessor is the "key" in the data
    },
    {
        Header: "UN number",
        disableSortBy: true,
        accessor: "un",
    },
    {
        Header: "Proper shipping name",
        disableSortBy: true,
        accessor: "properShippingName",
    },
    {
        Header: "Class",
        disableSortBy: true,
        accessor: "class",
    },
    {
        Header: "Subs. Risk",
        disableSortBy: true,
        accessor: "subsidiaryRisk",
    },
    {
        Header: "Packing group",
        disableSortBy: true,
        accessor: "packingGroup",
    },
    {
        Header: "Flash point (ºC)",
        disableSortBy: true,
        accessor: "flashPoint",
    },
    {
        Header: "EmS",
        disableSortBy: true,
        accessor: "ems",
    },
    {
        Header: "Stowage Position",
        disableSortBy: true,
        accessor: "stowagePosition",
    },
    {
        Header: "Reference number",
        disableSortBy: true,
        accessor: "referenceNumber",
    },
    {
        Header: "Marks & Numbers",
        disableSortBy: true,
        accessor: "marksAndNumbers",
    },
    {
        Header: "Number/kind of packages",
        disableSortBy: true,
        accessor: "numberOfPackages",
    },
    {
        Header: "Mass (kg) or Volume (m3)",
        disableSortBy: true,
        accessor: "massOrVolume",
    },
    {
        Header: "Loading Port",
        disableSortBy: true,
        accessor: "loadPort",
    },
    {
        Header: "Discharge port",
        disableSortBy: true,
        accessor: "dischargePort",
    },
]

export const initialFormDangerousGoodsValues = {
    grossMass: null,
    netVolume: null,
    typeOfCargo: null,
    tankHoldNumber: null,
    netWeight: null,
    dischargePort: null,
    loadPort: null,
    receiverName: null,
    receiverCountry: null,
    receiverCity: null,
    receiverAddress: null,
    receiverVatNumber: null,
    receiverPhoneNumber: null,
    shipperName: null,
    shipperCountry: null,
    shipperCity: null,
    shipperAddress: null,
    shipperVatNumber: null,
    shipperPhoneNumber: null,
    un: null,
    name: null,
    class: null,
    packingGroup: null,
    classificationCode: null,
    ec: null,
    cas: null,
    ems: null,
    labels: null,
    properShippingName: null,
    cargoOperations: null,
    subsidiaryRisk: null,
    stowagePosition: null,
    flashPoint: null,
    referenceNumber: null,
    marksAndNumbers: null,
    massOrVolume: null,
    shipper: null,
    consignee: null,
    marinePollutant: false,
    specialProvisions: null,
    typeOfUnit: null,
    numberOfPackages: null,
    containerGroup: null,
}
