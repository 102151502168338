import { useEffect, useState } from 'react';
import { getPortByUnlocode } from '../features/global/utils/ports';

const usePortsRepository = ({ ports, unlocode }) => {
  const [port, setPort] = useState();

  const getAsyncPort = async () => {
    setPort(await getPortByUnlocode({ ports, unlocode }));
  }

  useEffect(() => {
    if (unlocode) getAsyncPort();
  }, [unlocode]);

  return {
    port,
  };
};

export default usePortsRepository;
