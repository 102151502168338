import { searchPortByUnlocode } from "../../../features/port-clearance-form-request/slices/portsSlice";

export const getPortLabel = ({ port }) => {
    return port ? `${port?.name}, ${port?.countryCode}` : null;
  };
  
export const getPortByUnlocode = async ({ ports, unlocode }) => {
  const relatedPort = ports?.find((port) => port.unlocode === unlocode);
  if (relatedPort) {
      return relatedPort;
  } else {
      const asyncPorts = await searchPortByUnlocode({ unlocode });
      return asyncPorts.find((port) => port.unlocode === unlocode);
  }
};

export const getRelatedPortByUnlocode = ({ ports, unlocode }) => {
  return ports?.find((port) => port.unlocode === unlocode);
};