/*
This function will handle deleting the attributes that
doesn't have any content. This is to avoid sending empty
attributes to the backend as it will cause an error.

Make sure to send an object as a parameter.
ie: {name: 'John', age: 20, address: ''}
*/

export const cleanEmptyData = (data) => {
    Object.keys(data).forEach((key) => {
        if (!Boolean(data[key])) {
            try {
                delete data[key];
            } catch (e) {
            }
        }
    })
}
export const removeNullUndefined = (obj) => {
    return Object.entries(obj)
        .filter(([_, v]) => v !== null && v !== undefined && v !== "")
        .reduce((acc, [k, v]) => {
            return { ...acc, [k]: v };
        }, {});
};
