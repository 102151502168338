import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { axiosInstance } from '../../../../../config/axios';
import auth from '../../../../../config/auth';

const initialState = {
  hasErrors: false,
  errors: [],
  ui: {

  }
};


export const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState,
  reducers: {
    setGlobalError: (state, action) => {
      state.hasErrors = true;
      state.errors = state.errors.concat(action.payload);
    },
    clearErrors: (state, action) => {
      state.hasErrors = false;
      state.errors = []
    }
  },
  extraReducers: (builder) => {
  },
});

export const {
  setGlobalError,
  clearErrors
} = globalErrorSlice.actions;


export const selectGlobalError = (state) => state.globalError;

export default globalErrorSlice.reducer;
