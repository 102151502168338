import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  updateIsps,
  selectSolas,
} from '../../../slices/portCallSlice';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import ShipToShipOperationsTable from './ShipToShipOperationsTable';
import SolasSecurityMeasuresTable from './SolasSecurityMeasuresTable';
import "../styles/solas-security-measures.scss";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const additionalSecurityMeasuresOptions = booleanOptionsForRadioGroup();
const wereApprovedProceduresFollowedInShipToShipOperations = booleanOptionsForRadioGroup();

function SolasSecurityMeasures() {
  const {formContextRef, multiFormRef} = useContext(FormRefContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const solas = useSelector(selectSolas);
  const additionalSecurityMeasuresFormRef = useRef(null);
  const wereApprovedProceduresFollowedInShipToShipOperationsRef = useRef(null);

  let firstFormInitialValues = {
    hasAdditionalSecurityMeasures: solas.data.hasAdditionalSecurityMeasures,
  };

  const firstFormValidationSchema = Yup.object({
    hasAdditionalSecurityMeasures: Yup.boolean()
  })

  let secondFormInitialValues = {
    wereApprovedProceduresFollowedInShipToShipOperations: solas.data.wereApprovedProceduresFollowedInShipToShipOperations || false
  };

  const secondFormValidationSchema = Yup.object({
    wereApprovedProceduresFollowedInShipToShipOperations: Yup.boolean()
  })

  useEffect(() => {
    formContextRef.current = additionalSecurityMeasuresFormRef?.current; 
    multiFormRef.current = wereApprovedProceduresFollowedInShipToShipOperations?.current;
  }, [formContextRef,
     multiFormRef,
     additionalSecurityMeasuresFormRef,
    wereApprovedProceduresFollowedInShipToShipOperations,
    solas
    ]);

  const handleSubmit = async (values, onSubmitProps) => {
    const firstFormValues = additionalSecurityMeasuresFormRef?.current?.values;
    const secondFormValues = wereApprovedProceduresFollowedInShipToShipOperationsRef?.current?.values;
    dispatch(updateIsps({
      ispsData: {
        hasAdditionalSecurityMeasures: firstFormValues.hasAdditionalSecurityMeasures,
        wereApprovedProceduresFollowedInShipToShipOperations: secondFormValues.wereApprovedProceduresFollowedInShipToShipOperations
      },
      uid: params.uid
    }))

  };

  return (
    <div className="solas-security-measures">
      <PageHeader title={"SOLAS and ISPS code"} />
      <Formik
        onSubmit={handleSubmit}
        innerRef={additionalSecurityMeasuresFormRef}
        initialValues={firstFormInitialValues}
        enableReinitialize
        validationSchema={firstFormValidationSchema}>
        {({ values }) => {
          return <>
            <Form className="additional-security-measures-form">
              <div className="additional-security-measures-form__row">
                <RadioButtonsCustom name="hasAdditionalSecurityMeasures" label="Did the ship take any special or additional security measures, beyond those in the approved SSP ?" options={additionalSecurityMeasuresOptions} />
              </div>
            </Form>
            <SolasSecurityMeasuresTable disabled={!values.hasAdditionalSecurityMeasures} />
          </>
        }}
      </Formik>
      <Formik
        innerRef={wereApprovedProceduresFollowedInShipToShipOperationsRef}
        initialValues={secondFormInitialValues}
        enableReinitialize
        validationSchema={secondFormValidationSchema}>
        <Form className="procedures-followed-form">
          <div className="procedures-followed-form__row">
            <RadioButtonsCustom name="wereApprovedProceduresFollowedInShipToShipOperations" label="Have all approved procedures (SSP) been followed in ship-to-ship operations ?" options={wereApprovedProceduresFollowedInShipToShipOperations} />
          </div>
        </Form>
      </Formik>
      <ShipToShipOperationsTable />
      <SkipAndNextButtons
        onClick={() => navigate(`/${params.uid}/marpol`)}
        next={`/${params.uid}/marpol`}
        back={`/${params.uid}/solas-and-isps-code/port-calls`} />
    </div >
  );
}

export default SolasSecurityMeasures;
