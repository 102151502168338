import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { searchPlaceOfBirth, selectPlaceOfBirthSearchResults, selectPlaceOfBirthLoadingState } from '../slices/placeOfBirthSlice';
import MultiSelect from '../../MultiSelect';


function PlaceOfBirthDropdown(props) {
    const dispatch = useDispatch();
    const placeOfBirthSearchResults = useSelector((state) => selectPlaceOfBirthSearchResults(state, props.dropdownID)) || [];
    const shouldShowLoadingInPlaceOfBirthDropdown = useSelector((state) => selectPlaceOfBirthLoadingState(state, props.dropdownID));

    const handlePlaceOfBirthChanged = _.debounce((value) => {
        if (value && value.length >= 3) {
            dispatch(searchPlaceOfBirth({
                dropdownID: props.dropdownID,
                value
            }));
        };
    }, 500);

    const handlePlaceOfBirthChangeDebounced = (value) => {
        if (handlePlaceOfBirthChanged.cancel) {
            handlePlaceOfBirthChanged.cancel(); // If there is an existing debounce, cancel it
        }

        handlePlaceOfBirthChanged(value); // Start a new debounce with the latest value
    };


    return (
        <MultiSelect
            name={props.name}
            onInputChange={handlePlaceOfBirthChangeDebounced}
            options={placeOfBirthSearchResults}
            isMulti={false}
            label="Place of birth"
            isLoading={shouldShowLoadingInPlaceOfBirthDropdown}
            placeholder="Type One" />
    );
}

export default PlaceOfBirthDropdown;
