import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Form, Formik } from 'formik';
import { default as React, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectPortCallData, updatePortCallData } from '../../../slices/portCallSlice';
import '../../../styles/last-and-next-port.scss';
import { isEmpty, isEmptyObject, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from './SkipAndNextButtons';

const validationSchema = Yup.object({
  lastPort: Yup.object(),
  nextPort: Yup.object(),
  atdLastPort: Yup.string().nullable(),
  etaNextPort: Yup.string().nullable(),
});

function LastAndNexPort() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const portCallData = useSelector(selectPortCallData);

  const initialValues = {
    lastPort: portCallData.data.lastPort,
    nextPort: portCallData.data.nextPort,
    atdLastPort: portCallData.data.atdLastPort?.actualTime ? new Date(portCallData.data.atdLastPort.actualTime) : '',
    etaNextPort: portCallData.data.etaNextPort?.estimatedTime
      ? new Date(portCallData.data.etaNextPort.estimatedTime)
      : '',
  };

  const handleSubmit = async (values) => {
    dispatch(
      updatePortCallData({
        uid: params.uid,
        data: {
          lastPort: values.lastPort,
          nextPort: values.nextPort,
          atdLastPort: { actualTime: values.atdLastPort ? values.atdLastPort.toISOString() : null },
          etaNextPort: { estimatedTime: values.etaNextPort ? values.etaNextPort.toISOString() : null },
        },
      })
    );
  };

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, portCallData]);

  return (
    <div className='last-and-next-port'>
      <PageHeader title={'Port call data'} />
      <Formik
        innerRef={formRef}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({ values, setValues, errors }) => {
          return (
            <Form className='last-and-next-port-form'>
              <div className='last-and-next-port-form__controls'>
                <div className='last-and-next-port-form__controls__row'>
                  <SearchPortsDropdown
                    label='What was your last port? *'
                    name='lastPort'
                    dropdownID='lastPort'
                    placeholder='Last Port'
                    hasError={isEmptyObject(values?.lastPort)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                  <Field label='ATD: *' className='field_date_picker_lastPort'>
                    <DatePickerTimeInput
                      key={initialValues?.atdLastPort + 'atdLastPort'}
                      name='atdLastPort'
                      sizeInput='large'
                      maxDate={portCallData?.data?.eta?.estimatedTime || new Date()}
                      showTime={true}
                      defaultValue={initialValues?.atdLastPort}
                      action={({ isoDate }) => {
                        if (isoDate) {
                          setValues({ ...values, atdLastPort: new Date(isoDate) });
                        } else {
                          setValues({ ...values, atdLastPort: null });
                        }
                      }}
                      showBlueError={true}
                      hasError={isEmpty(values?.atdLastPort)}
                      errorMessage={MandatoryFieldMessages.RequiredField}
                    />
                  </Field>
                </div>
                <div className='last-and-next-port-form__controls__row'>
                  <SearchPortsDropdown
                    label='And the next port? *'
                    name='nextPort'
                    dropdownID='nextPort'
                    placeholder='Next Port'
                    hasError={isEmptyObject(values?.nextPort)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                  <Field label='ETA:' className='field_date_picker_nextPort'>
                    <DatePickerTimeInput
                      key={initialValues?.etaNextPort + 'etaNextPort'}
                      name='etaNextPort'
                      sizeInput='large'
                      minDate={portCallData?.data?.etd?.estimatedTime || new Date()}
                      showTime={true}
                      defaultValue={initialValues?.etaNextPort}
                      action={({ isoDate }) => setValues({ ...values, etaNextPort: new Date(isoDate) })}
                      hasError={errors?.etaNextPort}
                      errorMessage={errors?.etaNextPort}
                    />
                  </Field>
                </div>
              </div>
              <SkipAndNextButtons
                next={`/${params.uid}/port-call-data/purpose`}
                back={`/${params.uid}/port-call-data/eta-etd`}
                onClick={() => navigate(`/${params.uid}/port-call-data/purpose`)}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default LastAndNexPort;
