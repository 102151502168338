import React from 'react';
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../forms/styles/images/full-arrow-left.svg"
import Button from '../../layout/components/Button';
import PortCall from '../../../config/portCall'
import "../../layout/styles/logout-layout.scss";

export default function Logout(props) {
  const navigate = useNavigate();
  const currentPortCallUuid = PortCall.getPortCallUuid()

  return (
    <div className="logout-layout">
      <main>
        <header>
          <h3>Thank you!</h3>
          <p>Safe travels!</p>
        </header>
        <footer>
          <Button onClick={() => navigate(`/${currentPortCallUuid}/welcome`)} iconLeft={arrowLeft}>Go to form</Button>
        </footer>
      </main>
    </div>
  );
}
