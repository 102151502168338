import { Field } from 'formik';
import React from 'react';
import '../../captains-form/styles/mandatory-check.scss';
import { getStyles } from '../helpers/random-form-helpers';
import '../styles/input.scss';

function CustomInput(props) {
  const {
    field,
    form,
    form: { errors },
    ...rest
  } = props;

  return (
    <input
      {...field}
      {...rest}
      type='number'
      step='0.01'
      onChange={(e) => {
        let value = e.target.value;

        const dotPosition = value.indexOf('.');
        if (dotPosition === 0) {
          form.setFieldValue(field.name, '');
        } else if (dotPosition > 0) {
          const decimalPlaces = value.slice(dotPosition + 1, value.length);

          if (decimalPlaces.length > 2) {
            const newDecimal = value.slice(dotPosition, dotPosition + 3);
            const newInteger = value.slice(0, dotPosition);
            form.setFieldValue(field.name, newInteger + newDecimal);
          } else {
            form.setFieldValue(field.name, value);
          }
        } else {
          form.setFieldValue(field.name, value);
        }
      }}
      style={form.touched[field.name] && getStyles(errors, field.name)}
    />
  );
}

export default function NumberInputWithTwoDecimals(props) {
  const { label, name, hasError = false, errorMessage = '', ...rest } = props;

  return (
    <div className={'form-control input'}>
      <label className='input__label' htmlFor={name}>
        {label}
      </label>
      <Field
        component={CustomInput}
        type='text'
        id={name}
        name={name}
        className={hasError ? 'input-error-border' : ''}
        {...rest}
      />
      {hasError && <div className='error-normal'>{errorMessage}</div>}
    </div>
  );
}
