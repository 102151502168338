import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useParams, Navigate } from 'react-router-dom';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { useDispatch } from 'react-redux';
import { getPassengers } from '../../../slices/portCallSlice';

function Passengers() {
  const dispatch = useDispatch();
  const params = useParams();
  const [percentage, setPercentage] = useState("20");
  const [pageNumber, setPageNumber] = useState("");

  useEffect(() => {
    dispatch(getPassengers(params.uid));
  }, [])

  let location = useLocation();

  useEffect(() => {
    if (location.pathname === `/${params.uid}/passengers/number`) {
      setPercentage("35");
      setPageNumber("1");
    } else if (location.pathname === `/${params.uid}/passengers/arrival-passengers`) {
      setPercentage("70");
      setPageNumber("2");
    } else if (location.pathname === `/${params.uid}/passengers/departure-passengers`) {
      setPercentage("100");
      setPageNumber("3");
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/passengers`) {
    return <Navigate to={`/${params.uid}/passengers/number`} replace />;
  }


  return (
    <Page>
      <div className="passengers">
        <ProgressBar percentage={percentage} pageNumber={pageNumber} totalPages={"3"} />
        <Outlet />
      </div>
    </Page>
  );
}

export default Passengers;
