
export const InformationIcon = (props:any)=> {
    return (<svg
        width="24px"
        height="24px"
        viewBox="0 0 20 20"
        fill={props.fill}
        stroke={props.stroke}
        {...props}
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill="inherit"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM10 4.25C9.58579 4.25 9.25 4.58579 9.25 5C9.25 5.41421 9.58579 5.75 10 5.75H10.009C10.4232 5.75 10.759 5.41421 10.759 5C10.759 4.58579 10.4232 4.25 10.009 4.25H10ZM9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75H9.25V15C9.25 15.4142 9.58579 15.75 10 15.75C10.4142 15.75 10.75 15.4142 10.75 15V9C10.75 8.58579 10.4142 8.25 10 8.25H9Z"
        />
    </svg>)

}
