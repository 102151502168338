import MultiSelect from 'features/forms/components/MultiSelect';
import RadioButtonsCustom from 'features/forms/components/RadioButtonsCustom';
import { booleanOptionsForRadioGroup } from 'features/forms/helpers/random-form-helpers';
import { Port } from 'features/global/ports';
import { countryListAllIsoData } from 'features/global/utils/countriesList';
import { genderOptions, rankOptions } from 'features/global/utils/globalOptions';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import Input from '../../../../forms/components/Input';
import Textarea from '../../../../forms/components/TextArea';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import Button from '../../../../layout/components/Button';
import Modal from "../../../../layout/components/Modal";
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import { selectHealthDeclaration } from '../slices/healthSlice';
import { searchForPort } from '../slices/portCallSlice';
import "../styles/health.scss";
import { caseDisposals, particularStates } from '../utils/healthUtils';
// @ts-ignore
import { DatePickerTimeInput } from "@navozyme/uikit/dist/molecules/DatePicker";
// @ts-ignore
import { Field } from "@navozyme/uikit/dist/atoms/Field";

interface StateParticularModalProps {
  isEditingRow: boolean;
  editingRow: number | null;
  isModalOpen: boolean;
  setIsModalOpen: (newValue: boolean) => void;
  setEditingRow: (newValue: number | null) => void;
  setData: (newValue: any) => void;
}

interface FormValues {
  familyName: string;
  givenNames: string;
  classOrRating: string;
  age: number | null;
  gender: string;
  nationality: string;
  portOfEmbarkation: string | null | Object;
  dateOfEmbarkation: string | null | Date;
  natureOfIllness: string;
  dateOfOnsetOfSymptoms: string | null | Date;
  reportedToPortMedicalOfficer: boolean;
  state: string;
  disposalOfCase: string;
  evacuatedAtPort: string;
  treatmentGiven: string;
  comments: string;
}

export const StateParticularModal: React.FC<StateParticularModalProps> = ({
  isEditingRow,
  editingRow,
  isModalOpen,
  setIsModalOpen,
  setEditingRow,
  setData,
}) => {

  const healthDeclaration = useSelector(selectHealthDeclaration);
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<FormValues>({
    familyName: "",
    givenNames: "",
    classOrRating: "",
    age: null,
    gender: "",
    nationality: "",
    portOfEmbarkation: null,
    dateOfEmbarkation: null,
    natureOfIllness: "",
    dateOfOnsetOfSymptoms: null,
    reportedToPortMedicalOfficer: false,
    state: "",
    disposalOfCase: "",
    evacuatedAtPort: "",
    treatmentGiven: "",
    comments: "",
  });

  const [embarkationDate, setEmbarkationDate] = useState(initialValues?.dateOfEmbarkation);

  useEffect(() => {
    if (isEditingRow) {
      healthDeclaration.stateParticulars.forEach((stateParticular: any, index: number) => {
        if (index === editingRow) {
          dispatch(searchForPort(
            { unlocode: healthDeclaration.stateParticulars[editingRow as number].unlocode }
          ) as any).then((response: any) => {
            const dateOfEmbarkation = new Date(stateParticular.dateOfEmbarkation);
            const dateOfOnsetOfSymptoms = new Date(stateParticular.dateOfOnsetOfSymptoms);
            const portOfEmbarkation = response.payload[0];

            setInitialValues({
              familyName: stateParticular.familyName,
              givenNames: stateParticular.givenNames,
              classOrRating: stateParticular.classOrRating,
              age: stateParticular.age,
              gender: stateParticular.gender,
              nationality: stateParticular.nationality,
              portOfEmbarkation: portOfEmbarkation,
              dateOfEmbarkation: dateOfEmbarkation,
              natureOfIllness: stateParticular.natureOfIllness,
              dateOfOnsetOfSymptoms: dateOfOnsetOfSymptoms,
              reportedToPortMedicalOfficer: stateParticular.reportedToPortMedicalOfficer,
              state: stateParticular.state,
              disposalOfCase: stateParticular.disposalOfCase,
              evacuatedAtPort: stateParticular.evacuatedAtPort,
              treatmentGiven: stateParticular.treatmentGiven,
              comments: stateParticular.comments,
            })
          })
        }
      })
    }
  }, [isEditingRow, editingRow])


  const validationSchema = Yup.object({
    familyName: Yup.string(),
    givenName: Yup.string(),
    classOrRating: Yup.string(),
    age: Yup.number(),
    gender: Yup.string(),
    nationality: Yup.string(),
    portOfEmbarkation: Yup.string(),
    dateOfEmbarkation: Yup.string(),
    natureOfIllness: Yup.string(),
    dateOfOnsetOfSymptoms: Yup.string(),
    reportedToPortMedicalOfficer: Yup.boolean(),
    state: Yup.string(),
    disposalOfCase: Yup.string(),
    treatmentGiven: Yup.string(),
    comments: Yup.string(),
  });

  const handleSubmit = async (values: FormValues) => {
    const finalValues: FormValues = { ...values };

    if (values.dateOfEmbarkation) {
      finalValues.dateOfEmbarkation = new Date(values.dateOfEmbarkation).toISOString();
    }

    if (values.dateOfOnsetOfSymptoms) {
      finalValues.dateOfOnsetOfSymptoms = new Date(values.dateOfOnsetOfSymptoms).toISOString();
    }

    setData(finalValues)

    setIsModalOpen(false);
  };

  return (
    <Modal
      title={"State particulars"}
      className={`health-modal modal-scroll`}
      showModal={isModalOpen}
      onCloseModal={() => {
        setIsModalOpen(false);
      }}>
      <div className="health-modal__content">
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ values, setValues, errors }) => {
            return (
              <Form className="health-form state-particular">
                <div className="health-form__row">
                  <Input name="familyName" placeholder="Family name" label="Family name" />
                  <Input name="givenNames" placeholder="Given name" label="Given name" />
                </div>
                <div className="health-form__row">
                  <MultiSelect
                    name="classOrRating"
                    label="Class or rating"
                    options={rankOptions}
                    isMulti={false}
                    placeholder="Choose one" />
                  <Input name="age" type="number" placeholder="Age" label="Age" />
                </div>
                <div className="health-form__row">
                  <MultiSelect
                    name="gender"
                    label="Gender"
                    options={genderOptions}
                    isMulti={false}
                    placeholder="Choose one" />
                  <MultiSelect
                    name="nationality"
                    label="Nationality"
                    options={countryListAllIsoData}
                    isMulti={false}
                    placeholder="Choose one" />
                </div>
                <div className="health-form__row">
                  <SearchPortsDropdown
                    label="Port"
                    name="portOfEmbarkation"
                    onChange={(port: Port) => {
                      setInitialValues({
                        ...values,
                        portOfEmbarkation: port.unlocode
                      });
                    }}
                    dropdownID="healthDeclaration.port"
                    placeholder="Type One" />
                  <Field label="Date of embarkation" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.dateOfEmbarkation + "dateOfEmbarkation"}
                      name="dateOfEmbarkation"
                      sizeInput="large"
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={
                        initialValues?.dateOfEmbarkation
                      }
                      action={({ isoDate }: any) => {
                        setEmbarkationDate(new Date(isoDate))
                        setValues({ ...values, dateOfEmbarkation: new Date(isoDate) })
                      }}
                      hasError={errors?.dateOfEmbarkation}
                      errorMessage={errors?.dateOfEmbarkation}
                    />
                  </Field>
                </div>
                <div className="health-form__row">
                  <Input name="natureOfIllness" placeholder="Nature of illness" label="Nature of illness" />
                  <Field label="Date of onset of symptoms" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.dateOfOnsetOfSymptoms + "dateOfOnsetOfSymptoms"}
                      name="dateOfEmbarkation"
                      sizeInput="large"
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={
                        initialValues?.dateOfOnsetOfSymptoms
                      }
                      action={({ isoDate }: any) =>
                        setValues({ ...values, dateOfOnsetOfSymptoms: new Date(isoDate) })
                      }
                      minDate={embarkationDate || initialValues?.dateOfEmbarkation}
                      hasError={errors?.dateOfOnsetOfSymptoms}
                      errorMessage={errors?.dateOfOnsetOfSymptoms}
                    />
                  </Field>
                </div>
                <div className="health-form__row">
                  <RadioButtonsCustom
                    name="reportedToPortMedicalOfficer"
                    label="Reported to a port medical officer?"
                    options={booleanOptionsForRadioGroup()} />
                  <MultiSelect
                    name="state"
                    label="State"
                    options={particularStates}
                    isMulti={false}
                    placeholder="Choose" />
                </div>
                <div className="health-form__row">
                  <MultiSelect
                    name="disposalOfCase"
                    label="Disposal of case"
                    options={caseDisposals}
                    isMulti={false}
                    placeholder="Choose one" />
                  <SearchPortsDropdown
                    disabled={values.disposalOfCase !== "Evacuated"}
                    label="Port evacuated"
                    name="evacuatedAtPort"
                    onChange={(port: Port) => {
                      setInitialValues({
                        ...values,
                        evacuatedAtPort: port.unlocode
                      });
                    }}
                    dropdownID="healthDeclaration.evacuatedAtPort"
                    placeholder="Choose" />
                  <Input name="treatmentGiven" placeholder="Treatment given" label="Treatment given (drug medicines or other)" />
                </div>
                <div className="health-form__row">
                  <Textarea name="comments" rows={7} placeholder="Comments" label="Comments" />
                </div>
                {
                  isEditingRow ? (
                    <div className="add-member__form-submission-btns">
                      <div className="discard-btn" onClick={() => {
                        setIsModalOpen(false);
                        setTimeout(() => setEditingRow(null), 300)

                      }}>Discard</div>
                      <Button type="submit">Change Information</Button>
                    </div>
                  ) : (
                    <div className="add-member__form-submission-btns">
                      <Button iconLeft={plusIcon} type="submit">Add</Button>
                    </div>
                  )
                }
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal >
  );
};


export default StateParticularModal;
