import React from 'react';
import { Outlet } from "react-router-dom";
import Layout from './features/layout/components/Layout';
import {FormRefProvider} from './features/global/context/FormRefContext'

function App() {
  return (
    <FormRefProvider>
      <Layout>
        <Outlet />
      </Layout>
    </FormRefProvider>
  );
}

export default App;
