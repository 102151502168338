import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../../config/axios';
import auth from '../../../../../config/auth';

const initialState = {
	searchResults: {},
	loading: {},
};


export const searchPort = createAsyncThunk(
	'searchPorts/searchPort',
	async ({ dropdownID, searchData }) => {
		const headers = auth.getHeaders();

		let endpoint = `/ports/`;
		if (searchData.unlocode) {
			endpoint += `?unlocode=${searchData.unlocode}`
		} else if (searchData.portName) {
			endpoint += `?name=${searchData.portName}`
		}

		const response = await axiosInstance.get(endpoint, { headers });

		return {
			dropdownID,
			data: response.data.data
		}
	}
);


export const searchPortsSlice = createSlice({
	name: 'searchPorts',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(searchPort.pending, (state, { meta }) => {
				const { dropdownID } = meta.arg;
				state.loading[dropdownID] = true;
			})
			.addCase(searchPort.fulfilled, (state, action) => {
				const { dropdownID, data } = action.payload;
				state.searchResults[dropdownID] = data;
				state.loading[dropdownID] = false;
			})
			.addCase(searchPort.rejected, (state, { meta }) => {
				const { dropdownID } = meta.arg;
				state.loading[dropdownID] = false;
			})
	},
});

export const { } = searchPortsSlice.actions;

export const selectSearchPortsResults = (state, dropdownId) => state.searchPorts.searchResults[dropdownId];
export const selectSearchPortLoadingState = (state, dropdownId) => state.searchPorts.loading[dropdownId];

export default searchPortsSlice.reducer;
