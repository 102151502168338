import { ANNEXES_KEYS, WASTE_DISPOSAL_APPROACH_KEYS } from "../constants/annexes";
import { ALL_WASTE_DISPOSAL_APPROACH, PARTIALLY_WASTE_DISPOSAL_APPROACH, parseFloatWithDecimalsValuesInAnnexes } from "./marpolUtils";
import { isNumeric } from '../../../../global/utils/helpers';

export const AnnexesTypesList = [
    { label: "Annex I", value: "ANNEX_I", accessor: ANNEXES_KEYS.ANNEX_ONE },
    { label: "Annex II", value: "ANNEX_II", accessor: ANNEXES_KEYS.ANNEX_TWO },
    { label: "Annex IV", value: "ANNEX_IV", accessor: ANNEXES_KEYS.ANNEX_FOUR },
    { label: "Annex V", value: "ANNEX_V", accessor: ANNEXES_KEYS.ANNEX_FIVE },
    { label: "Annex VI", value: "ANNEX_VI", accessor: ANNEXES_KEYS.ANNEX_SIX },
    { label: "Other waste", value: "OTHER_WASTE", accessor: ANNEXES_KEYS.OTHER_ANNEX },
];

const imo0183CodesByAnnex = {
  [ANNEXES_KEYS.ANNEX_ONE]: {
    101: {
      slug: "oilyBilgeWater",
      name: "Oily bilge water",
    },
    102: {
      slug: "oilyResidues",
      name: "Oily residues",
    },
    103: {
      slug: "oilyTankWashings",
      name: "Oily tank washings",
    },
    104: {
      slug: "dirtyBallastWater",
      name: "Dirty ballast water",
    },
    105: {
      slug: "scaleAndSludge",
      name: "Scale and sludge from tank cleaning",
    },
    999: {
      slug: "other",
      name: "Other (please specify)",
    },
  },
  [ANNEXES_KEYS.ANNEX_TWO]: {
    201: {
      slug: "categoryXSubstance",
      name: "Category X substance",
    },
    202: {
      slug: "categoryYSubstance",
      name: "Category Y substance",
    },
    203: {
      slug: "categoryZSubstance",
      name: "Category Z substance",
    },
    204: {
      slug: "osOtherSubstances",
      name: "OS - other substances",
    },
  },
  [ANNEXES_KEYS.ANNEX_FOUR]: {
    401: {
      slug: "sewage",
      name: "Sewage",
    },
  },
  [ANNEXES_KEYS.ANNEX_FIVE]: {
    501: {
      slug: "plastics",
      name: "A. Plastics",
    },
    502: {
      slug: "foodWastes",
      name: "B. Food wastes",
    },
    503: {
      slug: "domesticWastes",
      name: "C. Domestic wastes",
    },
    504: {
      slug: "cookingOil",
      name: "D. Cooking oil",
    },
    505: {
      slug: "incineratorAshes",
      name: "E. Incinerator ashes",
    },
    506: {
      slug: "operationalWastes",
      name: "F. Operational wastes",
    },
    507: {
      slug: "animalCarcasses",
      name: "G. Animal carcasses",
    },
    508: {
      slug: "fishingGear",
      name: "H. Fishing gear",
    },
    509: {
      slug: "eWaste",
      name: "I. E-waste",
    },
    510: {
      slug: "cargoResiduesNonHME",
      name: "J. Cargo residues (non-HME)",
    },
    511: {
      slug: "cargoResiduesHME",
      name: "K. Cargo residues (HME)",
    },
  },
  [ANNEXES_KEYS.ANNEX_SIX]: {
    601: {
      slug: "ozoneDepletingSubstances",
      name: "Ozone-depleting substances",
    },
    602: {
      slug: "exhaustGasCleaningResidues",
      name: "Exhaust gas-cleaning residues",
    },
  },
  [ANNEXES_KEYS.OTHER_ANNEX]: {
    991: {
      slug: "passivelyFishedWaste",
      name: "Passively fished waste",
    },
  },
};

const annexRomanNumbers = [
  {
    accessor: ANNEXES_KEYS.ANNEX_ONE,
    romanNumber: "I",
  },
  {
    accessor: ANNEXES_KEYS.ANNEX_TWO,
    romanNumber: "II",
  },
  {
    accessor: ANNEXES_KEYS.ANNEX_FOUR,
    romanNumber: "IV",
  },
  {
    accessor: ANNEXES_KEYS.ANNEX_FIVE,
    romanNumber: "V",
  },
  {
    accessor: ANNEXES_KEYS.ANNEX_SIX,
    romanNumber: "VI",
  },
  {
    accessor: ANNEXES_KEYS.OTHER_ANNEX,
    romanNumber: "OTHER",
  },
];

function getAnnexRomanNumberFromSlug(slug) {
  return annexRomanNumbers.find((annex) => annex.accessor === slug).romanNumber;
}

export function getAnnexRomanNumberLabelFromImoCodes(imoCodes) {
  const annexes = new Set();

  for (const code of imoCodes) {
    for (const annexKey in imo0183CodesByAnnex) {
      if (imo0183CodesByAnnex[annexKey][code]) {
        annexes.add(annexKey);
        break;
      }
    }
  }

  const annexesInRomanNumbers = Array.from(annexes).map((annex) =>
    getAnnexRomanNumberFromSlug(annex)
  );

  return `Annex ${annexesInRomanNumbers}`;
}

export function getAnnexLabelReceipt(receipt) {
  const imoCodes = receipt.waste.map((w) => w.imo0183WasteCode);

  return getAnnexRomanNumberLabelFromImoCodes(imoCodes);
}

export function groupByAnnex(data) {
  const result = {};

  for (const key in data) {
    const entry = data[key];
    if(!entry.hasOwnProperty('imo0183WasteCode')) continue; 

    const annexKey = getAnnexKey(entry.imo0183WasteCode);
    const slug = getSlugByImo(entry.imo0183WasteCode);
    if (result[annexKey]) {
      result[annexKey][slug] = entry;
    } else {
      result[annexKey] = {
        [slug]: entry
      };
    }
  }

  return result;
}

export function flattenByAnnex(annexes){
  return annexes.reduce((acc, annex) => {
    return [...acc, ...Object.values(annex)]
  },[])
}

function getSlugByImo(imoCode) {
  for (const annexKey in imo0183CodesByAnnex) {
    const annex = imo0183CodesByAnnex[annexKey];
    for (const [code, value] of Object.entries(annex)) {
      if (code == imoCode) {
        return value.slug;
      }
    }
  }
  return null;
}

export function getAnnexKey(code) {
  for (const annexKey in imo0183CodesByAnnex) {
    const annex = imo0183CodesByAnnex[annexKey];
    if (annex[code]) {
      return annexKey;
    }
  }
  return null;
}

export const getImoObjectFromWasteObject = (waste) =>{
    return waste.map((group)=>{
        return [...group.map(({imo0183WasteCode})=>{
            return {
                imo0183WasteCode
            }
        })]
    })
}

export const getWastesFromAnnexes = (annexAccessors, wasteObject) => {
  const wastes = annexAccessors.map((annexAccessor) => {
    const imoAnnexGroup = imo0183CodesByAnnex[annexAccessor];
    return Object.entries(imoAnnexGroup).map((entry) => {
      const imo0183WasteCode = entry[0];
      const slug = entry[1].slug;
      const name = entry[1].name;
      const wasteInformation = wasteObject?.[annexAccessor]?.[slug] || [];
      return {
        imo0183WasteCode,
        name,
        ...wasteInformation,
      };
    });
  });

  return wastes.reduce((prev, curr) => {
    return [...prev, ...curr];
  });
};

export const getAllImoCodes = () => {
  return Object.keys(imo0183CodesByAnnex)
    .flatMap(annex => {
      return getImoCodesFromAnnex(annex)
    })
    .map(imoCode => parseInt(imoCode));
};

const getFlattedImo0183CodesByAnnex = () => {
  const imo0183CodesArray = [];
  
  for (const annexKey in imo0183CodesByAnnex) {
    if (Object.hasOwnProperty.call(imo0183CodesByAnnex, annexKey)) {
      const annex = imo0183CodesByAnnex[annexKey];
      for (const code in annex) {
        if (Object.hasOwnProperty.call(annex, code)) {
          const substance = annex[code];
          imo0183CodesArray.push([parseInt(code), substance.slug]);
        }
      }
    }
  }

  return imo0183CodesArray;
}

const flattedImo0183CodesByAnnex = getFlattedImo0183CodesByAnnex();

export const getImoCodeBySlug = (slug) => {
  const result = flattedImo0183CodesByAnnex.find(item => {
    // eslint-disable-next-line no-unused-vars
    const [_, imoCodeSlug] = item;
    return imoCodeSlug === slug;
  });

  return result ? result[0] : null;
}

export const getImoCodesFromAnnex = (annex) => Object.keys(imo0183CodesByAnnex[annex])

export const getAnnexesOptionsWithImoGroupValues = () => {
  return Object.entries(imo0183CodesByAnnex).map((annex) => {
    const label = AnnexesTypesList.find(
      (annexType) => annexType.accessor === annex[0]
    )?.label;

    return {
      label: label || annex[0],
      key: label || annex[0],
      value: annex[0]
    };
  });
};


export const wasteDisposalApproachOptions = [
  { key: WASTE_DISPOSAL_APPROACH_KEYS.ALL, value: ALL_WASTE_DISPOSAL_APPROACH },
  { key: WASTE_DISPOSAL_APPROACH_KEYS.PARTIALLY, value: PARTIALLY_WASTE_DISPOSAL_APPROACH },
];

export const getRetainedWasteAmount = (onBoardQuantity, deliveredQuantity) => {
  const currentOnBoardQuantity = isNumeric(onBoardQuantity) === true ? parseFloat(onBoardQuantity) : 0;
  const currentDeliveredQuantity = isNumeric(deliveredQuantity) === true ? parseFloat(deliveredQuantity) : 0;

  return currentOnBoardQuantity - currentDeliveredQuantity;
};

const isCompleteAnnexZeroValues = (annexes, flag = false) => {
  let annexFlag = flag;

  if (annexes && Object.keys(annexes).length !== 0){
    Object.keys(annexes).forEach((key) => {
      if (annexes[key] && typeof annexes[key] !== 'string' && Object.keys(annexes[key]).length !== 0) {
        annexFlag = isCompleteAnnexZeroValues(annexes[key], annexFlag)
      } else {
        if (annexes[key]) annexFlag = true
      }
    })
  } else {
    if (annexes && typeof annexes !== 'string' && annexes) annexFlag = true
  }
  return annexFlag;
};

export const parseDataForDeliveryWasteEndpoint = ({ currentWaste, annexOneFormRef, annexTwoFormRef, annexFourFormRef, annexFiveFormRef, annexSixFormRef, otherWasteFormRef }) => {
  const tempMarpolData = JSON.parse(JSON.stringify({
    annexOne: isCompleteAnnexZeroValues(annexOneFormRef.current.values) ? parseFloatWithDecimalsValuesInAnnexes(annexOneFormRef.current.values): {},
    annexTwo: isCompleteAnnexZeroValues(annexTwoFormRef.current.values) ? parseFloatWithDecimalsValuesInAnnexes(annexTwoFormRef.current.values): {},
    annexFour: isCompleteAnnexZeroValues(annexFourFormRef.current.values) ? parseFloatWithDecimalsValuesInAnnexes(annexFourFormRef.current.values): {},
    annexFive: isCompleteAnnexZeroValues(annexFiveFormRef.current.values) ? parseFloatWithDecimalsValuesInAnnexes(annexFiveFormRef.current.values): {},
    annexSix: isCompleteAnnexZeroValues(annexSixFormRef.current.values) ? parseFloatWithDecimalsValuesInAnnexes(annexSixFormRef.current.values): {},
    otherWaste: isCompleteAnnexZeroValues(otherWasteFormRef.current.values) ? parseFloatWithDecimalsValuesInAnnexes(otherWasteFormRef.current.values): {}
  }));

  for (const [annexKey, annex] of Object.entries(tempMarpolData)) {
    const isEmpty = !Object.keys(annex).length && !Array.from(annex).length
    if (isEmpty) continue;
    
    for (const [slug, values] of Object.entries(annex)){
      if (!(annexKey in currentWaste)) {
        currentWaste[annexKey] = {};
      }
      currentWaste[annexKey][slug] = {
        imo0183WasteCode: getImoCodeBySlug(slug),
        ...currentWaste[annexKey][slug],
        ...values,
        wasteToBeDelivered: values?.wasteToBeDelivered ?? 0,
      }
    }
  
  }

  const flattenObject = flattenByAnnex(Object.values(currentWaste)).map((entry)=>{
    const nextPortToDeliver = entry.dropdownPort ? entry.dropdownPort.unlocode : '';
    return {
      ...entry,
      nextPortToDeliver
    }
  })

  return flattenObject
}
