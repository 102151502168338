import React from 'react';
import "../styles/page.scss";

export default function Page(props) {
  return (
    <div className="page">
      {props.children}
    </div>
  );
}

