import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import {
  selectPortCallData, updatePortCallData
} from '../../../slices/portCallSlice';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SkipAndNextButtons from './SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import { FormRefContext } from '../../../../global/context/FormRefContext';

const mandatoryDocumentsFormValidationSchema = Yup.object({
  isAbleToInternationalShipping: Yup.boolean(),
  isAuthorizedForShortseaOrCabotage: Yup.boolean(),
})

const intenationalShippingOptions = booleanOptionsForRadioGroup();
const authorizedForShortseaOrCabotageOptions = booleanOptionsForRadioGroup();

function Questions() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const portCallData = useSelector(selectPortCallData);

  const initialValues = {
    isAbleToInternationalShipping: portCallData.data.isAbleToInternationalShipping,
    isAuthorizedForShortseaOrCabotage: portCallData.data.isAuthorizedForShortseaOrCabotage,
  };

  useEffect(() => {
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef, portCallData]);

  const handleSubmit = async (values, onSubmitProps) => {
    dispatch(updatePortCallData({
      uid: params.uid,
      data: values
    }))
  };
  return (
    <div className="questions">
      <PageHeader title={"Port call data"} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={mandatoryDocumentsFormValidationSchema}>
        <Form className="questions-form">
          <RadioButtonsCustom name="isAbleToInternationalShipping" label="Is the ship able to international shipping?" options={intenationalShippingOptions} />
          <RadioButtonsCustom name="isAuthorizedForShortseaOrCabotage" label="Is the ship authorised for shortsea or cabotage?" options={authorizedForShortseaOrCabotageOptions} />
          <SkipAndNextButtons
            next={`/${params.uid}/vessel-data`}
            back={`/${params.uid}/port-call-data/drafts`}
            onClick={()=>navigate(`/${params.uid}/vessel-data`)} 
            />
        </Form>
      </Formik>

    </div>
  );
}

export default Questions;
