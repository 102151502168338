import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import BlockedPage from './features/auth/components/BlockedPage';
import Login from './features/auth/components/Login';
import Logout from './features/auth/components/Logout';
import RequireAuth from './features/auth/components/RequireAuth';
import Signup from './features/auth/components/Signup';
import Cargo from './features/captains-form/components/cargo/components/Cargo';
import CargoInformation from './features/captains-form/components/cargo/components/CargoInformation';
import CargoTable from './features/captains-form/components/cargo/components/CargoTable';
import ArrivalCrewChanges from './features/captains-form/components/crew/components/ArrivalCrewChanges';
import ConfirmData from './features/captains-form/components/crew/components/ConfirmData';
import Crew from './features/captains-form/components/crew/components/Crew';
import DeclareEffects from './features/captains-form/components/crew/components/DeclareEffects';
import DepartureCrewChanges from './features/captains-form/components/crew/components/DepartureCrewChanges';
import SpecifyCrewNumber from './features/captains-form/components/crew/components/SpecifyCrewNumber';
import DangerousGoods from './features/captains-form/components/dangerous-goods/components/DangerousGoods';
import GeneralDeclaration from './features/captains-form/components/general-declaration/components/GeneralDeclaration';
import FirstHealthStep from './features/captains-form/components/health/components/FirstHealthStep';
import Health from './features/captains-form/components/health/components/Health';
import SecondHealthStep from './features/captains-form/components/health/components/SecondHealthStep';
import ThirdHealthStep from './features/captains-form/components/health/components/ThirdHealthStep';
import Inspections from './features/captains-form/components/inspections/components/Inspections';
import SafetyDocuments from './features/captains-form/components/local-formalities/components/SafetyDocuments';
import ConfirmWaste from './features/captains-form/components/marpol/components/ConfirmWaste';
import Marpol from './features/captains-form/components/marpol/components/Marpol';
import MarpolTables from './features/captains-form/components/marpol/components/MarpolTables';
import ArrivalPassengersChanges from './features/captains-form/components/passengers/components/ArrivalPassengersChanges';
import DeparturePassengersChanges from './features/captains-form/components/passengers/components/DeparturePassengersChanges';
import Passengers from './features/captains-form/components/passengers/components/Passengers';
import SpecifyPassengersNumber from './features/captains-form/components/passengers/components/SpecifyPassengersNumber';
import PortCallData from './features/captains-form/components/port-call-data/PortCallData';
import Draft from './features/captains-form/components/port-call-data/components/Draft';
import EtaAndEtd from './features/captains-form/components/port-call-data/components/EtaAndEtd';
import LastAndNexPort from './features/captains-form/components/port-call-data/components/LastAndNextPort';
import Purpose from './features/captains-form/components/port-call-data/components/Purpose';
import BunkeringDetail from './features/captains-form/components/port-call-data/components/BunkeringDetail';
import Questions from './features/captains-form/components/port-call-data/components/Questions';
import Welcome from './features/captains-form/components/port-call-data/components/Welcome';
import ShipCertificates from './features/captains-form/components/ship-certificates/components/ShipCertificates';
import Solas from './features/captains-form/components/solas/components/Solas';
import SolasPortCalls from './features/captains-form/components/solas/components/SolasPortCalls';
import SolasQuestions from './features/captains-form/components/solas/components/SolasQuestions';
import SolasSecurityMeasures from './features/captains-form/components/solas/components/SolasSecurityMeasures';
import Summary from './features/captains-form/components/summary/components/Summary';
import VesselData from './features/captains-form/components/vessel-data/components/VesselData';
import FormElements from './features/forms/components/FormElements.js';
import PortClearanceFormRequest from './features/port-clearance-form-request/components/PortClearanceFormRequest';
import PortClearanceFormRequestSelectedVessel from './features/port-clearance-form-request/components/PortClearanceFormRequestSelectedVessel';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    // errorElement: <div>Page Not Found</div>
    children: [
      {
        path: 'port-clearance-form-request',
        element: <PortClearanceFormRequest />,
      },
      {
        path: 'port-clearance-form-request/vessel/:mmsi',
        element: <PortClearanceFormRequestSelectedVessel />,
      },
      {
        path: 'forms',
        element: <FormElements />,
      },
      {
        path: ':uid/welcome',
        element: <Welcome />,
      },
      {
        path: ':uid/port-call-data',
        element: <PortCallData />,
        children: [
          {
            path: 'eta-etd',
            element: <EtaAndEtd />,
          },
          {
            path: 'ports',
            element: <LastAndNexPort />,
          },
          {
            path: 'purpose',
            element: <Purpose />,
          },
          {
            path: 'bunkering-detail',
            element: <BunkeringDetail />,
          },
          {
            path: 'drafts',
            element: <Draft />,
          },
          {
            path: 'questions',
            element: <Questions />,
          },
        ],
      },
      {
        path: ':uid/vessel-data',
        element: <VesselData />,
      },
      {
        path: ':uid/inspections',
        element: <Inspections />,
      },
      {
        path: ':uid/crew',
        element: <Crew />,
        children: [
          {
            path: 'confirm',
            element: <ConfirmData />,
          },
          {
            path: 'number',
            element: <SpecifyCrewNumber />,
          },
          {
            path: 'arrival-crew',
            element: <ArrivalCrewChanges />,
          },
          {
            path: 'departure-crew',
            element: <DepartureCrewChanges />,
          },
          {
            path: 'effects',
            element: <DeclareEffects />,
          },
        ],
      },
      {
        path: ':uid/passengers',
        element: <Passengers />,
        children: [
          {
            path: 'number',
            element: <SpecifyPassengersNumber />,
          },
          {
            path: 'arrival-passengers',
            element: <ArrivalPassengersChanges />,
          },
          {
            path: 'departure-passengers',
            element: <DeparturePassengersChanges />,
          },
        ],
      },
      {
        path: ':uid/dangerous-goods',
        element: <DangerousGoods />,
      },
      {
        path: ':uid/cargo',
        element: <Cargo />,
        children: [
          {
            path: 'information',
            element: <CargoInformation />,
          },
          {
            path: 'list',
            element: <CargoTable />,
          },
        ],
      },
      {
        path: ':uid/solas-and-isps-code',
        element: <Solas />,
        children: [
          {
            path: 'questions',
            element: <SolasQuestions />,
          },
          {
            path: 'port-calls',
            element: <SolasPortCalls />,
          },
          {
            path: 'security-measures',
            element: <SolasSecurityMeasures />,
          },
        ],
      },
      {
        path: ':uid/local-formalities',
        element: <SafetyDocuments />,
      },
      {
        path: ':uid/marpol',
        element: <Marpol />,
        children: [
          {
            path: 'confirm-waste',
            element: <ConfirmWaste />,
          },
          {
            path: 'tables',
            element: <MarpolTables />,
          },
        ],
      },
      {
        path: ':uid/health',
        element: <Health />,
        children: [
          {
            path: 'first-step',
            element: <FirstHealthStep />,
          },
          {
            path: 'second-step',
            element: <SecondHealthStep />,
          },
          {
            path: 'third-step',
            element: <ThirdHealthStep />,
          },
        ],
      },
      {
        path: ':uid/ship-certificates',
        element: <ShipCertificates />,
      },
      {
        path: ':uid/general-declaration',
        element: <GeneralDeclaration />,
      },
      {
        path: ':uid/summary',
        element: <Summary />,
      },
    ],
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/blocked',
    element: <BlockedPage />,
  },
]);

const Routes = () => <RouterProvider router={router} />;
export default Routes;
