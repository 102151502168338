import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectGeneralData } from '../../../slices/portCallSlice';
import arrowRight from "../styles/images/arrow-right.svg"
import "../.././../styles/welcome.scss";
import usePortsRepository from '../../../../../hooks/usePortsRepository';
import Auth from '../../../../../config/auth'

function Welcome() {
  const navigate = useNavigate();
  const params = useParams();
  const generalPortcallData = useSelector(selectGeneralData);

  const CURRENT_URL = window.location.href;
  const myRe = new RegExp("(?<=t=).*", "g");
  const token = myRe.exec(CURRENT_URL);

  const tokenValidation = new RegExp("^[a-z,0-9,-]{36,36}$", "g");
  const validToken = tokenValidation.test(token);
  if (validToken) {
    Auth.clearSession();
    Auth.storeToken(token);
  }
  const {
    masterTreatment,
    masterName,
    masterSurname,
    unlocode,
    relations
  } = generalPortcallData;
  const { port } = usePortsRepository({ ports: relations?.ports, unlocode });

  if (masterName) {
    return (
      <div className="welcome">
        <div className="welcome__message">
          <h1>Hello {masterTreatment} {masterName} {masterSurname}, welcome to the Port of {port?.name}!</h1>
          <p>Before your arrival, we would appreciate if you could complete the data requested<br /> for the port clearance. Kindly complete this form.</p>
          <p>First-time calls take a bit of time, but we will save up all the data for your next visit!</p>
        </div>


        <button onClick={() => navigate(`/${params.uid}/port-call-data/eta-etd`)}>Lets Start<img className="button__icon-right" src={arrowRight} alt="btn-icon" /></button>
        {/* <Button iconRight={arrowLeft}>Lets Start</Button> */}
      </div>
    );
  }
}

export default Welcome;
