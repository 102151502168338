import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPagedData } from '../../global/utils/fetchPagedData';
import { axiosInstance } from '../../../config/axios';

const initialState = {
  searchResults: [],
  ui: {
    shoudShowLoader: false,
    shouldShowSearchResultsSection: false
  }
};

export const searchVessel = createAsyncThunk(
  'vessels/searchVessel',
  async (searchData) => {
    let endpoint = `/vessels`;

    if (searchData && searchData.imoNumber) {
      endpoint += `?imo=${searchData.imoNumber}`;
    }
    if (searchData && searchData.vesselName) {
      endpoint += `?shipName=${searchData.vesselName}`;
    };

    const data = await fetchPagedData(endpoint, "/vessels");

    return data;
  }
);


export const vesselsSlice = createSlice({
  name: 'vessels',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(searchVessel.pending, (state) => {
        state.ui.shouldShowSearchResultsSection = true;
        state.searchResults = [];
        state.ui.shoudShowLoader = true;
      })
      .addCase(searchVessel.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.ui.shouldShowSearchResultsSection = true;
        state.ui.shoudShowLoader = false;
      })
      .addCase(searchVessel.rejected, (state, action) => {
        state.ui.shouldShowSearchResultsSection = true;
        state.ui.shoudShowLoader = false;
      });
  },
});

export const selectSearchResults = (state) => state.vessels.searchResults;
export const selectLoadingState = (state) => state.vessels.ui.shoudShowLoader;
export const selectShouldShowSearchResultsSection = (state) => state.vessels.ui.shouldShowSearchResultsSection;
export default vesselsSlice.reducer;
