import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import auth from '../../../config/auth';
import { axiosInstance } from '../../../config/axios';
import { fetchPagedData } from '../../global/utils/fetchPagedData';

const initialState = {
  searchResults: [],
  ui: {
    shoudShowLoader: false,
  },
};

export const searchPort = createAsyncThunk('vessels/searchPort', async (searchData) => {
  let endpoint = `/ports/`;
  if (searchData.unlocode) {
    endpoint += `?unlocode=${searchData.unlocode}`;
  } else if (searchData.portName) {
    endpoint += `?name=${searchData.portName}`;
  }

  const data = await fetchPagedData(endpoint, '/ports');

  // mapping into format that dropdown expects
  return data.map((port) => {
    return {
      ...port,
      value: port.unlocode,
      label: port.name + ', ' + port.countryCode,
    };
  });
});

export const searchPortByUnlocode = async ({ unlocode }) => {
  let hasMoreData = true;
  let endpoint = `/ports/?unlocode=${unlocode}`;
  const headers = auth.getHeaders();
  const allData = [];

  while (hasMoreData) {
    const response = await axiosInstance.get(endpoint, { headers });
    const jsonData = response.data;
    allData.push(...jsonData?.data);

    if (!jsonData?.paging?.next) {
      hasMoreData = false;
    } else {
      const nextUrl = jsonData.paging.next.includes('/v1/')
        ? jsonData.paging.next.replace('/v1/', '/')
        : jsonData.paging.next;
      endpoint = nextUrl;
    }
  }

  return allData;
};

export const portsSlice = createSlice({
  name: 'ports',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // signOut: (state) => {
    //   state.jwt = null;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(searchPort.pending, (state) => {
        state.searchResults = [];
        state.ui.shoudShowLoader = true;
      })
      .addCase(searchPort.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.ui.shoudShowLoader = false;
      })
      .addCase(searchPort.rejected, (state, action) => {
        state.ui.shoudShowLoader = false;
      });
  },
});

export const selectPortsSearchResults = (state) => state.ports.searchResults;
export const selectPortsLoadingState = (state) => state.ports.ui.shoudShowLoader;
export default portsSlice.reducer;
