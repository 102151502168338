import * as Yup from 'yup';
import { ALL_WASTE_DISPOSAL_APPROACH, PARTIALLY_WASTE_DISPOSAL_APPROACH } from './utils/marpolUtils';
import { isBefore, isAfter, isEqual } from 'date-fns';

const isNotEmptyValue = (value) => value !== null && value !== undefined && value !== '';
const isEmptyValue = (value) => !isNotEmptyValue(value);

export const filterFilledValuesOfWasteItems = (shouldDischargeWaste, waste) => {
  return waste.filter((wasteItem) => {
    const { onboardQuantity, wasteToBeDelivered, maxDedicatedStorage, estimatedAmountOfWaste, nextPortToDeliver } =
      wasteItem;
    const valuesToBeFilled = [onboardQuantity, maxDedicatedStorage, estimatedAmountOfWaste, nextPortToDeliver];
    if (shouldDischargeWaste) valuesToBeFilled.push(wasteToBeDelivered);

    return valuesToBeFilled.some((value) => isNotEmptyValue(value));
  });
};

const getFilledValuesOfWasteItems = (shouldDischargeWaste, waste) => {
  return waste
    .map((wasteItem) => {
      const { onboardQuantity, wasteToBeDelivered, maxDedicatedStorage, estimatedAmountOfWaste, nextPortToDeliver } =
        wasteItem;
      const valuesToBeFilled = [onboardQuantity, maxDedicatedStorage, estimatedAmountOfWaste, nextPortToDeliver];
      if (shouldDischargeWaste) valuesToBeFilled.push(wasteToBeDelivered);

      return valuesToBeFilled;
    })
    .filter((item) => {
      return item.some((value) => isNotEmptyValue(value));
    });
};

export const validationWasteSchema = (shouldDischargeWaste, waste) => {
  const filledValuesOfWasteItems = getFilledValuesOfWasteItems(shouldDischargeWaste, waste);
  return Yup.object()
    .test('at-least-one-row', '* You should fill at least one row.', () => {
      const validWasteItems = filledValuesOfWasteItems.filter((filledValues) => {
        return filledValues.every((item) => isNotEmptyValue(item));
      });
      return validWasteItems.length > 0;
    })
    .test(
      'check-none-partial-filled-rows',
      '*You should fill all the fields in the row or leave all of them empty.',
      () => {
        const pendingWasteItems = filledValuesOfWasteItems.filter((filledValues) => {
          return filledValues.some((item) => isEmptyValue(item));
        });
        return pendingWasteItems.length === 0;
      }
    );
};

export const validationOverlapDateSchema = (
  editingRow,
  dateFrom,
  dateTo,
  allItems,
  dateFromInputName,
  dateToInputName,
  portcallEta,
  isCheckingDateFrom = false
) => {
  let idEntryOverlaped = null;
  let isOverlapDate = false;
  const parseDateFrom = dateFrom ? new Date(dateFrom) : null;
  const parseDateTo = dateTo ? new Date(dateTo) : null;
  const dateToCheck = isCheckingDateFrom ? parseDateFrom : parseDateTo;
  allItems.forEach((item, index) => {
    if (index !== editingRow) {
      const dateFromItem = item?.[dateFromInputName] ? new Date(item?.[dateFromInputName]) : null;
      const dateToItem = item?.[dateToInputName] ? new Date(item?.[dateToInputName]) : null;

      const isDateFromOrDateToOverlapWithAnyDate =
        dateFromItem &&
        dateToItem &&
        (isBefore(dateFromItem, dateToCheck) || isEqual(dateFromItem, dateToCheck)) &&
        (isAfter(dateToItem, dateToCheck) || isEqual(dateToItem, dateToCheck));
      const isDateFromAndDateToWrapAnyRangeOfDate =
        dateFromItem &&
        dateToItem &&
        parseDateFrom &&
        parseDateTo &&
        (isBefore(parseDateFrom, dateFromItem) || isEqual(parseDateFrom, dateFromItem)) &&
        (isAfter(parseDateTo, dateToItem) || isEqual(parseDateTo, dateToItem));

      if (isDateFromOrDateToOverlapWithAnyDate) {
        idEntryOverlaped = idEntryOverlaped ? idEntryOverlaped : index + 1;
        isOverlapDate = true;
      } else if (isDateFromAndDateToWrapAnyRangeOfDate) {
        idEntryOverlaped = idEntryOverlaped ? idEntryOverlaped : index + 1;
        isOverlapDate = true;
      }
    }
  });

  if (!dateFrom && isCheckingDateFrom) {
    return Yup.object().required('This field is required');
  } else if (!dateTo && !isCheckingDateFrom) {
    return Yup.object().required('This field is required');
  }
  return Yup.date()
    .required('This field is required')
    .test(
      'check-no-overlap-date-with-other-items',
      `Adjust date/time to avoid conflicts with port call entry: ${idEntryOverlaped}`,
      () => {
        return !isOverlapDate;
      }
    )
    .test('check-date-is-before-portcall-ETA', 'The date must be before ETA Portcall', () => {
      const parsePortcallEta = portcallEta ? new Date(portcallEta) : null;
      if (!dateToCheck || !parsePortcallEta) return true;
      return isBefore(dateToCheck, parsePortcallEta) && !isEqual(dateToCheck, parsePortcallEta);
    })
    .test('check-dates-not-cross-themselves', 'The date/time is crossed between themselves', () => {
      if (!parseDateFrom || !parseDateTo) return true;
      return isBefore(parseDateFrom, parseDateTo) && !isEqual(parseDateFrom, parseDateTo);
    });
};

export const validationDeliveryWasteSchema = (shouldDischargeWaste) => {
  return Yup.object({
    wasteDisposalApproach: shouldDischargeWaste
      ? Yup.string()
          .oneOf([PARTIALLY_WASTE_DISPOSAL_APPROACH, ALL_WASTE_DISPOSAL_APPROACH], 'Select one of these values')
          .required()
      : Yup.string(),
  });
};
