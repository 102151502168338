import { Field } from "@navozyme/uikit/dist/atoms/Field";
import { DatePickerTimeInput } from "@navozyme/uikit/dist/molecules/DatePicker";
import { format } from 'date-fns';
import { Form, Formik } from "formik";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DragAndDrop from "../../../../forms/components/DragAndDrop";
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import PlaceOfBirthDropdown from '../../../../forms/components/place-of-birth/components/PlaceOfBirthDropdown';
import SearchPortsDropdown from "../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown";
import { NotificationAlert } from "../../../../global/alertModal/alertModal";
import { cleanEmptyData } from '../../../../global/utils/cleanEmptyData';
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import { API_REQUEST_FORMAT_DATE } from '../../../../global/utils/datetimes';
import { genderOptions, idNatureOptions, rankOptions } from '../../../../global/utils/globalOptions';
import { validateSchema } from '../../../../global/utils/validateForms';
import Button from '../../../../layout/components/Button';
import ConfirmationModal from "../../../../layout/components/ConfirmationModal";
import Modal from "../../../../layout/components/Modal";
import CrewListTable from '../../../../tables/components/CrewListTable';
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import { getCrew, importCrewList, updateCrew } from '../../../slices/portCallSlice';
import { DOCUMENT_TYPE_NAMES, DocumentCategory, DocumentSubCategory } from "../../documents/constants";
import { getDocumentTypes } from "../../documents/slices/documentsSlice";
import {
  convertDataFromApi,
  crewListColums,
  crewMemberInitialValues,
  crewMemberValidationSchema,
  fetchCrewMemberData,
  OTHER_RANK
} from "../crewUtils";
import "../styles/crew-list-table.scss";
import ConfirmDeleteModal from './ConfirmDeleteModal';

function DefaultCrewTable(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const crewListDocumentLink = props.crewListDocumentLink;
  const crewList = props.crewList; // crew.crewList.map((crewMember, index) => { return { ...crewMember, index } });
  const sectionCrewList = props.sectionCrewList; //crewList.filter(crewMember => crewMember.portOfEmbarkation !== portUnlocode);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenNotification, setOpenNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationType, setNotificationType] = useState("error");
  const [initialValues, setInitialValues] = useState(crewMemberInitialValues);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const handleSubmit = async (values, _) => {
    const finalValues = {
      ...values,
      IDExpiryDate: values.IDExpiryDate ? format(new Date(values.IDExpiryDate), API_REQUEST_FORMAT_DATE) : values.IDExpiryDate,
      birthDate: values.birthDate ? format(new Date(values.birthDate), API_REQUEST_FORMAT_DATE) : values.birthDate,
      embarkationDatetime: values.embarkationDatetime ? new Date(values.embarkationDatetime) : null,
      disembarkationDatetime: values.disembarkationDatetime ? new Date(values.disembarkationDatetime) : null,
      portOfEmbarkation: values?.portOfEmbarkation?.unlocode ? values?.portOfEmbarkation?.unlocode : null,
      portOfDisembarkation: values?.portOfDisembarkation?.unlocode ? values?.portOfDisembarkation?.unlocode : null
    };

    cleanEmptyData(finalValues);

    if (isEditingRow) {
      const data = {
        crewList: crewList.map((member, i) => {
          return i === editingRow ? finalValues : member;
        })
      };

      dispatch(updateCrew({ uid: params.uid, data }));

      setIsModalOpen(false);
      setEditingRow(null);
    } else {
      const data = { crewList: crewList.concat(finalValues) };

      Object.keys(data).forEach((object) => {
        data[object].forEach((dataObject) => {
          cleanEmptyData(dataObject)
        })
      });

      dispatch(updateCrew({ uid: params.uid, data }));
      setIsModalOpen(false);
    }
  };

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [disableImport, setDisableImport] = useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const row = isEditingRow ? crewList[editingRow] : null;

  useEffect(() => {
    const fetchData = async () => {
      const newInitialValues = await fetchCrewMemberData(dispatch, row);
      setInitialValues(newInitialValues);
    };

    if (row) fetchData();
  }, [row])

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);
  const [useOtherRankInput, setUseOtherRankInput] = useState(false);

  const onRankChange = (e) => {
    const selectedRank = e.value
    const isOtherRank = selectedRank === OTHER_RANK

    setUseOtherRankInput(isOtherRank)
  }

  useEffect(() => {
    setUseOtherRankInput(false);

    if (isEditingRow && row.rank === OTHER_RANK) {
      setUseOtherRankInput(true);
    }
  }, [editingRow])

  useEffect(() => {
    dispatch(getDocumentTypes())
  }, []);
  const openGoogleSheetLink = () => {
    if (crewListDocumentLink == '' || crewListDocumentLink == undefined) {
      setOpenNotification(true);
      setNotificationTitle("Document link is not available.");
      setNotificationType("error");
    } else {
      window.open(crewListDocumentLink, '_blank');
      setDisableImport(false);
    }

  };
  const refreshCrewList = async () => {
    let response = await dispatch(importCrewList(params.uid));
    if (response?.payload?.statusCode) {
      setOpenNotification(true);
      setNotificationTitle("Document is saved.");
      setNotificationType("success");
      dispatch(getCrew(params.uid));
    } else {
      setOpenNotification(true);
      setNotificationTitle("Document save is failed.");
      setNotificationType("error");
    }
  };

  return (
    <>
      <div className="crew-list-table">
        <NotificationAlert id={"Alert-Information"} alertType={notificationType}
          isOpened={isOpenNotification}
          setIsOpened={(open) => {
            setOpenNotification(open)
          }}
          title={notificationTitle}
          autoClose={notificationType == 'error' ? false : true}
          showCloseButton={true}
        />
        <CrewListTable
          useRowNumbers
          editorButtonText={'Open editor'}
          importButtonText={'Import from editor'}
          data={convertDataFromApi(sectionCrewList)}
          columns={crewListColums}
          onEditorButtonClick={() => {
            openGoogleSheetLink();
          }}
          onImportButtonClick={(rowIndex) => {
            refreshCrewList();
          }}
          disabled={disableImport}
        />
        <DragAndDrop
          openModalButtonText={"Upload crew list"}
          uploadButtonText={"Upload"}
          acceptedFormats={['.pdf', '.csv', '.xls', '.xlsx']}
          category={props.documentCategory}
          documentTypeId={props.documentTypeId}
          documentCategory={DocumentCategory.DUE_DOCUMENTS}
          documentSubCategory={DocumentSubCategory.Captain}
          documentTypeName={DOCUMENT_TYPE_NAMES.CREW_PDF_DOCUMENT}
          uploadButtonAtLeft={true}
        />
        <Modal
          className={`add-crew-member-modal`}
          title={"Add a crew member"}
          showModal={isModalOpen}
          onCloseModal={() => {
            setIsModalOpen(false);
          }}>
          <ConfirmationModal
            showModal={isConfirmationModalOpen}
            handleCloseModal={setIsConfirmationModalOpen}
            willDisableSave={formValues}
            onAcceptModal={() => {
              setIsConfirmationModalOpen(false);
              setIsModalOpen(false);
              handleSubmit(formValues)
            }}
            className='' />
          <div className="add-crew-member-modal__content">
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={false}
              validateOnMount={false}
              validate={(values) => {
                return validateSchema(values, crewMemberValidationSchema, setFormValues, setIsConfirmationModalOpen, setIsModalOpen)
              }}>
              {({ values, setValues, errors }) => {
                return (<Form className="add-crew-member-form">
                  <div className="add-crew-member-form__row">
                    <Input name="surname" placeholder="Type one" label="Family Name" />
                    <Input name="name" placeholder="Type one" label="Given Names" />
                  </div>
                  <div className="add-crew-member-form__row">
                    <MultiSelect
                      name="rank"
                      options={rankOptions}
                      isMulti={false}
                      label="Rank or rating"
                      placeholder="Choose"
                      onChange={onRankChange}
                    />
                    {useOtherRankInput && <Input name="otherRank" placeholder="Other rank" label="Other rank" />}

                    <MultiSelect
                      name="nationality"
                      options={countryListAllIsoData}
                      isMulti={false}
                      label="Nationality"
                      placeholder="Choose" />
                    <MultiSelect
                      name="gender"
                      options={genderOptions}
                      isMulti={false}
                      label="Gender"
                      placeholder="Choose" />
                  </div>
                  <div className="add-crew-member-form__row">
                    <Field label="Date of birth" className="field_date_picker">
                      <DatePickerTimeInput
                        key={initialValues?.birthDate + "birthDate"}
                        name="birthDate"
                        sizeInput="large"
                        maxDate={new Date()}
                        showTime={false}
                        defaultValue={
                          initialValues?.birthDate
                        }
                        action={({ isoDate }) => {
                          setValues({ ...values, birthDate: new Date(isoDate) })
                        }
                        }
                        hasError={errors?.birthDate}
                        errorMessage={errors?.birthDate}
                      />
                    </Field>
                    <PlaceOfBirthDropdown name="birthPlace" dropdownID="crewBirthPlace" />
                  </div>
                  <div className="add-crew-member-form__row">
                    <MultiSelect
                      name="IDNature"
                      options={idNatureOptions}
                      isMulti={false}
                      label="Type of identity or travel document"
                      placeholder="Choose one" />
                    <Input name="IDNumber" placeholder="Type one" label="Serial number of identity or travel document" />
                  </div>
                  <div className="add-crew-member-form__row">
                    <Field label="Expiry date of identity or travel document" className="field_date_picker">
                      <DatePickerTimeInput
                        key={initialValues?.IDExpiryDate + "IDExpiryDate"}
                        name="IDExpiryDate"
                        sizeInput="large"
                        minDate={new Date()}
                        showTime={false}
                        defaultValue={
                          initialValues?.IDExpiryDate
                        }
                        action={({ isoDate }) =>
                          setValues({ ...values, IDExpiryDate: new Date(isoDate) })

                        }
                        hasError={errors?.IDExpiryDate}
                        errorMessage={errors?.IDExpiryDate}
                      />
                    </Field>
                    <MultiSelect
                      name="IDIssuingState"
                      options={countryListAllIsoData}
                      isMulti={false}
                      label="Issuing State of identity or travel document"
                      placeholder="Type one" />
                  </div>
                  <div className="add-crew-member-form__row">
                    <SearchPortsDropdown
                      label="Port of embarkation"
                      name="portOfEmbarkation"
                      dropdownID="portOfEmbarkation"
                      placeholder="Select One"
                    />
                    <Field label="Sign-on Date" className="field_date_picker">
                      <DatePickerTimeInput
                        key={initialValues?.embarkationDatetime + "embarkationDatetime"}
                        name="embarkationDatetime"
                        sizeInput="large"
                        maxDate={new Date()}
                        showTime={false}
                        defaultValue={
                          initialValues?.embarkationDatetime
                        }
                        action={({ isoDate }) =>
                          setValues({ ...values, embarkationDatetime: new Date(isoDate) })

                        }
                        hasError={errors?.embarkationDatetime}
                        errorMessage={errors?.embarkationDatetime}
                      />
                    </Field>
                  </div>
                  <div className="add-crew-member-form__row">
                    <SearchPortsDropdown
                      label="Port of disembarkation"
                      name="portOfDisembarkation"
                      dropdownID="portOfDisembarkation"
                      placeholder="Select One"
                    />
                    <Field label="Disembarkation Date" className="field_date_picker">
                      <DatePickerTimeInput
                        key={initialValues?.disembarkationDatetime + "disembarkationDatetime"}
                        name="IDExpiryDate"
                        sizeInput="large"
                        minDate={new Date()}
                        showTime={false}
                        defaultValue={
                          initialValues?.disembarkationDatetime
                        }
                        action={({ isoDate }) =>
                          setValues({ ...values, disembarkationDatetime: new Date(isoDate) })

                        }
                        hasError={errors?.disembarkationDatetime}
                        errorMessage={errors?.disembarkationDatetime}
                      />
                    </Field>
                  </div>
                  {
                    isEditingRow ? (
                      <div className="add-member__form-submission-btns">
                        <div className="discard-btn" onClick={() => {
                          setIsModalOpen(false);
                          setTimeout(() => setEditingRow(null), 300)
                        }}>Discard
                        </div>
                        <Button type="submit">Change Information</Button>
                      </div>
                    ) : (
                      <div className="add-member__form-submission-btns">
                        <Button iconLeft={plusIcon} type="submit">Add</Button>
                      </div>
                    )
                  }
                </Form>)
              }}
            </Formik>
          </div>
        </Modal>
        <ConfirmDeleteModal
          title={<h3>Are you sure you want to <span>delete all the <br />crew members</span> from the list?</h3>}
          show={showConfirmDeleteAllMembersModal}
          onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
          onDeleteClick={() => {
            setShowConfirmDeleteAllMembersModal(false);
            const indexToRemove = sectionCrewList.map((crewItem) => crewItem.index);
            const updatedCrewList = crewList.filter((crewItem) => !indexToRemove.includes(crewItem.index));

            dispatch(updateCrew({
              uid: params.uid,
              data: { crewList: updatedCrewList }
            }))

          }}
          onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
        />
        <ConfirmDeleteModal
          title={<h3>Are you sure you want to delete this crew member?</h3>}
          show={confirmDeleteMemberModalData.showModal}
          onCancelClick={() => {
            setConfirmDeleteMemberModalData({
              index: null,
              showModal: false
            })
          }}
          onDeleteClick={() => {
            const updatedCrewList = [...crewList];
            updatedCrewList.splice(confirmDeleteMemberModalData.index, 1);

            dispatch(updateCrew({
              uid: params.uid,
              data: { crewList: updatedCrewList }
            }))

            setConfirmDeleteMemberModalData({
              index: null,
              showModal: false
            })
          }}
          onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
        />
      </div >
    </>
  );
}


DefaultCrewTable.propTypes = {
  title: PropTypes.string.isRequired,
  crewList: PropTypes.array.isRequired,
  sectionCrewList: PropTypes.array.isRequired,
  documentCategory: PropTypes.string.isRequired,
  documentTypeId: PropTypes.string.isRequired,
};

export default DefaultCrewTable;
