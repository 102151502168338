import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { axiosInstance } from '../../../config/axios';
import auth from '../../../config/auth';

const initialState = {
  generateFormModalOpen: false,
  portCallCreationStatus: "initial",
  uiid: ""
};


export const createPortCallRequest = createAsyncThunk(
  'portCallRequest/createPortCallRequest',
  async (portCallData) => {
    portCallData.uid = uuidv4();

    const headers = auth.getHeaders();
    await axiosInstance.post(`/portcalls/`, {
      ...portCallData,
      masterSurname: "Smith"
    }, {
      headers
    });

    return {
      uiid: portCallData.uid
    }
  }
);

export const portCallRequest = createSlice({
  name: 'portCallRequest',
  initialState,
  reducers: {
    toggleGenerateFormModal: (state) => {
      state.generateFormModalOpen = !state.generateFormModalOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPortCallRequest.pending, (state) => {
        state.portCallCreationStatus = "creating";
      })
      .addCase(createPortCallRequest.fulfilled, (state, action) => {
        state.uiid = action.payload.uiid;
        state.portCallCreationStatus = "success";
      })
      .addCase(createPortCallRequest.rejected, (state, action) => {
      });
  },
});



export const { toggleGenerateFormModal } = portCallRequest.actions;
export const selectPortcallUiid = (state) => state.portCallRequest.uiid;
export const selectIsGenerateFormModalOpen = (state) => state.portCallRequest.generateFormModalOpen;
export const selectPortCallCreationStatus = (state) => state.portCallRequest.portCallCreationStatus;
export default portCallRequest.reducer;
