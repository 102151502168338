import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import "../styles/select.scss";
import { getStyles } from '../helpers/random-form-helpers';

function CustomSelect(props) {
  const { field, form, form: { errors }, ...rest } = props;
  return <select {...field}  {...rest} style={form.touched[field.name] && getStyles(errors, field.name)} />
}

export default function Select(props) {
  const { label, name, options, ...rest } = props;

  return (
    <div className="form-control select">
      <label className="select__label" htmlFor={name}>{label}</label>
      <Field component={CustomSelect} as="select" id={name} name={name} {...rest}>
        {
          options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.key}
              </option>
            )
          })
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
