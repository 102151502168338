import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { selectAnnexFour } from "../slices/marpolSlice";
import { useSelector } from "react-redux";
import { emptyHandleSubmit } from "./MarpolTables"
import WasteRow from "./WasteRow";
import WasteHeader from "./WasteHeader";

export default function AnnexFourTable(props) {
  const annexFour = useSelector(selectAnnexFour);
  const [initialValues, setInitialValues] = useState(annexFour || {});

  useEffect(() => {
    setInitialValues(annexFour || {});
  }, [props.disableWasteDelivered, annexFour]);

  return (
    <div className="marpol-table" style={{ display: props.isDisplayed ? "flex" : "none" }}>
      <WasteHeader />
      <Formik
        innerRef={props.formRef}
        onSubmit={emptyHandleSubmit}
        initialValues={initialValues}
        enableReinitialize
        >
        {({values}) => (
          <Form className="cargo-information-form">
            <div className="marpol-table__body">
              <WasteRow wasteName="Sewage" imoWasteCode="sewage" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.sewage } />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
