import {format, isAfter, isBefore, isEqual} from "date-fns";

export const DEFAULT_FORMAT_DATE = 'dd/MM/yyyy';
export const DEFAULT_FORMAT_DATETIME = 'dd/MM/yyyy HH:mm';
export const API_REQUEST_FORMAT_DATE = 'yyyy-MM-dd';

export const isBeforeToday = (dateItem: Date | undefined): boolean => {
    if (!dateItem) return false;

    return dateItem < new Date();
};

export const formatDateInTable = (props: any) => { return  props.value ? (format(new Date(props.value), DEFAULT_FORMAT_DATE)) : '' }

export const formatDateTimeInTable = (props: any) => { return  props.value ? (format(new Date(props.value), DEFAULT_FORMAT_DATETIME)) : '' }

export const formatDate = (value: any, dateFormat:string = DEFAULT_FORMAT_DATETIME) => { return  value ? (format(new Date(value), dateFormat)) : '' }

export const isDateFromOrDateToOverlapWithAnyDate = (dateToCheck: Date, dateFrom: Date, dateTo: Date)=>{
    return dateFrom &&
    dateTo &&
    (isBefore(dateFrom, dateToCheck) ||
        isEqual(dateFrom, dateToCheck)) &&
    (isAfter(dateTo, dateToCheck) || isEqual(dateTo, dateToCheck))
}

export const isCrossedDates = (
    parseDateFrom: Date,
    parseDateTo: Date,
    includeEqual = false
) => {
    return (
        isBefore(parseDateTo, parseDateFrom) ||
        (includeEqual && isEqual(parseDateTo, parseDateFrom))
    );
};
