import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, Navigate, useParams } from 'react-router-dom';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getHealthDeclaration } from '../slices/healthSlice';

const Health = () => {
  const params = useParams();
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const [percentage, setPercentage] = useState<string>("35");
  const [pageNumber, setPageNumber] = useState<string>("1");

  useEffect(() => {
    dispatch(getHealthDeclaration(params.uid || ''));
  }, []);

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === `/${params.uid}/health/first-step`) {
      setPercentage("35");
      setPageNumber("1");
    } else if (location.pathname === `/${params.uid}/health/second-step`) {
      setPercentage("70");
      setPageNumber("2");
    } else if (location.pathname === `/${params.uid}/health/third-step`) {
      setPercentage("100");
      setPageNumber("3");
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/health`) {
    return <Navigate to={`/${params.uid}/health/first-step`} replace />;
  }

  return (
    <Page>
      <div className="health">
        <ProgressBar percentage={percentage} pageNumber={pageNumber} totalPages={"3"} />
        <Outlet />
      </div>
    </Page>
  );
}

export default Health;
