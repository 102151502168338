import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { selectAnnexSix } from "../slices/marpolSlice";
import { useSelector } from "react-redux";
import { emptyHandleSubmit } from "./MarpolTables"
import WasteRow from "./WasteRow";
import WasteHeader from "./WasteHeader";

export default function AnnexSixTable(props) {
  const annexSix = useSelector(selectAnnexSix);
  const [initialValues, setInitialValues] = useState(annexSix || {});

  useEffect(() => {
    setInitialValues(annexSix || {});
  }, [props.disableWasteDelivered, annexSix]);

  return (
    <div className="marpol-table" style={{ display: props.isDisplayed ? "flex" : "none" }}>
      <WasteHeader />
      <Formik
        innerRef={props.formRef}
        onSubmit={emptyHandleSubmit}
        initialValues={initialValues}
        enableReinitialize>
        {({values}) => (
          <Form className="cargo-information-form">
            <div className="marpol-table__body">
              <WasteRow 
                wasteName="Ozone-depleting substances and equipment containing such substances"
                imoWasteCode="ozoneDepletingSubstances"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                { ...values?.ozoneDepletingSubstances }
              />
              <WasteRow 
                wasteName="Exhaust gas-cleaning residues"
                imoWasteCode="exhaustGasCleaningResidues"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                { ...values?.exhaustGasCleaningResidues }
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
