import { axiosInstance } from "../../../config/axios";
import auth from "../../../config/auth";

export async function fetchPagedData(url, stringToReplace) {
  let data = [];
  let nextUrl = url;

  while (nextUrl !== '') {

    const headers = auth.getHeaders();

    const response = await axiosInstance.get(nextUrl, {
      headers
    });

    // Add new data to existing data
    data = data.concat(response.data.data);

    // Extract the remaining part of the "next" string
    const nextPath = response.data.paging.next ? response.data.paging.next.replace(stringToReplace, '') : '';

    // Build the next URL by concatenating the remaining part to the original URL
    nextUrl = nextPath ? `${url}${nextPath}` : '';
  }

  return data;
}