import React from 'react';
import "../styles/summary.scss";

const SummaryDocumentTag = (props) => {
  return (
    <div className="document-tag">
      <label className="document-tag-title">{props.title}</label>&nbsp;
      <label className="document-tag-value">{props.value}</label>
    </div>
  );
}

export default SummaryDocumentTag;
