const currentCompanyIsNotInCompanies = (companies, currentCompanyName) => {
  const index = companies.findIndex(company => company.name === currentCompanyName);
  return index === -1;
}

export const getShipCompanyOptions = (companies, vesselCompanyName) => {
  const shipCompanyOptions = companies?.map(company => {
    return {
      value: company.name,
      label: company.name }
  }) || [];
  if (vesselCompanyName && currentCompanyIsNotInCompanies(companies, vesselCompanyName)) {
    shipCompanyOptions.push(
      { value: vesselCompanyName, label: vesselCompanyName }
    );
  }
  return shipCompanyOptions;
};