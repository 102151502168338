import React from "react";
import PropTypes from 'prop-types';
import Input from "../../../../forms/components/Input";
import SearchPortsDropdown from "../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown";
import { disabledAnnexColumn } from "../utils/marpolUtils";
import { getRetainedWasteAmount } from "../utils/waste";

export default function WasteRow({ 
  wasteName,
  imoWasteCode,
  isWasteToBeDeliveredDisabled,
  additionalComponent,
  onboardQuantity,
  wasteToBeDelivered,
  maxDedicatedStorage,
  estimatedAmountOfWaste,
}) {

  return (
    <div className="marpol-table__row">
      {additionalComponent ? (
        <div className="cell mixed-type">
          <span>{wasteName}</span>
          {additionalComponent}
        </div>
      ) : (
        <div className="cell">{wasteName}</div>
      )}
      <div className="cell"><Input name={`${imoWasteCode}.onboardQuantity`} type="number" step="0.01" min="0" placeholder={onboardQuantity || 0} /></div>
      <div className="cell"><Input name={`${imoWasteCode}.wasteToBeDelivered`} type="number" step="0.01" min="0" placeholder={wasteToBeDelivered || 0} {...disabledAnnexColumn(isWasteToBeDeliveredDisabled)} /></div>
      <div className="cell"><Input name={`${imoWasteCode}.maxDedicatedStorage`} type="number" step="0.01" min="0" placeholder={maxDedicatedStorage || 0}  /></div>
      <div className="cell">{getRetainedWasteAmount(onboardQuantity, wasteToBeDelivered)}</div>
      <div className="cell"><Input name={`${imoWasteCode}.estimatedAmountOfWaste`} type="number" step="0.01" min="0" placeholder={estimatedAmountOfWaste || 0} /></div>
      <div className="cell">
        <SearchPortsDropdown
          name={`${imoWasteCode}.dropdownPort`}
          dropdownID={`${imoWasteCode}.port`}
          placeholder="Unknown" />
      </div>
    </div>
  )
}

WasteRow.propTypes = {
  wasteName: PropTypes.string.isRequired,
  imoWasteCode: PropTypes.string.isRequired,
  isWasteToBeDeliveredDisabled: PropTypes.bool.isRequired,
  additionalComponent: PropTypes.element,
  onboardQuantity: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  wasteToBeDelivered: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  maxDedicatedStorage: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  estimatedAmountOfWaste: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};
