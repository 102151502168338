import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../../config/axios';
import auth from '../../../../../config/auth';
import { setHealthDeclaration } from '../../../slices/portCallSlice';

interface UpdateHealthParams {
  uid: string;
  healthData: any;
}

interface WHOPortType {
  name: string;
  unlocode: string;
  dateFrom: string;
  dateTo: string;
}

interface IspsPortType {
  portcallUid: string
  portUid: string;
  portName: string;
  portUnLoCode: string;
  arrivalDate: string;
  departureDate: string;
  portCountry: string
  portFacilityImoNumber: string;
  portSecurityLevel: string;
  portNameDropdown: any;
}

export const getHealthUrl = (uid: string): string => {
  return `/portcalls/${uid}/health-declaration`;
};

export const getHealthDeclaration: AsyncThunk<any, string, {}> = createAsyncThunk(
  'portCall/getHealth',
  async (uid) => {
    const headers = auth.getHeaders();
    let healthResponse = await axiosInstance.get(getHealthUrl(uid), { headers });
    let ispsResonse = await axiosInstance.get(`/portcalls/${uid}/isps`, { headers });

    let updatedPortsVisitedWithinWHO: WHOPortType[] = [...healthResponse?.data?.data?.portsVisitedWithinWHO];

    const ispsList: WHOPortType[] = ispsResonse?.data?.data?.last10PortCalls.map((ispsPortCall: IspsPortType) => ({
      name: ispsPortCall.portName,
      unlocode: ispsPortCall.portUnLoCode,
      dateFrom: ispsPortCall.arrivalDate,
      dateTo: ispsPortCall.departureDate,
    }));

    if (updatedPortsVisitedWithinWHO.length === 0) {
      updatedPortsVisitedWithinWHO = ispsList;
    } else if (ispsList.length > updatedPortsVisitedWithinWHO.length) {
      ispsList.forEach((ispsPort) => {
        const isDuplicate = updatedPortsVisitedWithinWHO.some(
          (visitedPort) =>
            visitedPort.name === ispsPort.name &&
            visitedPort.unlocode === ispsPort.unlocode &&
            visitedPort.dateFrom === ispsPort.dateFrom &&
            visitedPort.dateTo === ispsPort.dateTo
        );

        if (!isDuplicate) {
          updatedPortsVisitedWithinWHO.push(ispsPort);
        }
      });

      updatedPortsVisitedWithinWHO.sort((a, b) => {
        return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime();
      });
    }

    healthResponse.data.data.portsVisitedWithinWHO = updatedPortsVisitedWithinWHO;
    setHealthDeclaration(healthResponse.data.data);
    return healthResponse.data.data;
  }
);


export const updateHealthDeclaration = createAsyncThunk<any, UpdateHealthParams,  {}>(
  'portCall/updateHealth',
  async (data: UpdateHealthParams) => {
    try {
      const headers = auth.getHeaders();
      await axiosInstance.put(getHealthUrl(data.uid), {
        ...data.healthData
      }, { headers });

      return data;
    } catch (err) {
    }
  }
);

export const selectHealthDeclaration = (state: any) => state.portCall.portCall.healthDeclaration;
