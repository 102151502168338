import React from 'react'
import Select from 'react-select'
import { getStyles } from '../helpers/random-form-helpers';

const ReactSelect = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder = 'Select',
  onInputChange,
  isLoading,
  disabled,
  onChange,
  ...rest
}) => {
  const getValue = () => {
    if (options.length) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [field.value] : field.value;
    }
  };
  
  const customStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: '0.7rem', // change this value to the desired font size
    }),
  };

  if (!isMulti) {

    return (
      <Select
        classNamePrefix="react-select"
        menuPosition="fixed"
        styles={customStyles}
        options={options}
        name={field.name}
        isLoading={isLoading}
        className={(form?.touched[field.name] && getStyles(form?.errors, field.name)) ? "react-select-container error" : "react-select-container"}
        value={getValue()}
        onChange={(option) => {
          if (onChange) {
            onChange(option)
          }
          if (form?.setFieldValue)
            form.setFieldValue(field.name, option.value)
        }}
        onInputChange={onInputChange}
        onBlur={() => { if (form?.handleBlur) form.handleBlur({ target: { name: field.name } })}}
        placeholder={placeholder}
        isDisabled={disabled}
        {...rest}
      />
    )

  } else {
    return (
      <Select
        classNamePrefix="react-select"
        className={(form?.touched[field.name] && getStyles(form?.errors, field.name)) ? "react-select-container error" : "react-select-container"}
        name={field.name}
        value={getValue()}
        styles={customStyles}
        onChange={(option) => {
          form.setFieldValue(
            field.name,
            option ? (option).map((item) => item.value) : [],
          );
        }}
        options={options}
        menuPosition="fixed"
        isMulti={true}
        onBlur={() => {if (form?.handleBlur) form.handleBlur({ target: { name: field.name } })}}
        placeholder={placeholder}
        isDisabled={disabled}
        {...rest}
      />
    )
  }
}

export default ReactSelect;
