import React from 'react';
import "../styles/page-header.scss";

export default function PageHeader(props) {
  return (
    <div className="page-header">
      <h2 className="page-title">{props.title}</h2>
      {props.subtitle && (
        <p className="page-subtitle">{props.subtitle}</p>
      )}
    </div>
    
  );
}

