export const validateSchema = (values, validationSchema, setFormValues, setIsConfirmationModalOpen, setIsModalOpen) => {
  try {
    validationSchema.validateSync(values, {abortEarly: false})
    setIsModalOpen(false);
    return {};
  } catch (err) {
    let errors = {};
    err.inner.forEach(error => {
      errors[error.path] = error.message;
    }
    );
    if (errors?.ems !== "Invalid format")
      setIsConfirmationModalOpen(true);
    setFormValues(values)
    return errors;
  }

}
