import React from 'react';
import { Navigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import AuthLayout from '../../layout/components/AuthLayout';
import { selectJWT, signup } from '../slices/authSlice';
import AuthPage from './AuthPage';
import "../styles/auth-page.scss";

export default function Signup(props) {
  const jwt = useSelector(selectJWT);
  const dispatch = useDispatch();

  if (jwt) {
    return <Navigate to="/port-clearance-form-request" replace={true} />
  }

  return (
    <AuthLayout>
      <AuthPage>
        <h3>Welcome to N-MAP</h3>
        <button onClick={() => dispatch(signup())}>Signup</button>
        <p>Already have an account? <NavLink to={`/login`}>Sign in</NavLink></p>
      </AuthPage>
    </AuthLayout>
  );
}
