import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../../../layout/components/Page';
import PageHeader from '../../../../layout/components/PageHeader';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { selectSafetyDocumentsByTitle } from '../../documents/slices/documentsSlice';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import '../styles/safety-documents.scss';
import UploadDownloadBox from './UploadDownloadBox';

function SafetyDocuments() {
  let navigate = useNavigate();
  const params = useParams();
  const safetyDocumentsByTitle = useSelector(selectSafetyDocumentsByTitle);

  const orderedCategories = ['Terminals', 'Pilots', 'Health'];

  const renderBody = () => {
    return _.isEmpty(safetyDocumentsByTitle) ? renderEmptyDocuments() : renderDocuments();
  };

  const renderDocuments = () => {
    return (
      <>
        <PageHeader title='Local formalities' />
        {orderedCategories.map((title, i) => {
          const documents = safetyDocumentsByTitle[title];
          const isNoDocumentsRequested = !documents || documents.length === 0;

          return (
            <div key={`doc-category-${i}`} className='document-category'>
              <h3>{`${title} documents`}</h3>
              <p style={{ marginBottom: isNoDocumentsRequested ? '28px' : '10px' }}>
                {isNoDocumentsRequested ? 'No documents requested.' : 'Complete and upload the requested documents.'}
              </p>
              <div className='documents-list'>
                {documents?.map((document, j) => (
                  <UploadDownloadBox
                    key={`doc-${j}`}
                    category='localFormalities'
                    document={document}
                    documentUid={document.documentUid}>
                    {document.documentType.name}
                  </UploadDownloadBox>
                ))}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderEmptyDocuments = () => {
    return <PageHeader title={'Local formalities'} subtitle={'No need to upload any document in this section.'} />;
  };

  return (
    <Page>
      <div className='safety-documents'>
        <ProgressBar percentage={'100'} pageNumber={1} totalPages={'1'} />
        {renderBody()}
        <SkipAndNextButtons
          onClick={() => navigate(`/${params.uid}/ship-certificates`)}
          next={`/${params.uid}/ship-certificates`}
          back={`/${params.uid}/health/third-step`}
        />
      </div>
    </Page>
  );
}

export default SafetyDocuments;
