import _ from "lodash";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PortCall from '../../../config/portCall';
import SendFormLayout from '../../captains-form/components/instructions/components/SendFormLayout';
import { toggleGenerateFormModal } from '../../port-clearance-form-request/slices/portCallRequestSlice';
import "../styles/header.scss";
import LogoutIcon from "../styles/images/logout-icon.svg";
import Logo from "../styles/images/nmap-logo.svg";
import Button from './Button';

import Auth from '../../../config/auth'

const Header = function (props) {
  const params = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { formRef, mandatoryDocumentsFormRef } = props;

  const onLogoutClick = () => {
    //dispatch(signOut());
    Auth.clearSession();
    PortCall.storePortCallUuid(params.uid);

    navigate('/logout');
  }

  const handleGenerateForm = async () => {
    const form = formRef.current;
    const errors = await form.validateForm();
    Object.keys(form.values).forEach(key => {
      form.setFieldTouched(key, true);
    });

    const mandatoryDocumentsForm = mandatoryDocumentsFormRef.current;
    const mandatoryDocumentsFormErrors = await mandatoryDocumentsForm.validateForm();
    Object.keys(mandatoryDocumentsForm.values).forEach(key => {
      mandatoryDocumentsForm.setFieldTouched(key, true);
    });

    if (_.isEmpty(errors) && _.isEmpty(mandatoryDocumentsFormErrors)) {
      form.submitForm()
      dispatch(toggleGenerateFormModal());
    }
  }

  const renderButton = () => {
    if (location.pathname.includes("/welcome")) return null;
    if (location.pathname.includes("/summary")) {
      return <SendFormLayout />
    } else if (location.pathname.includes("/port-clearance-form-request")) {
      return <Button className="generate-form-btn" onClick={handleGenerateForm}>Generate Form</Button>
    }
    return null;
  }

  return (
    <header className="header">
      <div className="ncap-logo">
        <img src={Logo} alt="ncap-logo" />
      </div>
      <div className="header__buttons">
        {renderButton()}
        <ul>
          <li onClick={() => onLogoutClick()}><ReactSVG src={LogoutIcon} /></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;