import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../layout/components/Button';
import { selectMissingFields } from '../../../slices/portCallSlice';
import SendConfirmationModal from './SendConfirmationModal';
import SendFormModal from './SendFormModal';

import '../styles/instructions.scss';

export const SendFormLayout = function () {
  const hasMissingFields = useSelector(selectMissingFields);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <>
      <SendFormModal
        isInstructionsModalOpen={isInstructionsModalOpen}
        setIsInstructionsModalOpen={setIsInstructionsModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        hasMissingFields={hasMissingFields}
      />
      <SendConfirmationModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      />
      <Button
        className='generate-form-btn'
        onClick={() => {
          setIsInstructionsModalOpen(true);
        }}>
        Send to agent
      </Button>
    </>
  );
};

export default SendFormLayout;
