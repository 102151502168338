import React from 'react';
import "../styles/progress-bar.scss";


function ProgressBar(props) {
  let width = "0";

  width = props.percentage + "%";

  return (
    <div className="progress-bar">
      <div className="progress-bar__progress-indicator">
        <div className="progress-bar__inner" style={{ width }}></div>
      </div>
      <div className="progress-bar__pages">
        {props.pageNumber}/{props.totalPages}
      </div>
    </div>
  );
}

export default ProgressBar;
