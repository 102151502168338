import { booleanOptionsForRadioGroup } from 'features/forms/helpers/random-form-helpers';
import { FormRefContext } from 'features/global/context/FormRefContext';
import { Form, Formik, FormikProps } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import PageHeader from '../../../../layout/components/PageHeader';
import Table, { DeleteModalData } from '../../../../tables/components/Table';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { selectHealthDeclaration, updateHealthDeclaration } from '../slices/healthSlice';
import '../styles/health.scss';
import { sanitaryMeasuresColumns } from '../utils/healthUtils';
import SanitaryMeasureModal from './SanitaryMeasureModal';

const ThirdHealthStep = () => {
  const formRef = useRef<FormikProps<any>>(null);
  const { formContextRef } = useContext(FormRefContext);
  const params = useParams();
  const healthDeclaration = useSelector(selectHealthDeclaration);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditingTable, setIsEditingTable] = useState<boolean>(false);
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState<boolean>(false);
  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState<DeleteModalData>({
    index: null,
    showModal: false,
  });

  const validationSchema = Yup.object({
    sanitaryMeasuresApplied: Yup.boolean(),
    sickAnimalOrPet: Yup.boolean(),
  });
  const isEditingRow = editingRow !== null;

  const dispatchValues = (values: any) => {
    dispatch(
      updateHealthDeclaration({
        uid: params.uid as string,
        healthData: {
          ...values,
          stateParticulars: healthDeclaration.stateParticulars,
        },
      }) as any
    );
  };

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, healthDeclaration]);

  const handleSubmit = async (values: any) => {
    dispatchValues(values);
  };

  const handleSubmitOnModalOpen = (values: any) => {
    dispatchValues(values);
  };

  const handleSanitaryMeasureData = (data: any) => {
    let sanityMeasures = [...healthDeclaration.sanityMeasures];

    if (isEditingRow) {
      sanityMeasures[editingRow as number] = data;
    } else {
      sanityMeasures.push(data);
    }

    dispatch(
      updateHealthDeclaration({
        uid: params.uid || '',
        healthData: {
          sanityMeasures: sanityMeasures,
        },
      }) as any
    );
  };

  const handleDeleteSingleMember = () => {
    const sanityMeasures = [...healthDeclaration.sanityMeasures];
    sanityMeasures.splice(confirmDeleteMemberModalData.index as number, 1);
    dispatch(
      updateHealthDeclaration({
        uid: params.uid as string,
        healthData: {
          sanityMeasures: sanityMeasures,
        },
      }) as any
    );

    setConfirmDeleteMemberModalData({
      index: null,
      showModal: false,
    });
  };

  const handleDeleteAllMembers = () => {
    setShowConfirmDeleteAllMembersModal(false);

    dispatch(
      updateHealthDeclaration({
        uid: params.uid as string,
        healthData: {
          sanityMeasures: [],
        },
      }) as any
    );
  };

  return (
    <div className='health-wrapper'>
      <SanitaryMeasureModal
        isEditingRow={isEditingRow}
        editingRow={editingRow}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setEditingRow={setEditingRow}
        setData={handleSanitaryMeasureData}
      />
      <ConfirmDeleteModal
        title={
          <h3>
            Are you sure you want to{' '}
            <span>
              delete all the <br />
              sanity measures
            </span>{' '}
            from the list?
          </h3>
        }
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => handleDeleteAllMembers()}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this sanity measure?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onDeleteClick={() => handleDeleteSingleMember()}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <PageHeader title={'Maritime declaration of health'} subtitle={'Health question - sanitary measures'} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={healthDeclaration}
        validationSchema={validationSchema}>
        {({ values }) => (
          <Form className='health-declaration-form'>
            <div className='health-declaration-form__controls'>
              <div className='health-declaration-form__row'>
                <RadioButtonsCustom
                  name='hasSanitaryMeasuresBeenAppliedOnBoard'
                  label='7. Has a sanitary measure (e.g. quarantine, isolation, disinfection or decontamination) been applied on board?'
                  options={booleanOptionsForRadioGroup()}
                />
                {values.hasSanitaryMeasuresBeenAppliedOnBoard && (
                  <Table
                    title={'Sanitary measures'}
                    addButtonText={'Add measure'}
                    data={healthDeclaration.sanityMeasures}
                    columns={sanitaryMeasuresColumns}
                    editingMode={isEditingTable}
                    setEditingMode={setIsEditingTable}
                    setIsAddModalOpen={setIsModalOpen}
                    setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
                    onEditButtonClick={(rowIndex) => {
                      setEditingRow(rowIndex);
                      setIsModalOpen(true);
                    }}
                    onDeleteButtonClick={(rowIndex) => {
                      setConfirmDeleteMemberModalData({
                        showModal: true,
                        index: rowIndex,
                      });
                    }}
                    onAddButtonClick={() => {
                      handleSubmitOnModalOpen(values);
                    }}
                    onEdittingModeButtonClick={() => {
                      handleSubmitOnModalOpen(values);
                    }}
                  />
                )}
              </div>
              <div className='health-declaration-form__row'>
                <RadioButtonsCustom
                  name='isThereASickAnimalOnBoard'
                  label='8. Is there a sick animal or pet on board?'
                  options={booleanOptionsForRadioGroup()}
                />
              </div>
            </div>
            <SkipAndNextButtons
              onClick={() => navigate(`/${params.uid}/local-formalities`)}
              next={`/${params.uid}/local-formalities`}
              back={`/${params.uid}/health/second-step`}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ThirdHealthStep;
