import React from 'react';
import "../styles/auth-layout.scss";

export default function AuthLayout(props) {
  return (
    <div className="auth-layout">
      <main>
        {props.children}
      </main>
    </div>
  );
}
