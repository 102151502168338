
import { isRejectedWithValue, isRejected } from '@reduxjs/toolkit'
import { setGlobalError } from '../slices/globalErrorSlice';

const globalErrorMiddleware = (store) => (next) => (action) => {
  try {

    if (isRejectedWithValue(action)) {
      console.log('We got a rejected action!', )
      throw new Error(JSON.stringify(action.payload));
    }


    return next(action);
  } catch (error) {
    console.log(error)
    store.dispatch(setGlobalError(error.message));
  }
};

export default globalErrorMiddleware;
