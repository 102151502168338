import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useLocation, useParams, Navigate } from 'react-router-dom';
import Page from '../../../layout/components/Page';
import ProgressBar from '../../../layout/components/ProgressBar';
import { useDispatch } from 'react-redux';
import { getPortCallData } from '../../slices/portCallSlice';
import { FormRefContext } from '../../../global/context/FormRefContext';

function PortCallData() {
  const dispatch = useDispatch();
  const { noOfPage } = useContext(FormRefContext);
  const params = useParams();
  const [percentage, setPercentage] = useState('20');
  const [pageNumber, setPageNumber] = useState('');

  useEffect(() => {
    dispatch(getPortCallData(params.uid));
  }, []);

  let location = useLocation();

  useEffect(() => {
    if (location.pathname === `/${params.uid}/port-call-data/eta-etd`) {
      setPercentage(noOfPage === 5 ? '20' : '17');
      setPageNumber('1');
    } else if (location.pathname === `/${params.uid}/port-call-data/ports`) {
      setPercentage(noOfPage === 5 ? '40' : '33');
      setPageNumber('2');
    } else if (location.pathname === `/${params.uid}/port-call-data/purpose`) {
      setPercentage(noOfPage === 5 ? '60' : '50');
      setPageNumber('3');
    } else if (location.pathname === `/${params.uid}/port-call-data/bunkering-detail`) {
      setPercentage('67');
      setPageNumber('4');
    } else if (location.pathname === `/${params.uid}/port-call-data/drafts`) {
      setPercentage(noOfPage === 5 ? '80' : '83');
      setPageNumber(noOfPage === 5 ? '4' : '5');
    } else if (location.pathname === `/${params.uid}/port-call-data/questions`) {
      setPercentage('100');
      setPageNumber(noOfPage === 5 ? '5' : '6');
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/port-call-data`) {
    return <Navigate to={`/${params.uid}/port-call-data/eta-etd`} replace />;
  }

  return (
    <Page>
      <div className='port-call-data'>
        <ProgressBar percentage={percentage} pageNumber={pageNumber} totalPages={noOfPage} />
        <Outlet />
      </div>
    </Page>
  );
}

export default PortCallData;
