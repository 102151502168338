import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import Input from '../../../../forms/components/Input';
import { selectAnnexOne } from '../slices/marpolSlice';
import { emptyHandleSubmit } from './MarpolTables';
import WasteRow from './WasteRow';
import WasteHeader from './WasteHeader';

export default function AnnexOneTable(props) {
  const annexOne = useSelector(selectAnnexOne);
  const [initialValues, setInitialValues] = useState(annexOne || {});

  useEffect(() => {
    setInitialValues(annexOne || {});
  }, [props.disableWasteDelivered, annexOne]);

  return (
    <div className='marpol-table annex-one-table' style={{ display: props.isDisplayed ? 'flex' : 'none' }}>
      <WasteHeader />
      <Formik innerRef={props.formRef} initialValues={initialValues} onSubmit={emptyHandleSubmit} enableReinitialize>
        {({ values }) => (
          <Form className='cargo-information-form'>
            <div className='marpol-table__body'>
              <WasteRow
                wasteName='Oily bilge water'
                imoWasteCode='oilyBilgeWater'
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                {...values?.oilyBilgeWater}
              />
            </div>
            <div className='marpol-table__body'>
              <WasteRow
                wasteName='Oily residues (sludge)'
                imoWasteCode='oilyResidues'
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                {...values?.oilyResidues}
              />
              <WasteRow
                wasteName='Oily tank washings'
                imoWasteCode='oilyTankWashings'
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                {...values?.oilyTankWashings}
              />
              <WasteRow
                wasteName='Dirty ballast water'
                imoWasteCode='dirtyBallastWater'
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                {...values?.dirtyBallastWater}
              />
              <WasteRow
                wasteName='Scale and sludge from tank cleaning'
                imoWasteCode='scaleAndSludge'
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                {...values?.scaleAndSludge}
              />
            </div>
            <WasteRow
              wasteName='Other (please specify)'
              imoWasteCode='other'
              isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
              additionalComponent={<Input name={`other.name`} placeholder='Specify' />}
              {...values?.other}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
