import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import Input from '../../../../forms/components/Input';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectPassengers, updatePassengers } from '../../../slices/portCallSlice';
import "../styles/specify-passengers-number.scss";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const validationSchema = Yup.object({
  arrivalPassengerQuantity: Yup.number().nullable().min(0, "Must be a positive number"),
  departurePassengerQuantity: Yup.number().nullable().min(0, "Must be a positive number")
})

function SpecifyPassengersNumber() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { arrivalPassengerQuantity, departurePassengerQuantity } = useSelector(selectPassengers);
  const params = useParams();

  const [initialValues, setInitialValues] = useState({
    arrivalPassengerQuantity ,
    departurePassengerQuantity,
  });

  useEffect(() => {
    if (arrivalPassengerQuantity !== undefined) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        arrivalPassengerQuantity: arrivalPassengerQuantity,
      }));
    }
  }, [arrivalPassengerQuantity]);

  useEffect(() => {
    if (departurePassengerQuantity !== undefined) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        departurePassengerQuantity: departurePassengerQuantity,
      }));
    }
  }, [departurePassengerQuantity]);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef.current, arrivalPassengerQuantity, departurePassengerQuantity]);

  const handleSubmit = async (values, onSubmitProps) => {
    dispatch(updatePassengers({
      uid: params.uid, data: {
        ...values
      }
    }));
  };
  return (
    <div className="specify-passengers-number">
      <PageHeader 
        title={"Passengers"} 
        subtitle={`How many passengers are on board?`} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form className="specify-passengers-number-form">
          <div className="specify-passengers-number-form__row">
            <Input name="arrivalPassengerQuantity" type="number" placeholder="0" label="At arrival" />
            <Input name="departurePassengerQuantity" type="number" placeholder="0" label="At departure" />
          </div>
          <SkipAndNextButtons
            next={`/${params.uid}/passengers/arrival-passengers`}
            back={`/${params.uid}/crew/effects`} 
            onClick={()=>navigate(`/${params.uid}/passengers/arrival-passengers`)}/>
        </Form>
      </Formik>
    </div>
  );
}

export default SpecifyPassengersNumber;
