import * as Yup from 'yup';
import { format } from 'date-fns';
import { formatDateInTable } from '../../../global/utils/datetimes';
import { searchPort } from "../../../port-clearance-form-request/slices/portsSlice";
import { DEFAULT_FORMAT_DATE } from '../../../global/utils/datetimes';

export const OTHER_RANK = "Others";

export const crewListColums = [
  {
    Header: "Family Name",
    accessor: "surname",
  },
  {
    Header: "Given Names",
    accessor: "name"
  },
  {
    Header: "Rank or rating",
    accessor: "rank"
  },
  {
    Header: "Nationality",
    accessor: "nationality",
    wrap: true,
  },
  {
    Cell: formatDateInTable,
    Header: "Date of Birth",
    accessor: "birthDate"
  },
  {
    Header: "Place of Birth",
    accessor: "birthPlace",
    width: "200"
  },
  {
    Header: "Gender",
    accessor: "gender"
  },
  {
    Header: "Nature Of Identity Number",
    accessor: "IDNature",
    width: "250"
  },
  {
    Header: "ID Number",
    accessor: "IDNumber"
  },
  {
    Header: "Issuing State",
    accessor: "IDIssuingState"
  },
  {
    Cell: formatDateInTable,
    Header: "Expiry Date",
    accessor: "IDExpiryDate"
  },
  {
    Header: "Port of Embarkation",
    accessor: "portOfEmbarkation"
  },
  {
    Header: "Sign-on Date",
    accessor: "embarkationDatetime"
  },
  {
    Header: "Port of Disembarkation",
    accessor: "portOfDisembarkation"
  },
  {
    Header: "Disembarkation Date",
    accessor: "disembarkationDatetime"
  }
];

export const crewMemberInitialValues = {
  surname: "",
  name: "",
  rank: "",
  nationality: "",
  gender: "",
  birthDate: "",
  birthPlace: "",
  IDNature: "",
  IDNumber: "",
  IDExpiryDate: "",
  IDIssuingState: "",
  embarkationDatetime: "",
  portOfEmbarkation: "",
  disembarkationDatetime: "",
  portOfDisembarkation: ""
};

export const crewMemberValidationSchema = Yup.object({
  surname: Yup.string().required("*This field is required"),
  name: Yup.string().required("*This field is required"),
  rank: Yup.string().required("*Please select one"),
  nationality: Yup.string().required("*This field is required"),
  gender: Yup.string().required("*Please select one"),
  birthDate: Yup.string().required("*This field is required"),
  birthPlace: Yup.string().required("*This field is required"),
  IDNature: Yup.string().required("*Please select one"),
  IDNumber: Yup.string().required("*This field is required"),
  IDExpiryDate: Yup.string().required("*This field is required"),
  IDIssuingState: Yup.string().required("*Please select one"),
  portOfEmbarkation: Yup.object().nullable(),
  embarkationDatetime: Yup.string().nullable(),
  portOfDisembarkation: Yup.object().nullable(),
  disembarkationDatetime: Yup.string().nullable(),
});

export const getGlobalCrewMemberIndex = (crewList, rowIndex) => {
  return crewList[rowIndex].index
}

export const fetchCrewMemberData = async (dispatch, crewMember) => {
  let portOfEmbarkation = null;
  let portOfDisembarkation = null;
  if (crewMember?.portOfEmbarkation){
    const portResponse = await dispatch(searchPort({unlocode: crewMember.portOfEmbarkation}));
    portOfEmbarkation = portResponse.payload[0] || null;
  }
  if (crewMember?.portOfDisembarkation){
    const portResponse = await dispatch(searchPort({unlocode: crewMember.portOfDisembarkation}));
    portOfDisembarkation = portResponse.payload[0] || null;
  }
  return {
    surname: crewMember.surname ? crewMember.surname : "",
    name: crewMember.name ? crewMember.name : "",
    rank: crewMember.rank ? crewMember.rank : "",
    otherRank: crewMember.otherRank ?? "",
    nationality: crewMember.nationality ? crewMember.nationality : "",
    gender: crewMember.gender ? crewMember.gender : "",
    birthDate: crewMember.birthDate ? new Date(crewMember.birthDate) : null,
    birthPlace: crewMember.birthPlace ? crewMember.birthPlace : "",
    IDNature: crewMember.IDNature ? crewMember.IDNature : "",
    IDNumber: crewMember.IDNumber ? crewMember.IDNumber : "",
    IDExpiryDate: crewMember.IDExpiryDate ? new Date(crewMember.IDExpiryDate) : null,
    IDIssuingState: crewMember.IDIssuingState ? crewMember.IDIssuingState : "",
    embarkationDatetime: crewMember.embarkationDatetime ? new Date(crewMember.embarkationDatetime) : null,
    portOfEmbarkation: portOfEmbarkation,
    disembarkationDatetime: crewMember.disembarkationDatetime ? new Date(crewMember.disembarkationDatetime) : null,
    portOfDisembarkation: portOfDisembarkation,
  };
};

export const convertDataFromApi = (crewList) =>{
  return crewList.map(crewMember => {
    return {
      ...crewMember,
      embarkationDatetime: crewMember?.embarkationDatetime ? format(new Date(crewMember.embarkationDatetime), DEFAULT_FORMAT_DATE): null,
      disembarkationDatetime: crewMember?.disembarkationDatetime ? format(new Date(crewMember.disembarkationDatetime), DEFAULT_FORMAT_DATE): null,
      rank: crewMember?.rank === OTHER_RANK? crewMember?.otherRank : crewMember?.rank
    }
  })

}