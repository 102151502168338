import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import PencilIcon from '../../layout/styles/images/pencil.svg';

import '../styles/modal.scss';


ReactModal.setAppElement('#root');

const ConfirmationModal = (props) => {
  const { title = 'Have you finished editing?',
  subtitle = 'Some required fields have not been completed.',
  disabledSubtitle = 'You have to complete at least one field.',
  showModal,
  handleCloseModal,
  willDisableSave,
  onAcceptModal,
  className,
  onAfterOpen,
  closeTimeoutMS
} = props

  const [isSaveInactive, setIsSaveInactive] = React.useState(false);
  function checkIfSaveInactive() {
    if (willDisableSave instanceof Boolean) return willDisableSave;
    if (willDisableSave instanceof Object) {
      let flag = true;
      Object.keys(willDisableSave).forEach(key => {
        if (Boolean(willDisableSave[key])) flag = false;
      })
      return flag;
    };
  }

  function onCancelClick() {
      handleCloseModal(false)
  }

  useEffect(() => {
    setIsSaveInactive(checkIfSaveInactive());
  }, [willDisableSave])

  return (
    <div className="modal">
      <ReactModal
        className={"modal-wrapper" + (className ? " " + className : "")}
        overlayClassName={"modal-overlay"}
        isOpen={showModal}
        onRequestClose={onAcceptModal}
        ariaHideApp={false}
        onAfterOpen={() => {
          if (onAfterOpen) onAfterOpen();
        }}
        closeTimeoutMS={closeTimeoutMS || 200}>

        <div className={`modal modal__children`}>
            <div className='modal__header-container modal__container-center'>
                <ReactSVG src={PencilIcon} />
                <div className='modal__header-container__column'>
                    <span className="modal__header-container__text__title">{title}</span>
                    <span className='modal__header-container__text__subtitle'>{isSaveInactive ? disabledSubtitle : subtitle}</span>
                </div>
            </div>
            <div className='modal__header-container__buttons'>
                <button className='button light-table' onClick={onCancelClick}>Cancel</button>
                <button className={isSaveInactive ? 'button hidden' : 'button'} onClick={ onAcceptModal}>Save and confirm</button>
            </div>
        </div>
      </ReactModal>
    </div>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  disabledSubtitle: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  willDisableSave: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]).isRequired,
  onAcceptModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  onAfterOpen: PropTypes.func,
  closeTimeoutMS: PropTypes.number
};

export default ConfirmationModal;
