import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import DepartureCrewTable from './DepartureCrewTable';
import FileInfo from '../styles/images/FileInfo.svg'

function DepartureCrewChanges() {
  let navigate = useNavigate();
  const params = useParams();

  return (
    <div className="crew-changes">
      <div className="notification">
        <div className="notification__img"><img style={{ height: "24px", width: "24px" }} src={FileInfo}></img></div>
        <div>
          <p className="notification__title">Steps to import the crew list</p>
          <ol >
            <li>Click ‘Open list editor’ to open a sheet editor in a new tab, where you can copy and paste the crew list. </li>
            <li>After editing, return to this screen and click ‘Import from editor’ to update the table & save the data.</li>
            <li>You can also upload a PDF or Excel version of the crew list using the ‘Upload crew list in PDF’ button.</li>
          </ol>
          <p className="notification__imp"> <span style={{ fontWeight: "bold" }}>Important: </span> If your internet drops, keep both tabs open. Once reconnected, click 'Import from editor' to load the data.</p>
        </div>
      </div>
      <PageHeader title={"Crew list on departure"} subtitle={"Complete the table or upload the crew list."} />
      <DepartureCrewTable />
      <SkipAndNextButtons
        onClick={() => navigate(`/${params.uid}/crew/effects`)}
        next={`/${params.uid}/crew/effects`}
        back={`/${params.uid}/crew/arrival-crew`} />
    </div>
  );
}

export default DepartureCrewChanges;
