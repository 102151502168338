import React, { useEffect, useMemo } from "react";
import { useTable, useFlexLayout } from "react-table";
import Button from '../../layout/components/Button';
import Increase from "../styles/images/Increase.svg";
import CloudDownload_Enable from "../styles/images/CloudDownload_Enable.svg";
import "../styles/table.scss";

interface CrewListTableProps {
  title: string;
  data: any[];
  columns: any[];
  useRowNumbers?: boolean;
  disabled?: boolean;
  editorButtonText: string;
  importButtonText: string;
  onEditorButtonClick?: () => void | null;
  onImportButtonClick?: () => void | null;
  setIsAddModalOpen: (newValue: boolean) => void;
  setIsDeleteModalOpen: (newValue: boolean) => void;
  onEditButtonClick: (index: number) => void;
  onDeleteButtonClick: (index: number) => void;
  setDisableImport: (newValue: boolean) => void;
}

export interface DeleteModalData {
  index: number | null;
  showModal: boolean;
}

export const CrewListTable: React.FC<CrewListTableProps> = ({
  title,
  data,
  columns,
  useRowNumbers = false,
  editorButtonText,
  importButtonText,
  onEditorButtonClick = null,
  onImportButtonClick = null,
  disabled = false,
  setDisableImport
}) => {
  const columnsMemoized = useMemo(() => {
    return columns;
  }, [columns]);
  const dataMemoized = useMemo(() => {
    return data;
  }, [data]);

  useEffect(() => {
    if (disabled) {
      //setEditingMode(false)
    }
  }, [disabled])


  const tableInstance = useTable({
    columns: columnsMemoized,
    data: dataMemoized
  }, useFlexLayout);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <div className="table-preheader">
        {title ? (
          <div className="table-preheader__title">
            {title}
          </div>
        ) : (<div></div>)}

        <div className="table-preheader__control-btns">
          {(
            <>
              <Button iconLeft={Increase} onClick={() => {
                if (onEditorButtonClick) onEditorButtonClick()
              }}>{editorButtonText}</Button>
              <Button disabled={disabled} iconLeft={CloudDownload_Enable} onClick={() => {
                if (onImportButtonClick) onImportButtonClick()
              }} >{importButtonText}</Button>
            </>
          )}
        </div>
      </div>
      <div className="table-body">
        <table className="table" {...getTableProps()}>
          <thead>
            {
              headerGroups.map((headerGroup: any) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      <>
                        {useRowNumbers && <th className="head-number-cell"><div>No.</div></th>}

                        {headerGroup.headers.map((column: any) => (
                          <th {...column.getHeaderProps()}>
                            <div>
                              {column.render("Header")}
                            </div>
                          </th>
                        ))
                        }
                      </>
                    }
                  </tr>
                )
              })
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {
                      <>
                        {useRowNumbers && <td className={"body-number-cell"}>{i + 1}</td>}
                        {
                          row.cells.map((cell: any) => {
                            return (
                              <td className={"body-cell " + cell.column.id} {...cell.getCellProps()}>
                                <span>{cell.render("Cell")}</span>
                              </td>
                            )
                          })
                        }
                      </>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
};

export default CrewListTable;
