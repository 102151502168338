import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Form, Formik } from 'formik';
import { default as React, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import usePortsRepository from '../../../../../hooks/usePortsRepository';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectGeneralData, selectPortCallData, updatePortCallData } from '../../../slices/portCallSlice';
import '../../../styles/eta-and-etd.scss';
import { isEmpty, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from './SkipAndNextButtons';

const validationSchema = Yup.object({
  eta: Yup.mixed().nullable(),
  etd: Yup.mixed().nullable(),
});

function EtaAndEtd() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const portCallData = useSelector(selectPortCallData);
  const generalPortcallData = useSelector(selectGeneralData);
  const { relations, unlocode } = generalPortcallData;
  const { port } = usePortsRepository({ ports: relations?.ports, unlocode });
  const [masterEta, setMasterEta] = useState(
    portCallData?.data?.eta?.estimatedTime ? new Date(portCallData.data.eta.estimatedTime) : null
  );
  const [masterEtd, setMasterEtd] = useState(
    portCallData?.data?.etd?.estimatedTime ? new Date(portCallData.data.etd.estimatedTime) : null
  );

  const initialValues = {
    eta: portCallData?.data?.eta?.estimatedTime ? new Date(portCallData.data.eta.estimatedTime) : null,
    etd: portCallData?.data?.etd?.estimatedTime ? new Date(portCallData.data.etd.estimatedTime) : null,
  };

  const handleSubmit = async (values, onSubmitProps) => {
    let finalValues = {};

    finalValues.eta = {
      estimatedTime: values.eta,
    };

    finalValues.etd = {
      estimatedTime: values.etd,
    };

    dispatch(
      updatePortCallData({
        uid: params.uid,
        data: finalValues,
      })
    );
  };

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, portCallData, generalPortcallData]);

  return (
    <div className='eta-and-etd'>
      <PageHeader title={'Port call data'} subtitle={`Provide the ETA and ETD for the Port of ${port && port.name}:`} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values, errors, setValues }) => (
          <Form className='eta-etd-form'>
            <div className='eta-etd-form__controls'>
              <Field label='Estimated Time of Arrival (ETA) *' className='field_date_picker'>
                <DatePickerTimeInput
                  key={initialValues?.eta + 'eta'}
                  name='eta'
                  sizeInput='large'
                  showTime={true}
                  defaultValue={initialValues?.eta}
                  action={({ isoDate }) => {
                    if (isoDate) {
                      const etaDate = new Date(isoDate);
                      setValues({ ...values, eta: etaDate });
                      setMasterEta(etaDate);
                    } else {
                      setValues({ ...values, eta: null });
                      setMasterEta(null);
                    }
                  }}
                  showBlueError={true}
                  hasError={isEmpty(values?.eta)}
                  errorMessage={MandatoryFieldMessages.RequiredField}
                />
              </Field>
              <Field label='Estimated Time of Departure (ETD) *' className='field_date_picker'>
                <DatePickerTimeInput
                  key={initialValues?.eta + initialValues?.etd + 'etd'}
                  name='etd'
                  sizeInput='large'
                  minDate={masterEta ? masterEta : initialValues?.eta}
                  showTime={true}
                  defaultValue={masterEtd ? masterEtd : initialValues?.etd}
                  action={({ isoDate }) => {
                    if (isoDate) {
                      setMasterEtd(new Date(isoDate));
                      setValues({ ...values, etd: new Date(isoDate) });
                    } else {
                      setMasterEtd(null);
                      setValues({ ...values, etd: null });
                    }
                  }}
                  showBlueError={true}
                  hasError={isEmpty(values?.etd)}
                  errorMessage={MandatoryFieldMessages.RequiredField}
                />
              </Field>
            </div>
            <SkipAndNextButtons
              next={`/${params.uid}/port-call-data/ports`}
              noBack
              onClick={() => navigate(`/${params.uid}/port-call-data/ports`)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EtaAndEtd;
