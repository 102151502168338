import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { DocumentCategory, DocumentSubCategory } from "../../captains-form/components/documents/constants";
import {
    getRequiredDocuments, openDocument, removeDocument,
    selectDocuments,
    selectIsLoading,
    uploadDocument
} from "../../captains-form/components/documents/slices/documentsSlice";
import Button from '../../layout/components/Button';
import Modal from '../../layout/components/Modal';
import "../styles/drag-and-drop.scss";
import cloudUpload from '../styles/images/CloudUpload.svg';
import cross from '../styles/images/Cross-X.svg';
import document from '../styles/images/document.svg';
import eye from '../styles/images/Eye.svg';
import uploadBtn from '../styles/images/upload-btn.svg';

interface DragAndDropProps {
    title: string;
    openModalButtonText?: string;
    uploadButtonText?: string;
    acceptedFormats?: string[];
    documentTypeId: string;
    category: string,
    documentTypeName: string,
    documentCategory: DocumentCategory
    documentSubCategory: DocumentSubCategory
    uploadButtonAtLeft?: boolean
}
export interface PortCallDocumentType {
    id: string,
    name: string,
    category: string,
    subcategory: string,
}
export interface PortCallDocument {
    documentUid: string,
    documentType: PortCallDocumentType,
    lastUpdated: string
}

function DragAndDrop({
    title,
    openModalButtonText = 'Upload CSV',
    uploadButtonText = 'Upload',
    acceptedFormats = ['*'],
    documentTypeId,
    category,
    documentTypeName,
    documentCategory,
    documentSubCategory,
    uploadButtonAtLeft = false,
}: DragAndDropProps) {
    const [temporaryFiles, setTemporaryFiles] = useState<File[] | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null)

    const isLoading = useSelector(state => selectIsLoading(state, documentTypeId));

    const params = useParams();
    const dispatch = useDispatch();
    const portCallDocuments = useSelector(selectDocuments)?.documents;
    const [currentDocuments, setCurrentDocuments] = useState<PortCallDocument[] | null>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setTemporaryFiles(files ? Array.from(files) : null);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        setTemporaryFiles(files ? Array.from(files) : null);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleUpload = () => {
        if (temporaryFiles) {
            const file = temporaryFiles[0];
            try {
                // @ts-ignore
                dispatch(uploadDocument({
                    documentUid: uuidv4(),
                    portcallId: params.uid,
                    category: category,
                    documentCategory: documentCategory,
                    documentSubCategory: documentSubCategory,
                    documentTypeId: documentTypeId,
                    documentTypeName: documentTypeName,
                    file: file
                }));
                handleCloseModal(true)
                return;
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleRemoveFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const fileIndex = Number(event.currentTarget.id);
        const newFiles = temporaryFiles && temporaryFiles.length > 1 ? temporaryFiles.filter((file, index) => index !== fileIndex) : null;
        setTemporaryFiles(newFiles);
    }

    function handleDeleteFile(documentUid: string) {
        // @ts-ignore
        dispatch(removeDocument({
            portcallId: params.uid,
            category: category,
            documentTypeId: documentTypeId,
            documentUid: documentUid
        }))
    }

    const handleCloseModal = (cleanFilelist = false) => {
        setIsModalOpen(false);
        cleanFilelist && setTemporaryFiles(null);
    }

    function handleDownload(uuid: string) {
        // @ts-ignore
        dispatch(openDocument({
            uiid: uuid,
            portcallId: params.uid
        }));
    }

    const getCurrentDocuments = (): PortCallDocument[] | null => {
        if (Object.keys(portCallDocuments).length) {
            const categoryDocuments = (portCallDocuments[category] || []) as PortCallDocument[];
            const currentDocuments = categoryDocuments?.filter((doc) => {
                return doc?.documentType.id === documentTypeId
            });

            return currentDocuments;
        }
        return null;
    }

    const formattedDate = (date: string) => {
        if (!date) return '';
        const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return (new Date(date)).toLocaleDateString('en-GB', options);
    };

    useEffect(() => {
        setCurrentDocuments(getCurrentDocuments())
    }, [portCallDocuments]);

    useEffect(() => {
        // @ts-ignore
        dispatch(getRequiredDocuments({ uid: params.uid }));
    }, []);

    const renderDocumentList = () => (
        <ul className="drag-and-drop__uploadedList">
            {currentDocuments && currentDocuments.map((file, index) => (
                <li className="drag-and-drop_file_card" key={index}>
                    <div className="drag-and-drop_file_card_content">
                        <div className="drag-and-drop_file_card_content_title">
                            <img className="drag-and-drop_file_card_content_title_icon" src={document} alt="document-icon" />
                            <div className="drag-and-drop_file_card_content_title_text">
                                <span className="drag-and-drop_file_card_content_title_text__title">{file.documentType.name}</span>
                                <span className="drag-and-drop_file_card_content_title_text__subtitle">Last Updated: {formattedDate(file.lastUpdated)}</span>
                            </div>
                        </div>
                        <div className="drag-and-drop_file_card_content_btns">
                            <Button className="drag-and-drop_file_card_content_btns__open light" iconMiddle={eye} type="button" onClick={() => handleDownload(file.documentUid)}>Open</Button>
                            <Button className="drag-and-drop_file_card_content_btns__cross" iconMiddle={cross} onClick={() => handleDeleteFile(file.documentUid)}></Button>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );

    return (
        <>
            {uploadButtonAtLeft ?
                <>
                    <Button iconLeft={uploadBtn} className="csv-excel-importer-button-left light" onClick={() => {
                        setIsModalOpen(!isModalOpen)
                    }}>{openModalButtonText}
                    </Button>
                    <div className="drag-and-drop">
                        <div className={'info'}>
                            <div className="drag-and-drop__title">
                                <span>{title}</span>
                            </div>
                            {renderDocumentList()}
                        </div>
                    </div>
                </>
                :
                <div className="drag-and-drop">
                    <div className={'info'}>
                        <div className="drag-and-drop__title">
                            <span>{title}</span>
                        </div>
                        {renderDocumentList()}
                    </div>
                    <Button iconLeft={uploadBtn} className="csv-excel-importer-button light" onClick={() => {
                        setIsModalOpen(!isModalOpen)
                    }}>{openModalButtonText}
                    </Button>
                </div>
            }
            {isModalOpen && (
                <Modal
                    title="Upload a document"
                    showModal={isModalOpen}
                    onCloseModal={handleCloseModal}
                    //@ts-ignore
                    hideCloseBtn={true}
                    icon={cloudUpload}
                    subtitle={"Upload the crew list."}
                >
                    {isLoading ?
                        <div className={'drag-and-drop_modal_loading'}>
                            <FontAwesomeIcon spin icon={faSpinner} className="loader" size="3x"
                                style={{ color: "#5b8fe9" }} />
                        </div>
                        :
                        <>
                            {temporaryFiles && temporaryFiles.length > 0 ?
                                (<div className='drag-and-drop_modal_filelist'>
                                    <span className='drag-and-drop_modal_filelist__label'>Attached files</span>
                                    <ul className='drag-and-drop_modal_filelist__list'>
                                        {Array.from(temporaryFiles).map((file, index) => (
                                            <li key={index}>
                                                <span>{index + 1}. {file.name}</span>
                                                <Button iconMiddle={cross} onClick={handleRemoveFile}></Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>) :
                                (<div
                                    className='drag-and-drop_modal_dropzone'
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}>
                                    <input
                                        type="file"
                                        accept={acceptedFormats.map((format) => format.trim()).join(',')}
                                        onChange={handleFileChange}
                                        multiple={false}
                                        hidden
                                        ref={inputRef}
                                    />
                                    <span>Drag the document here</span>
                                    <span>or</span>
                                    <Button className="light" onClick={() => inputRef?.current?.click()}>Select File</Button>
                                </div>)}
                        </>
                    }

                    <div className='drag-and-drop_modal_btns'>
                        <Button id="backButton" className="transparent" onClick={handleCloseModal}>Back</Button>
                        {temporaryFiles && temporaryFiles.length > 0 && <Button id="uploadButton" onClick={handleUpload}>{uploadButtonText}</Button>}
                    </div>
                </Modal>

            )}
        </>
    );
}

export default DragAndDrop;
