import React from "react";
import PropTypes from 'prop-types';

export default function WasteHeader({ 
  wasteName = "Waste",
}) {
  return (
    <div className="marpol-table__header">
        <div className="cell">{wasteName}</div>
        <div className="cell">Onboard quantity (m3)</div>
        <div className="cell">Waste to be delivered (m3)</div>
        <div className="cell">Max dedicated storage capacity (m3)</div>
        <div className="cell">Amount of waste retained on board (m3)</div>
        <div className="cell">Estimated amount of waste to be generated (m3)</div>
        <div className="cell">Next port of discharge</div>
    </div>
  )
}

WasteHeader.propTypes = {
  wasteName: PropTypes.string,
};
