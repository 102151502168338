import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import Input from '../../../../forms/components/Input';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import { removeNullUndefined } from '../../../../global/utils/cleanEmptyData';
import Page from '../../../../layout/components/Page';
import PageHeader from '../../../../layout/components/PageHeader';
import ProgressBar from '../../../../layout/components/ProgressBar';
import {
  getDangerousGoods,
  getDangerousGoodsData,
  selectDangerousGoods,
  updateDangerousGoodsData,
} from '../../../slices/portCallSlice';
import { isEmpty, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import '../styles/dangerous-goods.scss';
import DangerousGoodsTable from './DangerousGoodsTable';

const validationSchema = Yup.object({ dangerousGoodsType: Yup.array() });

const IS_NOT_CARRYING_DANGEROUS_GOODS = 'isNotCarryingDangerousGoods';
const IS_UNLOADING_DANGEROUS_GOODS = 'isUnloadingDangerousGoods';
const IS_LOADING_DANGEROUS_GOODS = 'isLoadingDangerousGoods';
const IS_DANGEROUS_GOODS_IN_TRANSIT = 'isDangerousGoodsInTransit';

const allDangerousGoodsStatusValues = [
  IS_NOT_CARRYING_DANGEROUS_GOODS,
  IS_UNLOADING_DANGEROUS_GOODS,
  IS_LOADING_DANGEROUS_GOODS,
  IS_DANGEROUS_GOODS_IN_TRANSIT,
];

function DangerousGoods() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const dangerousGoods = useSelector(selectDangerousGoods);

  const [initialValues, setInitialValues] = useState({ dangerousGoodsType: [IS_NOT_CARRYING_DANGEROUS_GOODS] });

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, dangerousGoods]);

  useEffect(() => {
    let newInitialValues = { ...initialValues };
    const { dangerousGoodsData } = dangerousGoods;
    if (dangerousGoodsData) {
      newInitialValues = {
        ...newInitialValues,
        ...JSON.parse(JSON.stringify(dangerousGoodsData)),
      };
    }

    if (dangerousGoodsData && dangerousGoodsData?.dangerousGoodsStatus) {
      newInitialValues = { ...newInitialValues, dangerousGoodsType: [] };
      if (dangerousGoodsData?.dangerousGoodsStatus[IS_DANGEROUS_GOODS_IN_TRANSIT]) {
        newInitialValues.dangerousGoodsType.push(IS_DANGEROUS_GOODS_IN_TRANSIT);
      }
      if (dangerousGoodsData?.dangerousGoodsStatus[IS_UNLOADING_DANGEROUS_GOODS]) {
        newInitialValues.dangerousGoodsType.push(IS_UNLOADING_DANGEROUS_GOODS);
      }
      if (dangerousGoodsData?.dangerousGoodsStatus[IS_LOADING_DANGEROUS_GOODS]) {
        newInitialValues.dangerousGoodsType.push(IS_LOADING_DANGEROUS_GOODS);
      }
      if (dangerousGoodsData?.dangerousGoodsStatus[IS_NOT_CARRYING_DANGEROUS_GOODS]) {
        newInitialValues = {
          ...newInitialValues,
          dangerousGoodsType: [IS_NOT_CARRYING_DANGEROUS_GOODS],
        };
      }
    }

    if (
      dangerousGoodsData &&
      dangerousGoodsData.internationalSafetyManagementCertificate &&
      dangerousGoodsData.internationalSafetyManagementCertificate.expireDate
    ) {
      newInitialValues.internationalSafetyManagementCertificate = {
        ...newInitialValues.internationalSafetyManagementCertificate,
        expireDate: new Date(dangerousGoodsData.internationalSafetyManagementCertificate.expireDate),
      };
    }

    setInitialValues(newInitialValues);
  }, [dangerousGoods.dangerousGoodsData]);

  useEffect(() => {
    dispatch(getDangerousGoodsData(params.uid));
    dispatch(getDangerousGoods(params.uid));
  }, [dispatch]);

  const handleSubmit = async (values, onSubmitProps) => {
    let finalValues = {
      ...values,
    };

    if (values.internationalSafetyManagementCertificate && values.internationalSafetyManagementCertificate.expireDate) {
      finalValues.internationalSafetyManagementCertificate = {
        ...finalValues.internationalSafetyManagementCertificate,
        expireDate: finalValues.internationalSafetyManagementCertificate.expireDate.toISOString(),
      };
    }

    const dangerousGoodsStatus = {};

    values.dangerousGoodsType.forEach((key) => {
      dangerousGoodsStatus[key] = true;
    });

    allDangerousGoodsStatusValues.forEach((key) => {
      if (!dangerousGoodsStatus[key]) {
        dangerousGoodsStatus[key] = false;
      }
    });

    finalValues.dangerousGoodsStatus = dangerousGoodsStatus;
    finalValues.dangerousGoodsType = undefined;

    dispatch(
      updateDangerousGoodsData({
        uid: params.uid,
        dangerousGoodsData: removeNullUndefined(finalValues),
      })
    );
  };

  return (
    <Page>
      <div className='dangerous-goods'>
        <ProgressBar percentage={'100'} pageNumber={3} totalPages={'3'} />
        <PageHeader title={'Cargo - Dangerous Goods'} />
        <Formik
          innerRef={formRef}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ values, setValues, errors }) => {
            return (
              <>
                <Form className='dangerous-goods-form'>
                  <div className='dangerous-goods__questions'>
                    <RadioButtonsCustom
                      name='isTransportingExplosives'
                      label='Does the ship transport explosives?'
                      options={booleanOptionsForRadioGroup()}
                    />
                    <>
                      <RadioButtonsCustom
                        name='internationalSafetyManagementCertificate.shipHasInternationalSafetyManagement'
                        label='Does the ship has a valid International Safety Management certificate? *'
                        options={booleanOptionsForRadioGroup()}
                        hasError={isEmpty(
                          values?.internationalSafetyManagementCertificate?.shipHasInternationalSafetyManagement
                        )}
                        errorMessage={MandatoryFieldMessages.RequiredField}
                      />
                      <div className='ism-extra-fields-section'>
                        {values?.internationalSafetyManagementCertificate &&
                          values?.internationalSafetyManagementCertificate?.shipHasInternationalSafetyManagement && (
                            <>
                              <div className='dangerous-goods-form__certificate-number'>
                                <Input
                                  name='internationalSafetyManagementCertificate.certificateNumber'
                                  placeholder='Certificate  number'
                                  label='Certificate number *'
                                  hasError={isEmpty(
                                    values?.internationalSafetyManagementCertificate?.certificateNumber
                                  )}
                                  errorMessage={MandatoryFieldMessages.RequiredField}
                                />
                              </div>
                              <div className='dangerous-goods-form__row'>
                                <Input
                                  name='internationalSafetyManagementCertificate.issuedBy'
                                  placeholder='Issued by (name of entity)'
                                  label='Issued by (name of entity) *'
                                  hasError={isEmpty(values?.internationalSafetyManagementCertificate?.issuedBy)}
                                  errorMessage={MandatoryFieldMessages.RequiredField}
                                />
                                <Field label='Expiry date *' className='field_date_picker'>
                                  <DatePickerTimeInput
                                    key={
                                      initialValues?.internationalSafetyManagementCertificate?.expireDate + 'expireDate'
                                    }
                                    name='internationalSafetyManagementCertificate.expireDate'
                                    sizeInput='large'
                                    minDate={new Date()}
                                    showTime={false}
                                    defaultValue={initialValues?.internationalSafetyManagementCertificate?.expireDate}
                                    action={({ isoDate }) =>
                                      setValues({
                                        ...values,
                                        internationalSafetyManagementCertificate: {
                                          ...values?.internationalSafetyManagementCertificate,
                                          expireDate: isoDate ? new Date(isoDate) : null,
                                        },
                                      })
                                    }
                                    showBlueError={true}
                                    hasError={isEmpty(values?.internationalSafetyManagementCertificate?.expireDate)}
                                    errorMessage={MandatoryFieldMessages.RequiredField}
                                  />
                                </Field>
                              </div>
                            </>
                          )}
                        {values?.internationalSafetyManagementCertificate &&
                          !values?.internationalSafetyManagementCertificate?.shipHasInternationalSafetyManagement && (
                            <Input
                              name='internationalSafetyManagementCertificate.reasonWhyNot'
                              placeholder='Why not?'
                              label='Why not? *'
                              hasError={isEmpty(values?.internationalSafetyManagementCertificate?.reasonWhyNot)}
                              errorMessage={MandatoryFieldMessages.RequiredField}
                            />
                          )}
                      </div>
                    </>
                  </div>
                  <div className='dangerous-goods-form__contact-information'>
                    <h3>Provide the Dangerous Goods contact information:</h3>
                    <div>
                      <Input name='dangerousGoodsContactPerson.name' placeholder='Full name' label='Full name' />
                      <Input
                        name='dangerousGoodsContactPerson.details'
                        placeholder='Contact details'
                        label='Contact details'
                      />
                    </div>
                  </div>
                </Form>
                {values?.dangerousGoodsType.length > 0 &&
                  values?.dangerousGoodsType[0] !== IS_NOT_CARRYING_DANGEROUS_GOODS && (
                    <>
                      <h3>Please, provide the information about the dangerous goods you are carrying</h3>
                      <DangerousGoodsTable />
                    </>
                  )}
              </>
            );
          }}
        </Formik>
        <SkipAndNextButtons
          onClick={() => navigate(`/${params.uid}/solas-and-isps-code`)}
          next={`/${params.uid}/solas-and-isps-code`}
          back={`/${params.uid}/cargo/list`}
        />
      </div>
    </Page>
  );
}

export default DangerousGoods;
