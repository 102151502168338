export const countryListAllIsoData = [
  { "value": "AF", label: "Afghanistan", "code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004" },
  { "value": "AL", label: "Albania", "code": "AL", "code3": "ALB", "name": "Albania", "number": "008" },
  { "value": "DZ", label: "Algeria", "code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012" },
  { "value": "AS", label: "American Samoa", "code": "AS", "code3": "ASM", "name": "American Samoa", "number": "016" },
  { "value": "AD", label: "Andorra", "code": "AD", "code3": "AND", "name": "Andorra", "number": "020" },
  { "value": "AO", label: "Angola", "code": "AO", "code3": "AGO", "name": "Angola", "number": "024" },
  { "value": "AI", label: "Anguilla", "code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660" },
  { "value": "AQ", label: "Antarctica", "code": "AQ", "code3": "ATA", "name": "Antarctica", "number": "010" },
  { "value": "AG", label: "Antigua and Barbuda", "code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028" },
  { "value": "AR", label: "Argentina", "code": "AR", "code3": "ARG", "name": "Argentina", "number": "032" },
  { "value": "AM", label: "Armenia", "code": "AM", "code3": "ARM", "name": "Armenia", "number": "051" },
  { "value": "AW", label: "Aruba", "code": "AW", "code3": "ABW", "name": "Aruba", "number": "533" },
  { "value": "AU", label: "Australia", "code": "AU", "code3": "AUS", "name": "Australia", "number": "036" },
  { "value": "AT", label: "Austria", "code": "AT", "code3": "AUT", "name": "Austria", "number": "040" },
  { "value": "AZ", label: "Azerbaijan", "code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031" },
  { "value": "BS", label: "Bahamas (the)", "code": "BS", "code3": "BHS", "name": "Bahamas (the)", "number": "044" },
  { "value": "BH", label: "Bahrain", "code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048" },
  { "value": "BD", label: "Bangladesh", "code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050" },
  { "value": "BB", label: "Barbados", "code": "BB", "code3": "BRB", "name": "Barbados", "number": "052" },
  { "value": "BY", label: "Belarus", "code": "BY", "code3": "BLR", "name": "Belarus", "number": "112" },
  { "value": "BE", label: "Belgium", "code": "BE", "code3": "BEL", "name": "Belgium", "number": "056" },
  { "value": "BZ", label: "Belize", "code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084" },
  { "value": "BJ", label: "Benin", "code": "BJ", "code3": "BEN", "name": "Benin", "number": "204" },
  { "value": "BM", label: "Bermuda", "code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060" },
  { "value": "BT", label: "Bhutan", "code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064" },
  { "value": "BO", label: "Bolivia (Plurinational State of)", "code": "BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068" },
  { "value": "BQ", label: "Bonaire, Sint Eustatius and Saba", "code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535" },
  { "value": "BA", label: "Bosnia and Herzegovina", "code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070" },
  { "value": "BW", label: "Botswana", "code": "BW", "code3": "BWA", "name": "Botswana", "number": "072" },
  { "value": "BV", label: "Bouvet Island", "code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074" },
  { "value": "BR", label: "Brazil", "code": "BR", "code3": "BRA", "name": "Brazil", "number": "076" },
  { "value": "IO", label: "British Indian Ocean Territory (the)", "code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086" },
  { "value": "BN", label: "Brunei Darussalam", "code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096" },
  { "value": "BG", label: "Bulgaria", "code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100" },
  { "value": "BF", label: "Burkina Faso", "code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854" },
  { "value": "BI", label: "Burundi", "code": "BI", "code3": "BDI", "name": "Burundi", "number": "108" },
  { "value": "CV", label: "Cabo Verde", "code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132" },
  { "value": "KH", label: "Cambodia", "code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116" },
  { "value": "CM", label: "Cameroon", "code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120" },
  { "value": "CA", label: "Canada", "code": "CA", "code3": "CAN", "name": "Canada", "number": "124" },
  { "value": "KY", label: "Cayman Islands (the)", "code": "KY", "code3": "CYM", "name": "Cayman Islands (the)", "number": "136" },
  { "value": "CF", label: "Central African Republic (the)", "code": "CF", "code3": "CAF", "name": "Central African Republic (the)", "number": "140" },
  { "value": "TD", label: "Chad", "code": "TD", "code3": "TCD", "name": "Chad", "number": "148" },
  { "value": "CL", label: "Chile", "code": "CL", "code3": "CHL", "name": "Chile", "number": "152" },
  { "value": "CN", label: "China", "code": "CN", "code3": "CHN", "name": "China", "number": "156" },
  { "value": "CX", label: "Christmas Island", "code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162" },
  { "value": "CC", label: "Cocos (Keeling) Islands (the)", "code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166" },
  { "value": "CO", label: "Colombia", "code": "CO", "code3": "COL", "name": "Colombia", "number": "170" },
  { "value": "KM", label: "Comoros (the)", "code": "KM", "code3": "COM", "name": "Comoros (the)", "number": "174" },
  { "value": "CD", label: "Congo (the Democratic Republic of the)", "code": "CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180" },
  { "value": "CG", label: "Congo (the)", "code": "CG", "code3": "COG", "name": "Congo (the)", "number": "178" },
  { "value": "CK", label: "Cook Islands (the)", "code": "CK", "code3": "COK", "name": "Cook Islands (the)", "number": "184" },
  { "value": "CR", label: "Costa Rica", "code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188" },
  { "value": "HR", label: "Croatia", "code": "HR", "code3": "HRV", "name": "Croatia", "number": "191" },
  { "value": "CU", label: "Cuba", "code": "CU", "code3": "CUB", "name": "Cuba", "number": "192" },
  { "value": "CW", label: "Curaçao", "code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531" },
  { "value": "CY", label: "Cyprus", "code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196" },
  { "value": "CZ", label: "Czechia", "code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203" },
  { "value": "CI", label: "Côte d'Ivoire", "code": "CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384" },
  { "value": "DK", label: "Denmark", "code": "DK", "code3": "DNK", "name": "Denmark", "number": "208" },
  { "value": "DJ", label: "Djibouti", "code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262" },
  { "value": "DM", label: "Dominica", "code": "DM", "code3": "DMA", "name": "Dominica", "number": "212" },
  { "value": "DO", label: "Dominican Republic (the)", "code": "DO", "code3": "DOM", "name": "Dominican Republic (the)", "number": "214" },
  { "value": "EC", label: "Ecuador", "code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218" },
  { "value": "EG", label: "Egypt", "code": "EG", "code3": "EGY", "name": "Egypt", "number": "818" },
  { "value": "SV", label: "El Salvador", "code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222" },
  { "value": "GQ", label: "Equatorial Guinea", "code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226" },
  { "value": "ER", label: "Eritrea", "code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232" },
  { "value": "EE", label: "Estonia", "code": "EE", "code3": "EST", "name": "Estonia", "number": "233" },
  { "value": "SZ", label: "Eswatini", "code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748" },
  { "value": "ET", label: "Ethiopia", "code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231" },
  { "value": "FK", label: "Falkland Islands (the) [Malvinas]", "code": "FK", "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238" },
  { "value": "FO", label: "Faroe Islands (the)", "code": "FO", "code3": "FRO", "name": "Faroe Islands (the)", "number": "234" },
  { "value": "FJ", label: "Fiji", "code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242" },
  { "value": "FI", label: "Finland", "code": "FI", "code3": "FIN", "name": "Finland", "number": "246" },
  { "value": "FR", label: "France", "code": "FR", "code3": "FRA", "name": "France", "number": "250" },
  { "value": "GF", label: "French Guiana", "code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254" },
  { "value": "PF", label: "French Polynesia", "code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258" },
  { "value": "TF", label: "French Southern Territories (the)", "code": "TF", "code3": "ATF", "name": "French Southern Territories (the)", "number": "260" },
  { "value": "GA", label: "Gabon", "code": "GA", "code3": "GAB", "name": "Gabon", "number": "266" },
  { "value": "GM", label: "Gambia (the)", "code": "GM", "code3": "GMB", "name": "Gambia (the)", "number": "270" },
  { "value": "GE", label: "Georgia", "code": "GE", "code3": "GEO", "name": "Georgia", "number": "268" },
  { "value": "DE", label: "Germany", "code": "DE", "code3": "DEU", "name": "Germany", "number": "276" },
  { "value": "GH", label: "Ghana", "code": "GH", "code3": "GHA", "name": "Ghana", "number": "288" },
  { "value": "GI", label: "Gibraltar", "code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292" },
  { "value": "GR", label: "Greece", "code": "GR", "code3": "GRC", "name": "Greece", "number": "300" },
  { "value": "GL", label: "Greenland", "code": "GL", "code3": "GRL", "name": "Greenland", "number": "304" },
  { "value": "GD", label: "Grenada", "code": "GD", "code3": "GRD", "name": "Grenada", "number": "308" },
  { "value": "GP", label: "Guadeloupe", "code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312" },
  { "value": "GU", label: "Guam", "code": "GU", "code3": "GUM", "name": "Guam", "number": "316" },
  { "value": "GT", label: "Guatemala", "code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320" },
  { "value": "GG", label: "Guernsey", "code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831" },
  { "value": "GN", label: "Guinea", "code": "GN", "code3": "GIN", "name": "Guinea", "number": "324" },
  { "value": "GW", label: "Guinea-Bissau", "code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624" },
  { "value": "GY", label: "Guyana", "code": "GY", "code3": "GUY", "name": "Guyana", "number": "328" },
  { "value": "HT", label: "Haiti", "code": "HT", "code3": "HTI", "name": "Haiti", "number": "332" },
  { "value": "HM", label: "Heard Island and McDonald Islands", "code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334" },
  { "value": "VA", label: "Holy See (the)", "code": "VA", "code3": "VAT", "name": "Holy See (the)", "number": "336" },
  { "value": "HN", label: "Honduras", "code": "HN", "code3": "HND", "name": "Honduras", "number": "340" },
  { "value": "HK", label: "Hong Kong", "code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344" },
  { "value": "HU", label: "Hungary", "code": "HU", "code3": "HUN", "name": "Hungary", "number": "348" },
  { "value": "IS", label: "Iceland", "code": "IS", "code3": "ISL", "name": "Iceland", "number": "352" },
  { "value": "IN", label: "India", "code": "IN", "code3": "IND", "name": "India", "number": "356" },
  { "value": "ID", label: "Indonesia", "code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360" },
  { "value": "IR", label: "Iran (Islamic Republic of)", "code": "IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364" },
  { "value": "IQ", label: "Iraq", "code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368" },
  { "value": "IE", label: "Ireland", "code": "IE", "code3": "IRL", "name": "Ireland", "number": "372" },
  { "value": "IM", label: "Isle of Man", "code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833" },
  { "value": "IL", label: "Israel", "code": "IL", "code3": "ISR", "name": "Israel", "number": "376" },
  { "value": "IT", label: "Italy", "code": "IT", "code3": "ITA", "name": "Italy", "number": "380" },
  { "value": "JM", label: "Jamaica", "code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388" },
  { "value": "JP", label: "Japan", "code": "JP", "code3": "JPN", "name": "Japan", "number": "392" },
  { "value": "JE", label: "Jersey", "code": "JE", "code3": "JEY", "name": "Jersey", "number": "832" },
  { "value": "JO", label: "Jordan", "code": "JO", "code3": "JOR", "name": "Jordan", "number": "400" },
  { "value": "KZ", label: "Kazakhstan", "code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398" },
  { "value": "KE", label: "Kenya", "code": "KE", "code3": "KEN", "name": "Kenya", "number": "404" },
  { "value": "KI", label: "Kiribati", "code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296" },
  { "value": "KP", label: "Korea (the Democratic People's Republic of)", "code": "KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408" },
  { "value": "KR", label: "Korea (the Republic of)", "code": "KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410" },
  { "value": "KW", label: "Kuwait", "code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414" },
  { "value": "KG", label: "Kyrgyzstan", "code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417" },
  { "value": "LA", label: "Lao People's Democratic Republic (the)", "code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418" },
  { "value": "LV", label: "Latvia", "code": "LV", "code3": "LVA", "name": "Latvia", "number": "428" },
  { "value": "LB", label: "Lebanon", "code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422" },
  { "value": "LS", label: "Lesotho", "code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426" },
  { "value": "LR", label: "Liberia", "code": "LR", "code3": "LBR", "name": "Liberia", "number": "430" },
  { "value": "LY", label: "Libya", "code": "LY", "code3": "LBY", "name": "Libya", "number": "434" },
  { "value": "LI", label: "Liechtenstein", "code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438" },
  { "value": "LT", label: "Lithuania", "code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440" },
  { "value": "LU", label: "Luxembourg", "code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442" },
  { "value": "MO", label: "Macao", "code": "MO", "code3": "MAC", "name": "Macao", "number": "446" },
  { "value": "MG", label: "Madagascar", "code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450" },
  { "value": "MW", label: "Malawi", "code": "MW", "code3": "MWI", "name": "Malawi", "number": "454" },
  { "value": "MY", label: "Malaysia", "code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458" },
  { "value": "MV", label: "Maldives", "code": "MV", "code3": "MDV", "name": "Maldives", "number": "462" },
  { "value": "ML", label: "Mali", "code": "ML", "code3": "MLI", "name": "Mali", "number": "466" },
  { "value": "MT", label: "Malta", "code": "MT", "code3": "MLT", "name": "Malta", "number": "470" },
  { "value": "MH", label: "Marshall Islands (the)", "code": "MH", "code3": "MHL", "name": "Marshall Islands (the)", "number": "584" },
  { "value": "MQ", label: "Martinique", "code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474" },
  { "value": "MR", label: "Mauritania", "code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478" },
  { "value": "MU", label: "Mauritius", "code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480" },
  { "value": "YT", label: "Mayotte", "code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175" },
  { "value": "MX", label: "Mexico", "code": "MX", "code3": "MEX", "name": "Mexico", "number": "484" },
  { "value": "FM", label: "Micronesia (Federated States of)", "code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583" },
  { "value": "MD", label: "Moldova (the Republic of)", "code": "MD", "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498" },
  { "value": "MC", label: "Monaco", "code": "MC", "code3": "MCO", "name": "Monaco", "number": "492" },
  { "value": "MN", label: "Mongolia", "code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496" },
  { "value": "ME", label: "Montenegro", "code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499" },
  { "value": "MS", label: "Montserrat", "code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500" },
  { "value": "MA", label: "Morocco", "code": "MA", "code3": "MAR", "name": "Morocco", "number": "504" },
  { "value": "MZ", label: "Mozambique", "code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508" },
  { "value": "MM", label: "Myanmar", "code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104" },
  { "value": "NA", label: "Namibia", "code": "NA", "code3": "NAM", "name": "Namibia", "number": "516" },
  { "value": "NR", label: "Nauru", "code": "NR", "code3": "NRU", "name": "Nauru", "number": "520" },
  { "value": "NP", label: "Nepal", "code": "NP", "code3": "NPL", "name": "Nepal", "number": "524" },
  { "value": "NL", label: "Netherlands (the)", "code": "NL", "code3": "NLD", "name": "Netherlands (the)", "number": "528" },
  { "value": "NC", label: "New Caledonia", "code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540" },
  { "value": "NZ", label: "New Zealand", "code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554" },
  { "value": "NI", label: "Nicaragua", "code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558" },
  { "value": "NE", label: "Niger (the)", "code": "NE", "code3": "NER", "name": "Niger (the)", "number": "562" },
  { "value": "NG", label: "Nigeria", "code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566" },
  { "value": "NU", label: "Niue", "code": "NU", "code3": "NIU", "name": "Niue", "number": "570" },
  { "value": "NF", label: "Norfolk Island", "code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574" },
  { "value": "MP", label: "Northern Mariana Islands (the)", "code": "MP", "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580" },
  { "value": "NO", label: "Norway", "code": "NO", "code3": "NOR", "name": "Norway", "number": "578" },
  { "value": "OM", label: "Oman", "code": "OM", "code3": "OMN", "name": "Oman", "number": "512" },
  { "value": "PK", label: "Pakistan", "code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586" },
  { "value": "PW", label: "Palau", "code": "PW", "code3": "PLW", "name": "Palau", "number": "585" },
  { "value": "PS", label: "Palestine, State of", "code": "PS", "code3": "PSE", "name": "Palestine, State of", "number": "275" },
  { "value": "PA", label: "Panama", "code": "PA", "code3": "PAN", "name": "Panama", "number": "591" },
  { "value": "PG", label: "Papua New Guinea", "code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598" },
  { "value": "PY", label: "Paraguay", "code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600" },
  { "value": "PE", label: "Peru", "code": "PE", "code3": "PER", "name": "Peru", "number": "604" },
  { "value": "PH", label: "Philippines (the)", "code": "PH", "code3": "PHL", "name": "Philippines (the)", "number": "608" },
  { "value": "PN", label: "Pitcairn", "code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612" },
  { "value": "PL", label: "Poland", "code": "PL", "code3": "POL", "name": "Poland", "number": "616" },
  { "value": "PT", label: "Portugal", "code": "PT", "code3": "PRT", "name": "Portugal", "number": "620" },
  { "value": "PR", label: "Puerto Rico", "code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630" },
  { "value": "QA", label: "Qatar", "code": "QA", "code3": "QAT", "name": "Qatar", "number": "634" },
  { "value": "MK", label: "Republic of North Macedonia", "code": "MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807" },
  { "value": "RO", label: "Romania", "code": "RO", "code3": "ROU", "name": "Romania", "number": "642" },
  { "value": "RU", label: "Russian Federation (the)", "code": "RU", "code3": "RUS", "name": "Russian Federation (the)", "number": "643" },
  { "value": "RW", label: "Rwanda", "code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646" },
  { "value": "RE", label: "Réunion", "code": "RE", "code3": "REU", "name": "Réunion", "number": "638" },
  { "value": "BL", label: "Saint Barthélemy", "code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652" },
  { "value": "SH", label: "Saint Helena, Ascension and Tristan da Cunha", "code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654" },
  { "value": "KN", label: "Saint Kitts and Nevis", "code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659" },
  { "value": "LC", label: "Saint Lucia", "code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662" },
  { "value": "MF", label: "Saint Martin (French part)", "code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663" },
  { "value": "PM", label: "Saint Pierre and Miquelon", "code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666" },
  { "value": "VC", label: "Saint Vincent and the Grenadines", "code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670" },
  { "value": "WS", label: "Samoa", "code": "WS", "code3": "WSM", "name": "Samoa", "number": "882" },
  { "value": "SM", label: "San Marino", "code": "SM", "code3": "SMR", "name": "San Marino", "number": "674" },
  { "value": "ST", label: "Sao Tome and Principe", "code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678" },
  { "value": "SA", label: "Saudi Arabia", "code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682" },
  { "value": "SN", label: "Senegal", "code": "SN", "code3": "SEN", "name": "Senegal", "number": "686" },
  { "value": "RS", label: "Serbia", "code": "RS", "code3": "SRB", "name": "Serbia", "number": "688" },
  { "value": "SC", label: "Seychelles", "code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690" },
  { "value": "SL", label: "Sierra Leone", "code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694" },
  { "value": "SG", label: "Singapore", "code": "SG", "code3": "SGP", "name": "Singapore", "number": "702" },
  { "value": "SX", label: "Sint Maarten (Dutch part)", "code": "SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534" },
  { "value": "SK", label: "Slovakia", "code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703" },
  { "value": "SI", label: "Slovenia", "code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705" },
  { "value": "SB", label: "Solomon Islands", "code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090" },
  { "value": "SO", label: "Somalia", "code": "SO", "code3": "SOM", "name": "Somalia", "number": "706" },
  { "value": "ZA", label: "South Africa", "code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710" },
  { "value": "GS", label: "South Georgia and the South Sandwich Islands", "code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239" },
  { "value": "SS", label: "South Sudan", "code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728" },
  { "value": "ES", label: "Spain", "code": "ES", "code3": "ESP", "name": "Spain", "number": "724" },
  { "value": "LK", label: "Sri Lanka", "code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144" },
  { "value": "SD", label: "Sudan (the)", "code": "SD", "code3": "SDN", "name": "Sudan (the)", "number": "729" },
  { "value": "SR", label: "Suriname", "code": "SR", "code3": "SUR", "name": "Suriname", "number": "740" },
  { "value": "SJ", label: "Svalbard and Jan Mayen", "code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744" },
  { "value": "SE", label: "Sweden", "code": "SE", "code3": "SWE", "name": "Sweden", "number": "752" },
  { "value": "CH", label: "Switzerland", "code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756" },
  { "value": "SY", label: "Syrian Arab Republic", "code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760" },
  { "value": "TW", label: "Taiwan", "code": "TW", "code3": "TWN", "name": "Taiwan", "number": "158" },
  { "value": "TJ", label: "Tajikistan", "code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762" },
  { "value": "TZ", label: "Tanzania, United Republic of", "code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834" },
  { "value": "TH", label: "Thailand", "code": "TH", "code3": "THA", "name": "Thailand", "number": "764" },
  { "value": "TL", label: "Timor-Leste", "code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626" },
  { "value": "TG", label: "Togo", "code": "TG", "code3": "TGO", "name": "Togo", "number": "768" },
  { "value": "TK", label: "Tokelau", "code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772" },
  { "value": "TO", label: "Tonga", "code": "TO", "code3": "TON", "name": "Tonga", "number": "776" },
  { "value": "TT", label: "Trinidad and Tobago", "code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780" },
  { "value": "TN", label: "Tunisia", "code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788" },
  { "value": "TR", label: "Turkey", "code": "TR", "code3": "TUR", "name": "Turkey", "number": "792" },
  { "value": "TM", label: "Turkmenistan", "code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795" },
  { "value": "TC", label: "Turks and Caicos Islands (the)", "code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796" },
  { "value": "TV", label: "Tuvalu", "code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798" },
  { "value": "UG", label: "Uganda", "code": "UG", "code3": "UGA", "name": "Uganda", "number": "800" },
  { "value": "UA", label: "Ukraine", "code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804" },
  { "value": "AE", label: "United Arab Emirates (the)", "code": "AE", "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784" },
  { "value": "GB", label: "United Kingdom of Great Britain and Northern Ireland (the)", "code": "GB", "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826" },
  { "value": "UM", label: "United States Minor Outlying Islands (the)", "code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581" },
  { "value": "US", label: "United States of America (the)", "code": "US", "code3": "USA", "name": "United States of America (the)", "number": "840" },
  { "value": "UY", label: "Uruguay", "code": "UY", "code3": "URY", "name": "Uruguay", "number": "858" },
  { "value": "UZ", label: "Uzbekistan", "code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860" },
  { "value": "VU", label: "Vanuatu", "code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548" },
  { "value": "VE", label: "Venezuela (Bolivarian Republic of)", "code": "VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862" },
  { "value": "VN", label: "Viet Nam", "code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704" },
  { "value": "VG", label: "Virgin Islands (British)", "code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092" },
  { "value": "VI", label: "Virgin Islands (U.S.)", "code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850" },
  { "value": "WF", label: "Wallis and Futuna", "code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876" },
  { "value": "EH", label: "Western Sahara", "code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732" },
  { "value": "YE", label: "Yemen", "code": "YE", "code3": "YEM", "name": "Yemen", "number": "887" },
  { "value": "ZM", label: "Zambia", "code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894" },
  { "value": "ZW", label: "Zimbabwe", "code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716" },
  { "value": "AX", label: "Åland Islands", "code": "AX", "code3": "ALA", "name": "Åland Islands", "number": "248" }
];