import { Port } from 'features/global/ports';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Input from '../../../../forms/components/Input';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import Button from '../../../../layout/components/Button';
import Modal from '../../../../layout/components/Modal';
import plusIcon from '../../../../tables/styles/images/plusIcon.svg';
import { selectHealthDeclaration } from '../slices/healthSlice';
import { searchForPort } from '../slices/portCallSlice';
import '../styles/health.scss';
// @ts-ignore
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
// @ts-ignore
import { Field } from '@navozyme/uikit/dist/atoms/Field';

interface PortModalProps {
  isEditingRow: boolean;
  editingRow: number | null;
  isModalOpen: boolean;
  setIsModalOpen: (newValue: boolean) => void;
  setEditingRow: (newValue: number | null) => void;
  setData: (newValue: any) => void;
}

interface FormValues {
  name: string | null | Object;
  unlocode: string;
  dateFrom: null | string | Date;
  dateTo: null | string | Date;
}

export const PortModal: React.FC<PortModalProps> = ({
  isEditingRow,
  editingRow,
  isModalOpen,
  setIsModalOpen,
  setEditingRow,
  setData,
}) => {
  const healthDeclaration = useSelector(selectHealthDeclaration);
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: null,
    unlocode: '',
    dateFrom: null,
    dateTo: null,
  });

  const [whoFromDate, setWhoFromDate] = useState(
    initialValues.dateFrom || null
  );
  const [whoToDate, setWhoToDate] = useState(initialValues.dateTo || null);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    if (isEditingRow) {
      healthDeclaration.portsVisitedWithinWHO.forEach(
        (port: any, index: number) => {
          if (index === editingRow) {
            dispatch(
              searchForPort({
                unlocode:
                  healthDeclaration.portsVisitedWithinWHO[editingRow as number]
                    .unlocode,
              }) as any
            ).then((response: any) => {
              const dateFrom = new Date(port.dateFrom);
              const dateTo = new Date(port.dateTo);

              setInitialValues({
                name: response.payload[0].name,
                unlocode: port.unlocode,
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
              setCountryCode(response.payload[0].countryCode);
            });
          }
        }
      );
    }
  }, [isEditingRow, editingRow]);

  useEffect(() => {
    if (initialValues.unlocode) {
      healthDeclaration.portsVisitedWithinWHO.forEach(
        (port: any, index: number) => {
          if (index === editingRow) {
            dispatch(
              searchForPort({ unlocode: initialValues.unlocode }) as any
            ).then((response: any) => {
              const dateFrom = new Date(port.dateFrom);
              const dateTo = new Date(port.dateTo);

              setInitialValues({
                name: response.payload[0].name,
                unlocode: initialValues.unlocode,
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
              setCountryCode(response.payload[0].countryCode);
            });
          }
        }
      );
    }
  }, [initialValues.unlocode]);

  const validationSchema = Yup.object({
    name: Yup.string().required('* This field is required'),
    dateFrom: Yup.string().required('* This field is required'),
    dateTo: Yup.string().required('* This field is required'),
  });

  const handleSubmit = async (values: FormValues) => {
    const finalValues = {
      ...values,
    };

    if (values.dateFrom) {
      finalValues.dateFrom = new Date(values.dateFrom).toISOString();
    }
    if (values.dateTo) {
      finalValues.dateTo = new Date(values.dateTo).toISOString();
    }

    setData(finalValues);

    setIsModalOpen(false);
    setWhoFromDate(null);
    setWhoToDate(null);
  };

  return (
    <Modal
      title={'Add a port call'}
      className={`health-modal`}
      showModal={isModalOpen}
      onCloseModal={() => {
        setInitialValues({
          name: null,
          unlocode: '',
          dateFrom: '',
          dateTo: '',
        });
        setWhoFromDate(null);
        setWhoToDate(null);
        setIsModalOpen(false);
      }}>
      <div className='health-modal__content'>
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ values, setValues, errors }) => {
            return (
              <Form className='health-form'>
                <div className='health-form__row'>
                  <SearchPortsDropdown
                    label='Port'
                    name='name'
                    onChange={(port: Port) => {
                      setInitialValues({
                        name: port.name,
                        unlocode: port.unlocode,
                        dateFrom: '',
                        dateTo: '',
                      });
                    }}
                    dropdownID='healthDeclaration.port'
                    placeholder={
                      values.name
                        ? `${values.name} , ${countryCode} `
                        : 'Type One'
                    }
                  />
                  <Input
                    value={values.unlocode}
                    name='unlocode'
                    disabled
                    label='UNLOCODE'
                  />
                </div>
                <div className='health-form__row'>
                  <Field label='Date From' className='field_date_picker'>
                    <DatePickerTimeInput
                      key={
                        initialValues?.dateFrom + values?.unlocode + 'dateFrom'
                      }
                      name='dateFrom'
                      sizeInput='large'
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={whoFromDate}
                      action={({ isoDate }: any) => {
                        setWhoFromDate(isoDate);
                        setValues({ ...values, dateFrom: new Date(isoDate) });
                      }}
                      hasError={errors?.dateFrom}
                      errorMessage={errors?.dateFrom}
                    />
                  </Field>
                  <Field label='Date To' className='field_date_picker'>
                    <DatePickerTimeInput
                      key={initialValues?.dateTo + values?.unlocode + 'dateTo'}
                      name='dateTo'
                      sizeInput='large'
                      minDate={
                        whoFromDate ? new Date(String(whoFromDate)) : null
                      }
                      showTime={false}
                      defaultValue={whoToDate}
                      action={({ isoDate }: any) => {
                        setWhoToDate(isoDate);
                        setValues({ ...values, dateTo: new Date(isoDate) });
                      }}
                      hasError={errors?.dateTo}
                      errorMessage={errors?.dateTo}
                    />
                  </Field>
                </div>
                {isEditingRow ? (
                  <div className='add-member__form-submission-btns'>
                    <div
                      className='discard-btn'
                      onClick={() => {
                        setIsModalOpen(false);
                        setTimeout(() => setEditingRow(null), 300);
                      }}>
                      Discard
                    </div>
                    <Button type='submit'>Change Information</Button>
                  </div>
                ) : (
                  <div className='add-member__form-submission-btns'>
                    <Button iconLeft={plusIcon} type='submit'>
                      Add
                    </Button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default PortModal;
