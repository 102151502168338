import React from 'react';
import Input from './Input';
import SearchPortsDropdown from './search-ports-dropdown/components/SearchPortsDropdown';
import { Field } from 'formik';


export default function FlexibleInput( props ) {
  const { isTextInput, inputName, dropdownName, label, dropdownID, placeholder, getSpecificValue, value, ...rest } = props;

  return (
    <>
        {isTextInput ? (
            <Input name={inputName} label={label} placeholder={placeholder} {...rest} />) :
            (<>
                <SearchPortsDropdown
                label={label}
                name={dropdownName || inputName}
                dropdownID={dropdownID}
                placeholder={placeholder}
                getSpecificValue= {getSpecificValue}
                {...rest} />
                <Field name={dropdownName || inputName} type='hidden'/>
              </>
              )}
    </>
  );
}