import { Gender } from "../globalTypes";

export interface Option {
  value: string;
  label: string;  
};

export const genderOptions: Option[] = [
  { value: Gender.Male, label: Gender.Male },
  { value: Gender.Female, label: Gender.Female }
]

export const idNatureOptions: Option[] = [
  { value: "Passport", label: "Passport" },
  { value: "ID", label: "ID" },
  { value: "ResidentCard", label: "Resident Card" },
  { value: "SeamansBook", label: "Seaman's book" }
];

export const rankOptions: Option[] = [
  { value: "Master", label: "Master" },
  { value: "Chief engineer", label: "Chief engineer" },
  { value: "2nd engineer", label: "2nd engineer" },
  { value: "3rd engineer", label: "3rd engineer" },
  { value: "4th engineer", label: "4th engineer" },
  { value: "Bosun", label: "Bosun" },
  { value: "Ab seaman", label: "Ab seaman" },
  { value: "Chief officer", label: "Chief officer" },
  { value: "2nd officer", label: "2nd officer" },
  { value: "3rd officer", label: "3rd officer" },
  { value: "Chief Cook", label: "Chief Cook" },
  { value: "Chief Mate", label: "Chief Mate" },
  { value: "Messman", label: "Messman" },
  { value: "Pumpman", label: "Pumpman" },
  { value: "Electrician", label: "Electrician" },
  { value: "Wiper", label: "Wiper" },
  { value: "Others", label: "Others" },
  { value: "Pilot", label: "Pilot" },
  { value: "n/a", label: "n/a" }
];
