import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import Input from '../../../../forms/components/Input';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import Button from '../../../../layout/components/Button';
import Modal from '../../../../layout/components/Modal';
import PageHeader from '../../../../layout/components/PageHeader';
import Table from '../../../../tables/components/Table';
import plusIcon from '../../../../tables/styles/images/plusIcon.svg';
import { selectCargo, selectDangerousGoods, updateCargo } from '../../../slices/portCallSlice';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import '../styles/cargo-table.scss';

const cargoToDeclare = booleanOptionsForRadioGroup();

function CargoTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);
  const params = useParams();
  const cargo = useSelector(selectCargo);
  const dangerousGoods = useSelector(selectDangerousGoods);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, cargo]);

  const columns = [
    {
      Header: 'Name of article',
      accessor: 'name',
      width: 100,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      width: 100,
    },
    {
      Header: 'Location on board',
      accessor: 'location',
      width: 100,
    },
    {
      Header: 'Official use',
      accessor: 'officialUse',
    },
  ];

  let initialValues = {
    name: '',
    quantity: '',
    location: '',
    officialUse: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    quantity: Yup.string(),
    location: Yup.string(),
    officialUse: Yup.string(),
  });

  const handleSubmit = async (values, onSubmitProps) => {
    if (isEditingRow) {
      const newMembers = cargo.declaredCargo.map((member, i) => {
        if (i === editingRow) {
          return values;
        }
        return member;
      });

      dispatch(
        updateCargo({
          declaredCargo: newMembers,
        })
      );

      setIsModalOpen(false);
      setEditingRow(null);
    } else {
      if (values.hasOwnProperty('cargoToDeclare') && values.cargoToDeclare === false) {
        dispatch(
          updateCargo({
            cargoToDeclare: false,
          })
        );
      } else if (Object.keys(values).length > 0 && !values.hasOwnProperty('cargoToDeclare')) {
        dispatch(
          updateCargo({
            cargoToDeclare: true,
            declaredCargo: cargo.declaredCargo.concat(values),
          })
        );
      }

      setIsModalOpen(false);
    }
  };

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const row = isEditingRow ? cargo.declaredCargo[editingRow] : {};
  initialValues = {
    name: row.name ? row.name : '',
    quantity: row.quantity ? row.quantity : '',
    location: row.location ? row.location : '',
    officialUse: row.officialUse ? row.officialUse : '',
  };

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false,
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);

  return (
    <div className='cargo-table'>
      <PageHeader title={'Cargo - General information'} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={{
          cargoToDeclare: cargo.cargoToDeclare,
        }}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values }) => {
          return (
            <>
              <Form className='crew-changes-form'>
                <RadioButtonsCustom name='cargoToDeclare' label='Any cargo to declare?' options={cargoToDeclare} />
              </Form>
              {values.cargoToDeclare && (
                <Table
                  title={'Ship’s Stores Declaration'}
                  addButtonText={'Add cargo'}
                  data={cargo.declaredCargo}
                  columns={columns}
                  editingMode={isEditingTable}
                  setEditingMode={setIsEditingTable}
                  setIsAddModalOpen={setIsModalOpen}
                  setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
                  onEditButtonClick={(rowIndex) => {
                    setEditingRow(rowIndex);
                    setIsModalOpen(true);
                  }}
                  onDeleteButtonClick={(rowIndex) => {
                    setConfirmDeleteMemberModalData({
                      showModal: true,
                      index: rowIndex,
                    });
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
      <SkipAndNextButtons
        onClick={() => navigate(`/${params.uid}/dangerous-goods`)}
        next={`/${params.uid}/dangerous-goods`}
        back={`/${params.uid}/cargo/information`}
      />
      <Modal
        className={`add-cargo-modal`}
        title={'Add cargo'}
        showModal={isModalOpen}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}>
        <div className='add-cargo-modal__content'>
          <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            <Form className='add-cargo-form'>
              <div className='add-cargo-form__row'>
                <Input name='name' placeholder='Type one' label='Name of the article' />
                <Input name='quantity' type='number' placeholder='Type one' label='Quantity' />
              </div>
              <div className='add-cargo-form__row'>
                <Input name='location' placeholder='Type one' label='Location on board' />
                <Input name='officialUse' placeholder='Type one' label='Official use' />
              </div>
              {isEditingRow ? (
                <div className='add-cargo__form-submission-btns'>
                  <div
                    className='discard-btn'
                    onClick={() => {
                      setIsModalOpen(false);
                      setTimeout(() => setEditingRow(null), 300);
                    }}>
                    Discard
                  </div>
                  <Button type='submit'>Change Information</Button>
                </div>
              ) : (
                <div className='add-cargo__form-submission-btns'>
                  <Button iconLeft={plusIcon} type='submit'>
                    Add
                  </Button>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
        title={
          <h3>
            Are you sure you want to{' '}
            <span>
              delete all <br />
              cargo items
            </span>{' '}
            from the list?
          </h3>
        }
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          setShowConfirmDeleteAllMembersModal(false);

          dispatch(
            updateCargo({
              cargoToDeclare: false,
              declaredCargo: [],
            })
          );
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this cargo item?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onDeleteClick={() => {
          const declaredCargo = [...cargo.declaredCargo];
          declaredCargo.splice(confirmDeleteMemberModalData.index, 1);

          dispatch(
            updateCargo({
              cargoToDeclare: declaredCargo.length === 0 ? false : true,
              declaredCargo,
            })
          );

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
    </div>
  );
}

export default CargoTable;
