import { Field } from 'formik';
import '../../captains-form/styles/mandatory-check.scss';
import '../styles/multi-select.scss';
import ReactSelect from './ReactSelect';

export default function MultiSelect(props) {
  const { label, name, onInputChange, onChange, hasError = false, errorMessage = '', ...rest } = props;

  return (
    <div className={'form-control multi-select'}>
      {label && (
        <label className='input__label' htmlFor={name}>
          {label}
        </label>
      )}
      {props.sublabel && <span className='sublabel'>{props.sublabel}</span>}
      <Field
        name={name}
        id={name}
        onChange={onChange}
        isMulti={true}
        component={ReactSelect}
        onInputChange={onInputChange}
        className={hasError ? 'input-error-border' : ''}
        {...rest}
      />
      {hasError && <div className='error-dropdown'>{errorMessage}</div>}
    </div>
  );
}
