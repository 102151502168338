import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createUserSession } from './authAPI';
import authHelper from './auth';

const initialState = {
  jwt: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const signup = createAsyncThunk('auth/createUserSession', async (amount) => {
  const response = await createUserSession(amount);
  // The value we return becomes the `fulfilled` action payload
  const CURRENT_URL = window.location.href;
  const myRe = new RegExp('(?<=t=).*', 'g');
  const token = myRe.exec(CURRENT_URL);

  const tokenValidation = new RegExp('^[a-z,0-9,-]{36,36}$', 'g');
  const validToken = tokenValidation.test(token);
  authHelper.storeToken(validToken ? token : null);
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    signOut: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      authHelper.clearSession();
      state.jwt = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.jwt = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        const CURRENT_URL = window.location.href;
        const myRe = new RegExp('(?<=t=).*', 'g');
        const token = myRe.exec(CURRENT_URL);

        const tokenValidation = new RegExp('^[a-z,0-9,-]{36,36}$', 'g');
        const validToken = tokenValidation.test(token);
        state.jwt = validToken ? token : null;
      });
  },
});

export const selectJWT = (state) => state.auth.jwt;

export const { signOut } = authSlice.actions;

export default authSlice.reducer;
