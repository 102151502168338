import axios from 'axios';

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		name: 'Content-Type', 
		value: 'application/json'
	}
});

//TODO It would be good to refactor usage of REACT_APP_API_URL and not store /v1 in there, as
// it is part of path (we have some endpoints with /v2).
const docsApi = process.env.REACT_APP_API_URL.replace('/v1', '/docs')

export const axiosDocumentsInstance = axios.create({
	baseURL: docsApi,
	headers: {
		name: 'Content-Type', 
		value: 'application/json'
	}
});
