import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Input from '../../forms/components/Input';
import { NavLink } from 'react-router-dom';
import { searchVessel, selectLoadingState, selectSearchResults, selectShouldShowSearchResultsSection } from '../slices/vesselsSlice';
import Page from '../../layout/components/Page';
import CollapsibleBox from '../../layout/components/CollapsibleBox';
import Card from '../../layout/components/Card';
import PageTitle from '../../layout/components/PageTitle';
import "../styles/port-clearance-form-request.scss";
import MagnifyingGlass from "../styles/images/magnifyingGlass.svg";
import ArrowRight from "../styles/images/arrowRight.svg";

const initialValues = {
  vesselName: "",
  imoNumber: ""
};

const validationSchema = Yup.object({
  vesselName: Yup.string(),
  imoNumber: Yup.string()
})

function PortClearanceFormRequest() {
  const dispatch = useDispatch();
  const searchResults = useSelector(selectSearchResults);
  const shouldShowLoading = useSelector(selectLoadingState);
  const shouldShowSearchResultsSection = useSelector(selectShouldShowSearchResultsSection);
  

  const handleSubmit = (values, onSubmitProps) => {
    dispatch(searchVessel(values));
  };

  const searchResultsList = searchResults.map((vessel, i) => {
    return <div className="search-result-list-item" key={`vessel-${i}`}>
      <NavLink to={`/port-clearance-form-request/vessel/${vessel.mmsi}`}>
        <Card theme="blue-with-hover"><div className="search-result-list-item__vessel-name">{vessel.shipName}</div><img src={ArrowRight} alt="ArrowRight" /></Card>
      </NavLink>
    </div>
  })

  return (
    <Page>
      <div className="port-clearance-form-request">
        <PageTitle>Port Clearance Form Request</PageTitle>
        <CollapsibleBox initialOpen={true} title={"Ship Data"}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {({ values, errors, touched, isSubmitting, setErrors }) => (
              <Form className="search-vessel-form">
                <Card theme="blue">
                  <div className="search-vessel-form__form-elements">
                    <Input name="vesselName" placeholder="Name" label="Vessel Name" />
                    <Input name="imoNumber" type="number" placeholder="IMO Number" label="IMO Number" />
                  </div>
                  <div className="form-error">
                    {errors._error ? <div>{errors._error}</div> : null}
                  </div>
                </Card>
                <div className="search-vessel-form__submit-btn">
                  <button
                    type="submit"
                    onClick={(e) => {
                      if (!values.vesselName && !values.imoNumber) {
                        setErrors({ _error: '*Please fill in either a Vessel Name or an IMO Number to search for vessels.' });
                        e.preventDefault()
                      }
                    }}>
                    <img src={MagnifyingGlass} alt="MagnifyingGlass" />
                    Search Vessel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </CollapsibleBox>
        {(shouldShowSearchResultsSection) && (
          <CollapsibleBox initialOpen={true} title={"Search results"}>
            {shouldShowLoading && (
              <div className="search-results__loading">
                <FontAwesomeIcon spin icon={faSpinner} size="3x" style={{ color: "#5b8fe9" }} />
              </div>
            )}
            {searchResultsList}
            {(searchResultsList.length === 0 && !shouldShowLoading) && (
              <div className="search-results__no-results">We could not find any vessels matching the specified parameters.</div>
            )}
          </CollapsibleBox>
        )}

      </div>
    </Page>
  );
}

export default PortClearanceFormRequest;
