import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { getCargo } from '../../../slices/portCallSlice';

function Cargo() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const [percentage, setPercentage] = useState('33');
  const [pageNumber, setPageNumber] = useState('');

  useEffect(() => {
    dispatch(getCargo(params.uid));
  }, [dispatch, params.uid]);

  useEffect(() => {
    if (location.pathname === `/${params.uid}/cargo/information`) {
      setPercentage('33');
      setPageNumber('1');
    } else if (location.pathname === `/${params.uid}/cargo/list`) {
      setPercentage('66');
      setPageNumber('2');
    } else if (location.pathname === `/${params.uid}/dangerous-goods`) {
      setPercentage('100');
      setPageNumber('3');
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/cargo`) {
    return <Navigate to={`/${params.uid}/cargo/information`} replace />;
  }

  return (
    <Page>
      <div className='cargo'>
        <ProgressBar
          percentage={percentage}
          pageNumber={pageNumber}
          totalPages={'3'}
        />
        <Outlet />
      </div>
    </Page>
  );
}

export default Cargo;
