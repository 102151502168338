export const disabledAnnexColumn = (condition) => {
  return condition ? { disabled: true } : {}
}

export const parseFloatWithDecimalsValuesInAnnexes = (object, decimalDigits=2) => {
  let parsedObject = JSON.parse(JSON.stringify(object)); 
  if (typeof parsedObject === 'object' && parsedObject !== null && typeof parsedObject !== 'string') {
    if (Object.keys(parsedObject).length > 0) {
      Object.keys(parsedObject).forEach((key) => {
        parsedObject[key] = parseFloatWithDecimalsValuesInAnnexes(parsedObject[key], decimalDigits);
      })
    }
  } else {
    if (!isNaN(parsedObject)) {
      return parseFloat(parseFloat(parsedObject).toFixed(decimalDigits));
    }
  }
  return parsedObject;
};

export const REQUIRED_FIELD_MESSAGE = "This field is required";

export const ALL_WASTE_DISPOSAL_APPROACH = "ALL";
export const PARTIALLY_WASTE_DISPOSAL_APPROACH = "PARTIALLY";
export const NONE_WASTE_DISPOSAL_APPROACH = "NONE";
