import { Form, Formik } from "formik";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from "yup";
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import { rankOptions } from '../../../../global/utils/globalOptions';
import Button from '../../../../layout/components/Button';
import Modal from "../../../../layout/components/Modal";
import Table from '../../../../tables/components/Table';
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import { selectCrew, updateCrew } from '../../../slices/portCallSlice';
import "../styles/effects-list-table.scss";
import ConfirmDeleteModal from './ConfirmDeleteModal';

function EffectsListTable() {
  const dispatch = useDispatch();
  const params = useParams();
  const crew = useSelector(selectCrew);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [familyNameOptions, setFamilyNameOptions] = useState([]);

  const columns = [
    {
      Header: "Family Name",
      accessor: "familyName",
      width: 30,
    },
    {
      Header: "Given Names",
      accessor: "givenNames",
      width: 40,
    },
    {
      Header: "Rank or rating",
      accessor: "rank",
      width: 30,
    },
    {
      Header: "Effects ineligible for relief from customs duties and taxes or subject to prohibitions or restrictions",
      accessor: "effects"
    }
  ];

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const row = isEditingRow ? crew.crewEffects[editingRow] : {};

  const initialValues = {
    familyName: row.familyName ? row.familyName : "",
    givenNames: row.givenNames ? row.givenNames : "",
    rank: row.rank ? row.rank : "",
    effects: row.effects ? row.effects : "",
  };

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);

  const validationSchema = Yup.object({
    familyName: Yup.string().required('Family Name is required'),
    givenNames: Yup.string().required('Given Name is required'),
    rank: Yup.string().required('Rank is required'),
    effects: Yup.string().min(1, 'Effects must not be empty').required('Effects are required')
  });


  const handleSubmit = async (values, onSubmitProps) => {
    if (isEditingRow) {
      const newMembers = crew.crewEffects.map((member, i) => {
        if (i === editingRow) {
          return values;
        }
        return member;
      });

      dispatch(updateCrew({
        uid: params.uid,
        data: {
          crewEffects: newMembers
        }
      }));

      setIsModalOpen(false);
      setEditingRow(null);
    } else {
      dispatch(updateCrew({
        uid: params.uid,
        data: {
          crewEffects: crew.crewEffects.concat(values)
        }
      }));
      setIsModalOpen(false);
    }
  };

  const handleFamilyNameChange = (inputValue, setFieldValue) => {
    if (inputValue) {
      const matches = crew.crewList.filter(member => member.surname.toLowerCase().includes(inputValue.toLowerCase()));
      const options = matches.map(member => ({
        value: member.surname,
        label: member.name + ' ' + member.surname,
        surname: member.surname,
        name: member.name,
        rank: member.rank
      }));

      setFamilyNameOptions(options);
    } else {
      setFamilyNameOptions([]);
    }
  };

  const handleFamilyNameSelect = (selectedOption, setFieldValue) => {
    setFieldValue('familyName', selectedOption.value);
    setFieldValue('givenNames', selectedOption.name);
    setFieldValue('rank', selectedOption.rank);
    setFamilyNameOptions([]);
  };

  return (
    <div className="effects-list-table">
      <Table
        useRowNumbers
        title={"Crew's Effects Declaration"}
        addButtonText={'Add Effect'}
        data={crew.crewEffects}
        columns={columns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex
          });
        }}
      />
      <Modal
        className={`add-effect-modal`}
        title={"Add effect"}
        showModal={isModalOpen}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}>
        <div className="add-effect-modal__content">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}>
            {({ setFieldValue, values }) => (
              <Form className="add-effect-form">
                <div className="add-effect-form__row">
                  <MultiSelect
                    name="familyName"
                    label="Family Name"
                    isMulti={false}
                    options={familyNameOptions}
                    onInputChange={(inputValue) => handleFamilyNameChange(inputValue, setFieldValue)}
                    onChange={(selectedOption) => handleFamilyNameSelect(selectedOption, setFieldValue)}
                    value={values.familyName ? { value: values.familyName, label: values.familyName } : null}
                  />
                  <Input name="givenNames" label="Given Names" disabled={true} />
                </div>
                <div className="add-effect-form__row">
                  <MultiSelect
                    name="rank"
                    options={rankOptions}
                    isMulti={false}
                    label="Rank or rating"
                    disabled={true}
                    value={values.rank ? { value: values.rank, label: values.rank } : null}
                  />
                  <Input name="effects" placeholder="Type one" label="Effects" autocomplete="off" />
                </div>
                {
                  isEditingRow ? (
                    <div className="add-member__form-submission-btns">
                      <div className="discard-btn" onClick={() => {
                        setIsModalOpen(false);
                        setTimeout(() => setEditingRow(null), 300);
                      }}>Discard</div>
                      <Button type="submit">Change Information</Button>
                    </div>
                  ) : (
                    <div className="add-member__form-submission-btns">
                      <Button iconLeft={plusIcon} type="submit">Add</Button>
                    </div>
                  )
                }
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to <span>delete all the <br />effects</span> from the list?</h3>}
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          setShowConfirmDeleteAllMembersModal(false);
          dispatch(updateCrew({
            uid: params.uid,
            data: {
              crewEffects: []
            }
          }));
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this crew effect?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          });
        }}
        onDeleteClick={() => {
          const crewEffects = [...crew.crewEffects];
          crewEffects.splice(confirmDeleteMemberModalData.index, 1);

          dispatch(updateCrew({
            uid: params.uid,
            data: {
              crewEffects
            }
          }));

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          });
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
    </div>
  );
}

export default EffectsListTable;
