import React, { useContext, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import ArrowRight from "../styles/images/arrow-right.svg";
import ArrowLeft from "../styles/images/arrow-left.svg";
import Button from '../../../../layout/components/Button';
import "../../../styles/skip-and-next-buttons.scss";
import { FormRefContext } from 'features/global/context/FormRefContext';

interface SkipAndNextButtonsProps {
  next: string;
  back: string;
  onClick?: () => void;
  noBack?: Boolean;
  noNext?: Boolean;
}

const SkipAndNextButtons: React.FC<SkipAndNextButtonsProps> = ({
  next, back, onClick = () => {}, noBack = false, noNext = false
}) => {
  
  const {setSkipOrBack} = useContext(FormRefContext)

  return (
    <div className="skip-and-next">
      {!noNext && (
        <NavLink to={next} className={"skip-btn"} onClick={()=>setSkipOrBack(true)}>Skip</NavLink>
      )}
      {!noBack && <NavLink to={back} className={"back-btn"} onClick={()=>setSkipOrBack(true)}>
        <img src={ArrowLeft} alt="arrowLeft" />
        Back</NavLink>}
      {!noNext && (
        <Button className="next-btn" type="submit" onClick={() => onClick ? onClick() : undefined}>
          Next<img src={ArrowRight} alt="arrowLeft" />
        </Button>
      )}
    </div>
  );
}

export default SkipAndNextButtons;
