import { useDispatch, useSelector } from 'react-redux';
// import {
//   useLocation,
//   Navigate,
// } from "react-router-dom";
import { selectJWT, signup } from '../slices/authSlice';

export default function RequireAuth({ children }) {
  const jwt = useSelector(selectJWT);
  //let location = useLocation();
  const dispatch = useDispatch();

  if (!jwt) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    dispatch(signup())
    //return <Navigate to="/signup" state={{ from: location }} replace />;
  }

  return children;
}