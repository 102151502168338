export const PORT_CALL_UUID = 'current_port_call_uuid';
export const STATUS_OPEN_TO_AGENT = "opentoagent";

const PortCall = {
	storePortCallUuid(portCallUuid) {
		return localStorage.setItem(PORT_CALL_UUID, portCallUuid);
	},

	getPortCallUuid() {
		return localStorage.getItem(PORT_CALL_UUID);
	}
}

export default PortCall;
