import { Option } from "features/global/utils/globalOptions";
import { formatDateInTable } from "features/global/utils/datetimes";

export const sanitaryMeasuresColumns = [
  { Header: "Port name", accessor: "portName" },
  { Header: "UNLOCODE", accessor: "unlocode" },
  { Header: "Type of sanitary measure", accessor: "typeOfSanitaryMeasure" },
  { Header: "Date from", accessor: "dateFrom", Cell: formatDateInTable },
  { Header: "Date to", accessor: "dateTo", Cell: formatDateInTable }
];

export const lastPortInThirtyDaysColumns = [
  { Header: "Port name", accessor: "name" },
  { Header: "UNLOCODE", accessor: "unlocode" },
  { Header: "Date from", accessor: "dateFrom", Cell: formatDateInTable },
  { Header: "Date to", accessor: "dateTo", Cell: formatDateInTable }
];

export const stateParticularColumns = [
  { Header: "Family name", accessor: "familyName" },
  { Header: "Given names", accessor: "givenNames" },
  { Header: "Class or rating", accessor: "classOrRating" },
  { Header: "Gender", accessor: "gender" },
  { Header: "Nationality", accessor: "nationality" },
  { Header: "Port of embarkation", accessor: "portOfEmbarkation" },
  { Header: "Date of embarkation", accessor: "dateOfEmbarkation", Cell: formatDateInTable },
  { Header: "Nature of illness", accessor: "natureOfIllness" },
  { Header: "Date of onset of symptoms", accessor: "dateOfOnsetOfSymptoms", Cell: formatDateInTable },
  { Header: "Reported to a port medical officer", accessor: "reportedToPortMedicalOfficer" },
  { Header: "State", accessor: "state" },
  { Header: "Disposal of case *", accessor: "disposalOfCase" },
  { Header: "Port evacuated", accessor: "evacuatedAtPort" },
  { Header: "Treatment given (drug medicines or other)", accessor: "treatmentGiven" },
  { Header: "Comments", accessor: "comments" }
];

export const sanitaryMeasureTypes: Option[] = [
  { value: "Quarantine", label: "Quarentine" },
  { value: "Isolation", label: "Isolation" },
  { value: "Disinfection", label: "Disinfection" },
  { value: "Decontamination", label: "Decontamination" }
];

export const particularStates: Option[] = [
  { value: "Recovered", label: "Recovered" },
  { value: "Ill", label: "Ill" },
  { value: "Died", label: "Died" }
];

export const caseDisposals: Option[] = [
  { value: "On board", label: "On board" },
  { value: "Evacuated", label: "Evacuated" },
  { value: "Buried at sea", label: "Buried at sea" }
];
