

export const CrossXIcon = (props: any) => {
     return <svg
         xmlns="http://www.w3.org/2000/svg"
         width="1em"
         height="1em"
         fill={props.fill}
         viewBox="0 0 24 25"
         preserveAspectRatio="xMidYMid meet"
         stroke={props.stroke}
         {...props}
     >
         <path
             fill="inherit"
             fillRule="evenodd"
             d="M5.47 5.835a.75.75 0 0 1 1.06 0l5.47 5.47 5.47-5.47a.75.75 0 1 1 1.06 1.06l-5.47 5.47 5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.425l-5.47 5.47a.75.75 0 0 1-1.06-1.06l5.47-5.47-5.47-5.47a.75.75 0 0 1 0-1.06Z"
             clipRule="evenodd"
         />
     </svg>
 }
