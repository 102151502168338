import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import "../styles/checkbox-group.scss";

export default function CheckboxGroup(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control checkbox-group">
      <label className="checkbox-group__label">{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return (
            options.map(option => {
              return (
                <div className="checkbox" key={option.key}>
                  <input
                    type="checkbox"
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value.includes(option.value)} />
                  <label htmlFor={option.value}>{option.key}</label>
                </div>
              )
            })
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
