import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnnexFive,
  searchDangerousGoodsJCargo,
  searchDangerousGoodsKCargo,
  selectJCargoDangerousGoodsSearchResults,
  selectKCargoDangerousGoodsSearchResults,
} from "../slices/marpolSlice";
import { emptyHandleSubmit } from "./MarpolTables"
import MultiSelect from '../../../../forms/components/MultiSelect';
import WasteRow from "./WasteRow";
import WasteHeader from "./WasteHeader";

export default function AnnexFiveTable(props) {
  const dispatch = useDispatch();

  const annexFive = useSelector(selectAnnexFive || {});
  const jCargoDangerousGoodsSearchResults = useSelector(selectJCargoDangerousGoodsSearchResults);
  const kCargoDangerousGoodsSearchResults = useSelector(selectKCargoDangerousGoodsSearchResults);
  
  const [initialValues, setInitialValues] = useState(annexFive || {});
  const [jCargoDangerousGoodsOptions, setJCargoDangerousGoodsOptions] = useState([]);
  const [kCargoDangerousGoodsOptions, setKCargoDangerousGoodsOptions] = useState([]);

  useEffect(() => {
    setInitialValues(annexFive || {});
  }, [props.disableWasteDelivered, annexFive]);

  const handleJCargoDangerousGoodsUnChanged = _.debounce((value) => {
    if (value) {
      dispatch(searchDangerousGoodsJCargo({
        un: value
      }));
    };
  }, 500);

  const handleJCargoResiduesDangerousGoodsUnChangeDebounced = (value) => {
    if (handleJCargoDangerousGoodsUnChanged.cancel) {
      handleJCargoDangerousGoodsUnChanged.cancel(); // If there is an existing debounce, cancel it
    }

    handleJCargoDangerousGoodsUnChanged(value); // Start a new debounce with the latest value
  };

  const handleKCargoDangerousGoodsUnChanged = _.debounce((value) => {
    if (value) {
      dispatch(searchDangerousGoodsKCargo({
        un: value
      }));
    };
  }, 500);

  const handleKCargoResiduesDangerousGoodsUnChangeDebounced = (value) => {
    if (value) {
      dispatch(searchDangerousGoodsKCargo({
        un: value
      }));
    };
    if (handleKCargoDangerousGoodsUnChanged.cancel) {
      handleKCargoDangerousGoodsUnChanged.cancel(); // If there is an existing debounce, cancel it
    }

    handleKCargoDangerousGoodsUnChanged(value); // Start a new debounce with the latest value
  };

  useEffect(()=>{
    const values = props.formRef?.current?.values;
    if(!values) return;
  
    let options = [{label: '-', value: ''}];
    if(values?.cargoResiduesNonHME?.name){
      options.push({
        label: values?.cargoResiduesNonHME?.name,
        value: values?.cargoResiduesNonHME?.name
      });
    };
    if(jCargoDangerousGoodsSearchResults){
      options.push(...jCargoDangerousGoodsSearchResults);
    };

    setJCargoDangerousGoodsOptions(options);
  },[jCargoDangerousGoodsSearchResults]);
  
  useEffect(()=>{
    const values = props.formRef?.current?.values;
    if(!values) return;
  
    let options = [{label: '-', value: ''}];
    if(values?.cargoResiduesHME?.name){
      options.push({
        label: values?.cargoResiduesHME?.name,
        value: values?.cargoResiduesHME?.name
      });
    };
    if(kCargoDangerousGoodsSearchResults){
      options.push(...kCargoDangerousGoodsSearchResults);
    };

    setKCargoDangerousGoodsOptions(options);
  },[kCargoDangerousGoodsSearchResults]);

  return (
    <div className="marpol-table" style={{ display: props.isDisplayed ? "flex" : "none" }}>
      <WasteHeader />
      <Formik
        innerRef={props.formRef}
        onSubmit={emptyHandleSubmit}
        initialValues={initialValues}
        enableReinitialize>
        {({values}) => (
          <Form className="cargo-information-form">
            <div className="marpol-table__body">
              <WasteRow wasteName="A - Plastics" imoWasteCode="plastics" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.plastics } />
              <WasteRow wasteName="B - Food waste" imoWasteCode="foodWastes" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.foodWastes } />
              <WasteRow wasteName="C - Domestic waste" imoWasteCode="domesticWastes" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.domesticWastes } />
              <WasteRow wasteName="D - Cooking Oil" imoWasteCode="cookingOil" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.cookingOil } />
              <WasteRow wasteName="E - Incinerator ashes" imoWasteCode="incineratorAshes" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.incineratorAshes } />
              <WasteRow wasteName="F - Operational waste" imoWasteCode="operationalWastes" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.operationalWastes } />
              <WasteRow wasteName="G - Animal carcasses" imoWasteCode="animalCarcasses" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.animalCarcasses } />
              <WasteRow wasteName="H - Fishing gear" imoWasteCode="fishingGear" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.fishingGear } />
              <WasteRow wasteName="I - E-waste" imoWasteCode="eWaste" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.eWaste } />
              <WasteRow 
                wasteName="J – Cargo residues (non-HME)"
                imoWasteCode="cargoResiduesNonHME"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                additionalComponent={
                  <MultiSelect
                    name="cargoResiduesNonHME.name"
                    options={jCargoDangerousGoodsOptions}
                    isMulti={false}
                    onInputChange={handleJCargoResiduesDangerousGoodsUnChangeDebounced}
                    label=""
                    placeholder="Select"
                  />
                }
                { ...values?.cargoResiduesNonHME }
              />
              <WasteRow 
                wasteName="K - Cargo residues (HME)"
                imoWasteCode="cargoResiduesHME"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                additionalComponent={
                  <MultiSelect
                    name="cargoResiduesHME.name"
                    options={kCargoDangerousGoodsOptions}
                    onInputChange={handleKCargoResiduesDangerousGoodsUnChangeDebounced}
                    isMulti={false}
                    label=""
                    placeholder="Select"
                  />
                }
                { ...values?.cargoResiduesHME }
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
