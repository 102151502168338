import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import FlexibleInput from '../../../../forms/components/FlexibleInput';
import Input from '../../../../forms/components/Input';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import { getPortByUnlocode } from '../../../../global/utils/ports';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectSolas, updateIsps } from '../../../slices/portCallSlice';
import { isEmpty, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import '../styles/solas-questions.scss';

const validationSchema = Yup.object({
  isscOptions: Yup.string(),
  sspOptions: Yup.string(),
  shipSecurityLevelOptions: Yup.string(),
  hasValidISSC: Yup.boolean(),
  CSOName: Yup.string().required('This field is required'),
  contactDetails24h: Yup.string().required('This field is required').max(25, 'Please, maximum 25 characters required'),
  certificateIssuer: Yup.string()
    .nullable()
    .when('hasValidISSC', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
  certificateNumber: Yup.string()
    .nullable()
    .when('hasValidISSC', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
  certificateExpiryDate: Yup.string()
    .nullable()
    .when('hasValidISSC', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
});

const isscOptions = booleanOptionsForRadioGroup();
const sspOptions = booleanOptionsForRadioGroup();

const shipSecurityLevelOptions = [
  { key: 'Level 1', value: 'LEVEL_1' },
  { key: 'Level 2', value: 'LEVEL_2' },
  { key: 'Level 3', value: 'LEVEL_3' },
];

const locationOptions = {
  at_sea: { key: 'At sea', value: 'AT_SEA' },
  at_port: { key: 'At port', value: 'AT_PORT' },
};

const locationOptionsToArr = () => {
  const arr = [];
  Object.keys(locationOptions).forEach((key) => {
    arr.push(locationOptions[key]);
  });
  return arr;
};

function SolasQuestions() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isps = useSelector(selectSolas);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, isps]);

  const [initialValues, setInitialValues] = useState({
    ...isps.data,
    certificateExpiryDate: isps.data.certificateExpiryDate ? new Date(isps.data.certificateExpiryDate) : null,
  });

  useEffect(() => {
    const fetchData = async () => {
      let relatedPort = {};
      try {
        if (isps?.data?.shipSecurityLevelLocation) {
          relatedPort = await getPortByUnlocode({
            ports: isps?.relations?.ports,
            unlocode: isps?.data?.shipSecurityLevelLocation,
          });
        }
      } catch (e) {
        console.error('Port is undefined');
      }
      setInitialValues({
        ...isps.data,
        certificateExpiryDate: isps.data.certificateExpiryDate ? new Date(isps.data.certificateExpiryDate) : null,
        locationRadio: isps.data.shipSecurityLevelLocation
          ? locationOptions.at_port.value
          : locationOptions.at_sea.value,
        dropdownShipSecurityLevelLocation: relatedPort,
      });
    };
    if (Object.keys(isps.data).length > 0) fetchData();
  }, [isps.data]);

  const isLocationAtSea = (locationOptionValue) => locationOptionValue === locationOptions.at_sea.value;

  const handleLocationChange = (value, values) => {
    setInitialValues({
      ...values,
      locationRadio: isLocationAtSea(value) ? locationOptions.at_sea.value : locationOptions.at_port.value,
    });
  };

  const handleSubmit = async (values) => {
    let finalValues = {
      ...values,
      sspNoReason: values.hasApprovedSSP ? null : values.sspNoReason,
      certificateExpiryDate: values.certificateExpiryDate ? values.certificateExpiryDate.toISOString() : undefined,
    };

    if (isLocationAtSea(values.locationRadio)) {
      finalValues.shipSecurityLevelLocation = null;
    } else {
      finalValues.shipSecurityLevelSeaLocation = null;
      if (!finalValues.shipSecurityLevelLocation)
        finalValues.shipSecurityLevelLocation = finalValues.dropdownShipSecurityLevelLocation;
    }

    if (!values.hasValidISSC) {
      finalValues.certificateIssuer = undefined;
      finalValues.certificateNumber = undefined;
      finalValues.certificateExpiryDate = undefined;
    }

    delete finalValues.dropdownShipSecurityLevelLocation;

    await dispatch(
      updateIsps({
        ispsData: { ...finalValues },
        uid: params.uid,
      })
    );
  };

  const validation = async (values) => {
    try {
      const res = await validationSchema.validate(values, { abortEarly: false });
      navigate(`/${params.uid}/solas-and-isps-code/port-calls`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='solas-questions'>
      <PageHeader title={'SOLAS and ISPS code'} subtitle={`Provide the Chief Security Officer contact information:`} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values, setValues, errors }) => (
          <Form className='solas-questions-form'>
            <div className='solas-questions-form__row'>
              <Input
                name='CSOName'
                placeholder='Name'
                label='Full name *'
                hasError={isEmpty(values?.CSOName)}
                errorMessage={MandatoryFieldMessages.RequiredField}
              />
              <Input
                name='contactDetails24h'
                placeholder='Contact details'
                label='24 hrs contact details *'
                hasError={isEmpty(values?.contactDetails24h)}
                errorMessage={MandatoryFieldMessages.RequiredField}
              />
            </div>
            <div className='solas-questions-form__row'>
              <RadioButtonsCustom
                name='hasValidISSC'
                label='Does the ship have a valid International Ship Security Certificate (ISSC)?'
                options={isscOptions}
              />
            </div>
            {values.hasValidISSC && (
              <div className='solas-questions-form__row'>
                <Input
                  name='certificateIssuer'
                  placeholder='Type one'
                  label='Issued by (name of Administr. or RSO) *'
                  hasError={isEmpty(values?.certificateIssuer)}
                  errorMessage={MandatoryFieldMessages.RequiredField}
                />
                <Input
                  name='certificateNumber'
                  placeholder='Certificate number'
                  label='Certificate number *'
                  hasError={isEmpty(values?.certificateNumber)}
                  errorMessage={MandatoryFieldMessages.RequiredField}
                />
                <Field label='Expiry date *' className='field_date_picker'>
                  <DatePickerTimeInput
                    key={initialValues?.certificateExpiryDate + 'certificateExpiryDate'}
                    name='certificateExpiryDate'
                    sizeInput='large'
                    minDate={new Date()}
                    showTime={false}
                    defaultValue={initialValues?.certificateExpiryDate}
                    action={({ isoDate }) => setValues({ ...values, certificateExpiryDate: new Date(isoDate) })}
                    showBlueError={true}
                    hasError={isEmpty(values?.certificateExpiryDate)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                </Field>
              </div>
            )}
            {!values.hasValidISSC && (
              <div className='solas-questions-form__row'>
                <Input name='noISSCReason' placeholder='Why not?' label='Why not?' />
              </div>
            )}
            <div className='solas-questions-form__row'>
              <RadioButtonsCustom
                name='hasApprovedSSP'
                label='Does the ship have an approved SSP on board?'
                options={sspOptions}
              />
            </div>
            {!values.hasApprovedSSP && (
              <div className='solas-questions-form__row'>
                <Input name='sspNoReason' placeholder='Why not?' label='Why not?' />
              </div>
            )}
            <div className='solas-questions-form__row'>
              <RadioButtonsCustom
                name='shipSecurityLevel'
                label='Ship Security Level'
                options={shipSecurityLevelOptions}
              />
            </div>
            <div className='solas-questions-form__row'>
              <div className='dependant-input'>
                <RadioButtonsCustom
                  name='locationRadio'
                  label='Indicate the location of the vessel at the moment of declaring the Security Level:'
                  options={locationOptionsToArr()}
                  onOptionClick={(value) => handleLocationChange(value, values)}
                />
                {initialValues.locationRadio != null && (
                  <FlexibleInput
                    inputName='shipSecurityLevelSeaLocation'
                    dropdownName='dropdownShipSecurityLevelLocation'
                    placeholder='Location'
                    isTextInput={isLocationAtSea(values.locationRadio)}
                    dropdownID='portLocationDropdown'
                    getSpecificValue='unlocode'
                  />
                )}
              </div>
            </div>

            <SkipAndNextButtons
              next={`/${params.uid}/solas-and-isps-code/port-calls`}
              back={`/${params.uid}/dangerous-goods`}
              onClick={() => validation(values)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SolasQuestions;
