import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstance} from '../../../../../config/axios';
import auth from '../../../../../config/auth';
import { groupByAnnex } from '../utils/waste';
import { getRelatedPortByUnlocode } from '../../../../global/utils/ports';
import { ANNEXES_KEYS } from '../constants/annexes';

export const getDeliveryWaste = createAsyncThunk(
    'portCall/getDeliveryWaste',
    async (uid) => {
        const headers = auth.getHeaders();
        let response = await axiosInstance.get(`/portcalls/${uid}/delivery-waste`, {headers});

        return response.data;
    }
);


export const updateDeliveryWaste = createAsyncThunk(
    'portCall/updateDeliveryWaste',
    async ({
               marpolData,
               uid
           }) => {
        const headers = auth.getHeaders();
        await axiosInstance.put(`/portcalls/${uid}/delivery-waste`, {
            ...marpolData
        }, {headers});

        return marpolData;
    }
);


export const searchDangerousGoodsJCargo = createAsyncThunk(
    'marpol/searchDangerousGoodsJCargo',
    async ({un}) => {
        const headers = auth.getHeaders();
        let response = await axiosInstance.get(`/dangerous-goods/?un=${un}`, {
            headers
        });

        return response.data.data.map((dangerousGood) => {
            return {
                value: dangerousGood.name,
                label: `UN: ${dangerousGood.un}, ${dangerousGood.name}, packingGroup: ${dangerousGood.packingGroup || "-"}`,
                data: dangerousGood
            }
        })
    }
);

export const searchDangerousGoodsKCargo = createAsyncThunk(
    'marpol/searchDangerousGoodsKCargo',
    async ({un}) => {
        const headers = auth.getHeaders();
        let response = await axiosInstance.get(`/dangerous-goods/?un=${un}`, {
            headers
        });

        // mapping into format that dropdown expects
        return response.data.data.map((dangerousGood) => {
            return {
                value: dangerousGood.name,
                label: `UN: ${dangerousGood.un}, ${dangerousGood.name}, packingGroup: ${dangerousGood.packingGroup || "-"}`,
                data: dangerousGood
            }
        })
    }
);


const initialState = {
    wasteToDischarge: null,
    marpolWasteData: {},
    waste: [],
}

export const marpol = createSlice({
    name: 'portCall',
    initialState,
    reducers: {
        setSelectedAnnexes: (state, action) => {
            state.annexes = action.payload.selectedAnnexes;
        },
        setWillDischargeWaste: (state, action) => {
            state.wasteToDischarge = action.payload.wasteToDischarge;
        },
        setExemptionForDeliveringWaste: (state, action) => {
            state.marpolWasteData.exemptionForDeliveringWaste = action.payload.exemptionForDeliveringWaste;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeliveryWaste.fulfilled, (state, action) => {
                const { relations, data } = action.payload;

                if (data?.wasteToDischarge !== undefined) {
                    state.wasteToDischarge = data.wasteToDischarge;
                }

                if (data?.marpolWasteData) {
                    state.marpolWasteData = data.marpolWasteData;
                }
                
                if (data?.marpolWasteData && data?.marpolWasteData?.lastPortDischarge) {
                    const relatedPort = getRelatedPortByUnlocode({ports: relations?.ports, unlocode: data.marpolWasteData.lastPortDischarge}) 
                    if (relatedPort) {
                        data.marpolWasteData.lastPortDischargeDropdown = relatedPort;
                    }
                }
                if (data?.marpolWasteData && data?.marpolWasteData.nextPortWasteGenerated) {
                    const relatedPort = getRelatedPortByUnlocode({ports: relations?.ports, unlocode: data.marpolWasteData.nextPortWasteGenerated}) 
                    if (relatedPort) {
                        data.marpolWasteData.nextPortWasteGeneratedDropdown = relatedPort;
                    }
                }
                state.wasteDisposalApproach = data?.wasteDisposalApproach;

                if (data?.waste){
                    data?.waste.map((item) => {
                        const relatedPort = getRelatedPortByUnlocode({ports: relations?.ports, unlocode: item.nextPortToDeliver}) 
                        if (relatedPort) {
                            item.dropdownPort = relatedPort
                        }
                    });
                }

                state.waste = data?.waste ?? [];
            })
            .addCase(updateDeliveryWaste.fulfilled, (state, action) => {
                if (action.payload.wasteDisposalApproach) {
                    state.wasteDisposalApproach = action.payload.wasteDisposalApproach;
                }
                if (action.payload.wasteToDischarge) {
                    state.marpolWasteData = {...action.payload.marpolWasteData}
                }
                if (action.payload.waste) {
                    state.waste = action.payload.waste
                }
            })
            .addCase(searchDangerousGoodsJCargo.pending, (state, _) => {
                state.jCargoDangerousGoodsSearchResults = [];
            })
            .addCase(searchDangerousGoodsJCargo.fulfilled, (state, action) => {
                state.jCargoDangerousGoodsSearchResults = action.payload;
            })
            .addCase(searchDangerousGoodsKCargo.pending, (state, _) => {
                state.kCargoDangerousGoodsSearchResults = [];
            })
            .addCase(searchDangerousGoodsKCargo.fulfilled, (state, action) => {
                state.kCargoDangerousGoodsSearchResults = action.payload;
            })
    },
});

export const {
    setWillDischargeWaste,
    setExemptionForDeliveringWaste,
    setSelectedAnnexes,
} = marpol.actions;

export const selectMarpol = (state) => state.marpol;
export const selectSelectedAnnexes = (state) => {
    if (!state?.marpol?.wasteToDischarge) return [];

    const wasteItemsWithWasteToBeDelivered = state?.marpol?.waste.filter(wasteItem => {
        return wasteItem.wasteToBeDelivered;
    })
    return Object.keys(groupByAnnex(wasteItemsWithWasteToBeDelivered));
};
export const selectWaste = (state) => state.marpol.waste;
export const selectGroupedWaste = (state) => groupByAnnex(state.marpol.waste);
export const selectAnnexOne = (state) => groupByAnnex(state.marpol.waste)[ANNEXES_KEYS.ANNEX_ONE];
export const selectAnnexTwo = (state) => groupByAnnex(state.marpol.waste)[ANNEXES_KEYS.ANNEX_TWO];
export const selectAnnexFour = (state) => groupByAnnex(state.marpol.waste)[ANNEXES_KEYS.ANNEX_FOUR];
export const selectAnnexFive = (state) => groupByAnnex(state.marpol.waste)[ANNEXES_KEYS.ANNEX_FIVE];
export const selectAnnexSix = (state) => groupByAnnex(state.marpol.waste)[ANNEXES_KEYS.ANNEX_SIX];
export const selectOtherWaste = (state) => groupByAnnex(state.marpol.waste)[ANNEXES_KEYS.OTHER_ANNEX];
export const selectLastWaste = (state) => state.marpol.lastWaste;
export const selectJCargoDangerousGoodsSearchResults = (state) => state.marpol.jCargoDangerousGoodsSearchResults
export const selectKCargoDangerousGoodsSearchResults = (state) => state.marpol.kCargoDangerousGoodsSearchResults

export default marpol.reducer;
