import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import '../../../../captains-form/styles/mandatory-check.scss';
import { formatDate } from '../../../../global/utils/datetimes';
import { MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import '../styles/general-declaration.scss';

const MasterSignature = ({ portCallData, title, subtitle, setSignature }) => {
  const signatureRef = useRef(null);
  const [signatureRequired, setSignatureRequired] = useState(true);

  useEffect(() => {
    if (portCallData?.masterSignature && signatureRef) {
      setSignatureRequired(false);
      signatureRef.current?.clear();
      signatureRef.current.fromDataURL(portCallData?.masterSignature);
    }
  }, [portCallData]);

  const onEndStrokeOnCanvas = () => {
    setSignature(signatureRef.current?.toDataURL());
  };

  const onClearSignature = () => {
    signatureRef.current?.clear();
    setSignatureRequired(true);
  };

  return (
    <>
      <label className={'masterSignatureTitle'}>{title}</label>
      <p className={'masterSignatureSubtitle'}>{subtitle}</p>
      <div className={`canvasDiv ${signatureRequired ? 'input-error-border' : ''}`}>
        <SignatureCanvas
          ref={signatureRef}
          clearOnResize={false}
          onEnd={onEndStrokeOnCanvas}
          canvasProps={{ className: 'submitSignatureCanvas' }}
        />
        <button type={'button'} onClick={onClearSignature}>
          Clear
        </button>
      </div>
      {!signatureRequired && portCallData?.masterSignature && portCallData?.masterSignatureTimestamp && (
        <p>{`Signature date: ${formatDate(portCallData?.masterSignatureTimestamp)}`}</p>
      )}
      {signatureRequired && <div className={'validationCheck'}>{MandatoryFieldMessages.RequiredField}</div>}
    </>
  );
};

export default MasterSignature;
