
export const CheckCircleIcon = (props: any)=> {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        fill={props.fill}
        viewBox="0 0 24 25"
        preserveAspectRatio="xMidYMid meet"
        stroke={props.stroke}
        {...props}
    >
        <path
            fill="inherit"
            fillRule="evenodd"
            d="M2.25 12.365c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75-9.75-4.365-9.75-9.75Zm10.5-5a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0v-6Zm-.75 9.25a.75.75 0 0 0 0 1.5h.009a.75.75 0 0 0 0-1.5H12Z"
            clipRule="evenodd"
        />
    </svg>
}
