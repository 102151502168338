import React from 'react';
import deleteIcon from "../../../../tables/styles/images/deleteIcon.svg";
import Modal from "../../../../layout/components/Modal";
import Button from '../../../../layout/components/Button';
import "../styles/confirm-delete-modal.scss";


function ConfirmDeleteModal({
  show, title, onCloseModal, onCancelClick, onDeleteClick,
}) {

  return (
    <Modal
      className={`confirm-delete-modal`}
      showModal={show}
      hideCloseBtn
      onCloseModal={() => { onCloseModal() }}>
      <div className="confirm-delete-modal__title">
        <img src={deleteIcon} alt="deleteIcon" />
        {title}
      </div>
      <div className="confirm-delete-modal__btns">
        <div className="cancel-btn" onClick={() => {
          onCancelClick();
        }}>Cancel</div>
        <Button onClick={() => {
          onDeleteClick();
        }}>Delete</Button>
      </div>
    </Modal>
  );
}

export default ConfirmDeleteModal;
