import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../config/axios';
import auth from '../../../config/auth';

const initialState = {
  companies: []
};

export const getCompanies = createAsyncThunk(
  'company/getCompanies',
  async () => {
    const headers = auth.getHeaders();
    const shipOwnerType = 'ship_owner'
    let response = await axiosInstance.get(`/companies?companyType=${shipOwnerType}`, {
      headers
    });
  
    return response.data.data;
  }
);

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
      })
  },
});

export const selectCompanies = (state) => state.companies.companies;

export default companySlice.reducer;
