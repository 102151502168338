import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  selectDangerousGoods,
  updateDangerousGoods,
  searchDangerousGoods,
  closeDangrousGoodsDropdown,
  showDangerousGoodsModal,
} from '../../../slices/portCallSlice';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import Table from '../../../../tables/components/Table';
import Button from '../../../../layout/components/Button';
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import chevronRight from "../../../../tables/styles/images/chevronRight.svg";
import chevronLeft from "../../../../tables/styles/images/chevronLeft.svg";
import Modal from "../../../../layout/components/Modal";
import Input from '../../../../forms/components/Input';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import MultiSelect from '../../../../forms/components/MultiSelect';
import "../styles/dangerous-goods-table.scss";
import {
  initialFormDangerousGoodsValues,
  StowageLevelList,
  StowagePositionList,
  TableColumns,
  TypeOfCargoList
} from "./config";
import ProgressBar from "../../../../layout/components/ProgressBar";
import {countryListAllIsoData} from "../../../../global/utils/countriesList";
import {removeNullUndefined} from "../../../../global/utils/cleanEmptyData";
import {validateSchema} from "../../../../global/utils/validateForms";
import ConfirmationModal from "../../../../layout/components/ConfirmationModal";
import SearchPortsDropdown from "../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown";
import {searchForPort} from "../../health/slices/portCallSlice";
import {
  searchPlaceOfBirth,
  selectPlaceOfBirthSearchResults
} from "../../../../forms/components/place-of-birth/slices/placeOfBirthSlice";

const marinePollutantOptions = booleanOptionsForRadioGroup();


function DangerousGoodsTable() {
  const dispatch = useDispatch();
  const dangerousGoods = useSelector(selectDangerousGoods);
  const shouldShowDangerousGoodsDropdown = dangerousGoods.ui.showDropdown;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [stepForm, setStepForm] = useState(1);
  const [isTanker, setIsTanker] = useState(false);
  const [initialValues, setInitialValues] = useState(initialFormDangerousGoodsValues);
  const [shipperCitiesOptions, setShipperCitiesOptions] = useState([]);
  const [receiverCitiesOptions, setReceiverCitiesOptions] = useState([]);
  const shipperCitiesResults = useSelector((state) => selectPlaceOfBirthSearchResults(state, "shipperCity")) || [];
  const receiverCitiesResults = useSelector((state) => selectPlaceOfBirthSearchResults(state, "receiverCity")) || [];


  const handleCitiesUnChanged = _.debounce((value, country, name) => {
    if (value) {
      dispatch(searchPlaceOfBirth({
        dropdownID: name,
        value: value,
        countryCode: country
      }));
    };
  }, 500);

  const handleCitiesUnChangeDebounced = (value, country, name) => {
    if (value) {
      dispatch(searchPlaceOfBirth({
        dropdownID: name,
        value: value,
        countryCode: country
      }));
    };
    if (handleCitiesUnChanged.cancel) {
      handleCitiesUnChanged.cancel(); // If there is an existing debounce, cancel it
    }

    handleCitiesUnChanged(value, country); // Start a new debounce with the latest value
  };

  useEffect(()=>{
    if (shipperCitiesResults?.length<1) return

    let options = [{label: '-', value: ''}];
    if(shipperCitiesResults){
      options.push(...shipperCitiesResults);
    }

    setShipperCitiesOptions(options);

  },[shipperCitiesResults]);

  useEffect(()=>{
    if (receiverCitiesResults?.length<1) return

    let options = [{label: '-', value: ''}];
    if(receiverCitiesResults){
      options.push(...receiverCitiesResults);
    }

    setReceiverCitiesOptions(options);
  },[receiverCitiesResults]);

  useEffect(() => {
    if (!isModalOpen)
    {
      setReceiverCitiesOptions([])
      setShipperCitiesOptions([])
    }
  }, [isModalOpen]);

  const handleDangerousGoodsUnChanged = _.debounce((value) => {
    if (value) {
      dispatch(searchDangerousGoods({
        un: value
      }));
    }
  }, 500);

  const handleDangerousGoodsUnChangeDebounced = ({ target: { value } }) => {
    if (handleDangerousGoodsUnChanged.cancel) {
      handleDangerousGoodsUnChanged.cancel(); // If there is an existing debounce, cancel it
    }

    handleDangerousGoodsUnChanged(value); // Start a new debounce with the latest value
  };

  const dangerousGoodsSearchResults = dangerousGoods.dangerousGoodsSearchResults;
  const shouldShowLoadingInDangerousGoodsDropdown = dangerousGoods.dangerousGoodsSearchResultsLoading;
  const dangerousGoodsDropdownOptions = dangerousGoodsSearchResults;

  const validationSchema = Yup.object({
    typeOfCargo: Yup.string().required('*This field is required'),
    un: Yup.string().required('*This field is required'),
    properShippingName: Yup.string().required('*This field is required'),
    class: Yup.string().required('*This field is required'),
    marinePollutant: Yup.string().required('*This field is required'),
    netWeight: Yup.string().required('*This field is required'),
    ems: Yup.string()
      .matches(/^F-[A-J]{1}S-[A-Z]{1}$/, 'Invalid format').nullable(),
    loadPort: Yup.string().required('*This field is required'),
    dischargePort: Yup.string().required('*This field is required'),
    receiverName: Yup.string().required('*This field is required'),
    receiverCountry: Yup.string().required('*This field is required'),
    receiverCity: Yup.string().required('*This field is required'),
    receiverAddress: Yup.string().required('*This field is required'),
    receiverVatNumber: Yup.string().required('*This field is required'),
    receiverPhoneNumber: Yup.string().required('*This field is required'),
    shipperName: Yup.string().required('*This field is required'),
    shipperCountry: Yup.string().required('*This field is required'),
    shipperCity: Yup.string().required('*This field is required'),
    shipperAddress: Yup.string().required('*This field is required'),
    shipperVatNumber: Yup.string().required('*This field is required'),
    shipperPhoneNumber: Yup.string().required('*This field is required'),
  })

  const formatFormValuesForApi = (values)=>{
    return removeNullUndefined({
      ...values,
      shipperName: null,
      shipperAddress: null,
      shipperCountry: null,
      shipperPhoneNumber: null,
      shipperVatNumber: null,
      shipperCity: null,
      receiverCity: null,
      receiverName: null,
      receiverAddress: null,
      receiverCountry: null,
      receiverPhoneNumber: null,
      receiverVatNumber: null,
      tankHoldNumber: null,
      un: values?.un,
      packingGroup: values?.packingGroup,
      class: values?.class,
      loadPort: values?.loadPort?.unlocode || values?.loadPort?.value || values?.loadPort ,
      dischargePort: values?.dischargePort?.unlocode || values?.dischargePort?.value || values?.dischargePort,
      properShippingName: values?.properShippingName,
      stowagePositionHold:
          values?.typeOfCargo !== "Liquid bulk"
              ? values?.tankHoldNumber
              : null,
      stowageTankerNumber:
          values?.typeOfCargo === "Liquid bulk"
              ? values?.tankHoldNumber
              : null,
      stowagePosition:
          values?.typeOfCargo !== "Liquid bulk"
              ? values?.stowagePosition
              : null,
      stowageLevel:
          values?.typeOfCargo !== "Liquid bulk"
              ? values?.stowageLevel
              : null,
      shipper: {
        companyName: values?.shipperName,
        address: values?.shipperAddress,
        country: values?.shipperCountry || values?.shipperCity?.value?.slice(0, 2) || null,
        city: values?.shipperCity?.value ? values?.shipperCity?.value : values?.shipperCity,
        telephone: values?.shipperPhoneNumber,
        vatNumber: values?.shipperVatNumber,
      },
      receiver: {
        companyName: values?.receiverName,
        address: values?.receiverAddress,
        country: values?.receiverCountry || values?.receiverCountry?.value?.slice(0, 2) || null,
        city: values?.receiverCity?.value ? values?.receiverCity?.value : values?.receiverCity,
        telephone: values?.receiverPhoneNumber,
        vatNumber: values?.receiverVatNumber,
      },
    })

  }

  const formateFormValuesFromApi = async (values)=>{
    const loadPort = values.loadPort? await dispatch(searchForPort({unlocode: values.loadPort})): null
    const dischargePort =values.dischargePort? await dispatch(searchForPort({unlocode: values.dischargePort})):null
    const shipperCity = values?.shipper?.city ? await dispatch(searchPlaceOfBirth({location: values?.shipper?.city.slice(2), countryCode: values?.shipper?.city.slice(0, 2)})):null
    const receiverCity = values?.receiver?.city ? await dispatch(searchPlaceOfBirth({location: values?.receiver?.city.slice(2), countryCode: values?.receiver?.city.slice(0, 2)})):null


    return removeNullUndefined({
      ...values,
      receiverName: values?.receiver?.companyName,
      receiverCountry: countryListAllIsoData?.find(el=>el?.value===values?.receiver?.country)?.value || null ,
      receiverCity: receiverCity?.payload?.data[0] || null,
      receiverAddress: values?.receiver?.address,
      receiverVatNumber: values?.receiver?.vatNumber,
      receiverPhoneNumber: values?.receiver?.telephone,
      shipperName: values?.shipper?.companyName,
      shipperCountry: countryListAllIsoData?.find(el=>el?.value ===values?.shipper?.country)?.value || null,
      shipperCity: shipperCity?.payload?.data[0] || null,
      shipperAddress: values?.shipper?.address,
      shipperVatNumber: values?.shipper?.vatNumber,
      shipperPhoneNumber: values?.shipper?.telephone,
      tankHoldNumber: values?.stowageTankerNumber || values?.stowagePositionHold,
      typeOfCargo: TypeOfCargoList?.find(el=> el.value === values?.typeOfCargo)?.value|| null,
      stowagePosition: StowagePositionList?.find(el=> el.value === values?.stowagePosition)?.value|| null,
      stowageLevel: StowageLevelList?.find(el=> el.value === values?.stowageLevel)?.value|| null,
      grossMass: values?.massOrVolume?.split(" ")[0] || null,
      netVolume: values?.massOrVolume?.split(" ")[1] || null,
      receiver: undefined,
      shipper: undefined,
      loadPort: loadPort?.payload[0] || null,
      dischargePort:dischargePort?.payload[0] || null
    })
  }

  const handleSubmit = async (values, _) => {
    const formatedValues = formatFormValuesForApi(values)
    if (isEditingRow) {
      const newMembers = dangerousGoods.dangerousGoodsList.map((member, i) => {
        if (i === editingRow) {
          return formatedValues
        }
        return removeNullUndefined(member);
      })

      dispatch(updateDangerousGoods({
        dangerousGoods: newMembers
      }));
    } else {
      dispatch(updateDangerousGoods({
        dangerousGoods: dangerousGoods.dangerousGoodsList.map(element=>removeNullUndefined(element)).concat(formatedValues)
      }));
    }
    setEditingRow(null);
    setIsModalOpen(false);
    setInitialValues(initialFormDangerousGoodsValues)
    setTimeout(()=>
            setStepForm(1)
        ,400)
  };

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  let isEditingRow = editingRow !== null;

  useEffect(() => {
    isEditingRow = editingRow !== null;
  const row = isEditingRow ? dangerousGoods.dangerousGoodsList[editingRow] : null;
  if (row) formateFormValuesFromApi(row).then((result)=>{
    setIsTanker(result?.typeOfCargo === "Liquid bulk")
    setInitialValues(result)})
  }, [editingRow]);



  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);

  const handleDangerousGoodClicked = function (dangerousGood) {
    dispatch(closeDangrousGoodsDropdown())

    setInitialValues({
      ...initialValues,
      class: dangerousGood?.data?.class || "",
      un: dangerousGood?.data?.un || "",
      properShippingName: dangerousGood?.data?.name || "",
      packingGroup: dangerousGood?.data?.packingGroup || ""
    })
  }

  const convertDataForTable = (data)=>{
    return data?.map((el, _) => ({
      ...el,
      stowagePosition:
          StowagePositionList.find(
              (element) => element.value === el.stowagePosition
          )?.label || "",
      subsidiaryRisk: `${el?.marinePollutant ? "P" : ""} ${
          el?.subsidiaryRisk  || ""
      } ${el?.subsidiaryRisk2  || ""} ${el?.subsidiaryRisk3 || ""}`,
      massOrVolume: `${el?.netWeight ? "M:" + el?.netWeight : ""} ${
          el?.volume ? "V:" + el?.volume : ""
      }`,
      bookingNumber: `${el?.bookingNumber ? el?.bookingNumber : ""} ${
          el?.billOfLadingNumber ? el?.billOfLadingNumber : ""
      }`,
    })) || []
  }

  return (
    <div className="dangerous-goods-table">
      <Table
        title={'Dangerous goods manifest'}
        addButtonText={'Add dangerous goods'}
        data={convertDataForTable(dangerousGoods.dangerousGoodsList)}
        columns={TableColumns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex
          })
        }}
      />
      <Modal
        className={`add-dangerous-good-modal`}
        title={"Dangerous goods manifest"}
        onClick={() => {
          dispatch(closeDangrousGoodsDropdown());
        }}
        showModal={isModalOpen}
        onCloseModal={() => {
          dispatch(closeDangrousGoodsDropdown());
          setIsModalOpen(false);
          setEditingRow(null);
          setInitialValues(initialFormDangerousGoodsValues)
          setTimeout(()=>
          setStepForm(1)
          ,400)
        }}>
        <ConfirmationModal
            showModal= {isConfirmationModalOpen}
            handleCloseModal= {setIsConfirmationModalOpen}
            willDisableSave= {formValues}
            onAcceptModal= {() => {
              setIsConfirmationModalOpen(false);
              setIsModalOpen(false);
              handleSubmit(formValues)
            }}
            className='' />
        <div className="add-dangerous-good-modal__content">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validate={(values) => {
              values = {...values, loadPort: values?.loadPort?.unlocode || values?.loadPort?.value || values?.loadPort, dischargePort: values?.dischargePort?.unlocode || values?.dischargePort?.value || values?.dischargePort, ems: values?.ems?values?.ems:null}
              const errors = validateSchema(values, validationSchema, setFormValues, setIsConfirmationModalOpen, setIsModalOpen)
              if (errors?.ems === "Invalid format") setStepForm(1)
              return errors
            }}>
            {({values})=>
              <Form className="add-dangerous-good-form">
              <ProgressBar percentage={stepForm/2*100} pageNumber={stepForm} totalPages={2} />
          {stepForm === 1 &&
              <>
                <div className="add-dangerous-good-form__row">
                  <MultiSelect
                      name="typeOfCargo"
                      options={TypeOfCargoList}
                      isMulti={false}
                      onChange={(value)=>
                          setIsTanker(value.value ==="Liquid bulk")
                      }
                      label="Type of cargo*"
                      placeholder="Select one"/>
                  <MultiSelect
                      name="stowagePosition"
                      options={StowagePositionList}
                      isMulti={false}
                      disabled={isTanker}
                      label="Stowage position"
                      placeholder="Select one"/>
                  <MultiSelect
                      name="stowageLevel"
                      options={StowageLevelList}
                      isMulti={false}
                      disabled={isTanker}
                      label="Stowage level"
                      placeholder="Select one"/>
                  <Input name="tankHoldNumber" placeholder="Ex: Tank or hold number" label="Tank or hold number" type={"number"} min={0}/>
                </div>
                <hr/>
                <div className="add-dangerous-good-form__row">
                  <div className="un-container">
                    <Input name="un"
                           onClick={(e) => {
                             if (dangerousGoodsSearchResults.length) {
                               e.stopPropagation()
                               dispatch(showDangerousGoodsModal());
                             }
                           }}
                           onChange={handleDangerousGoodsUnChangeDebounced}
                           placeholder="UN Number"
                           label="UN Number*"/>
                    {(shouldShowDangerousGoodsDropdown) && (
                        <div className="list">
                          {shouldShowLoadingInDangerousGoodsDropdown && "Loading.."}
                          {dangerousGoodsSearchResults.length === 0 && (
                              <span
                                  className="un__empty-case">Didn't find any dangerous goods with that UN code</span>
                          )}
                          {dangerousGoodsDropdownOptions.map((dangerousGood) => {
                            return (
                                <div className="list__item"
                                     onClick={() => handleDangerousGoodClicked(dangerousGood)}>{dangerousGood.label}</div>
                            )
                          })}
                        </div>
                    )}
                  </div>
                  <Input name="packingGroup" disabled={!values?.un}  label="Packing group" placeholder="Packing group" />
                  <Input name="properShippingName" disabled={!values?.un}
                         label="Proper shipping name / Techincal specifications*" placeholder="Proper shipping name / Techincal specifications"/>
                  <Input name="class" disabled={!values?.un} label="Class*" placeholder="Class"/>
                </div>
                <div className="add-dangerous-good-form__row">
                  <Input name="subsidiaryRisk" placeholder="Type One" label="Subsidiary Risk 1 (if any)"/>
                  <Input name="subsidiaryRisk2" placeholder="Type One" label="Subsidiary Risk 2 (if any)"/>
                  <RadioButtonsCustom
                      name="marinePollutant"
                      label="Marine Pollutant*"
                      options={marinePollutantOptions}/>
                  <Input name="flashPoint" placeholder="Flash Point (&deg;C)" label="Flash Point (&deg;C)" type={"number"} min={0}/>
                </div>
                <div className="add-dangerous-good-form__row">
                  <Input name="netWeight" placeholder="Net Mass (Kg)" label="Net Mass (Kg)*" type={"number"} min={0}/>
                  <Input name="grossWeight" placeholder="Gross mass (Kg)" label="Gross mass (Kg)" type={"number"} min={0}/>
                  <Input name="volume" placeholder="Net Volume (m3)" label="Net Volume (m3)" type={"number"} min={0}/>
                  <Input name="ems" placeholder="Ex: F-JS-K" label="EmS"/>
                </div>
                <div className="add-dangerous-good-form__row">
                  <Input name="specialProvisions" placeholder="Special Provisions" label="Special Provisions"/>
                  <Input name="packingInstructions" placeholder="Packing Instructions"
                         label="Packing Instructions"/>
                  <Input name="additionalInformation" placeholder="Addition information"
                         label="Addition information (if any)"/>
                </div>
                <div className="add-dangerous-good__form-submission-btns">
                  <Button className="discard-btn"  onClick={() => {
                    setIsModalOpen(false);
                    setTimeout(() => setEditingRow(null), 300)
                  }}>Discard
                  </Button>
                  <Button iconRight={chevronRight} onClick={()=>{
                    setStepForm(2)
                  }}>Next</Button>
                </div>
              </>
          }
          {stepForm===2 && <>
            <div className="add-dangerous-good-form__row2">
              <Input name="bookingNumber" placeholder="Booking number"
                     label="Booking number"/>
              <Input name="billOfLadingNumber" placeholder="B/L number"
                     label="B/L number"/>
              <div className="un-container">
                <SearchPortsDropdown
                    label="Port of loading*"
                    name="loadPort"
                    dropdownID="loadPort"
                    placeholder="Select One"
                />
              </div>
              <div className="un-container">
                <SearchPortsDropdown
                    label="Port of discharge*"
                    name="dischargePort"
                    dropdownID="dischargePort"
                    placeholder="Select One"
                />
              </div>
            </div>
            <hr/>
            <div className="add-dangerous-good-form__row2">
              <Input name="receiverName" placeholder="Receiver's name"
                     label="Receiver's name*"/>
              <MultiSelect
                  options={countryListAllIsoData}
                  isMulti={false}
                  name="receiverCountry"
                  placeholder="Receiver's country"
                  label="Receiver's country*"
              />
              <MultiSelect
                  name="receiverCity"
                  options={ receiverCitiesOptions}
                  onInputChange={(value)=>handleCitiesUnChangeDebounced(value, values.receiverCountry, "receiverCity")}
                  isMulti={false}
                  label="Receiver's City*"
                  placeholder="Select receiver's City"
              />
              <Input name="receiverAddress" placeholder="Receiver's address"
                     label="Receiver's address*"/>
              <Input name="receiverVatNumber" placeholder="Receiver's VAT number"
                     label="Receiver's VAT number*"/>
              <Input name="receiverPhoneNumber" placeholder="Receiver's phone number"
                     label="Receiver's phone number"/>
            </div>
            <hr/>
            <div className="add-dangerous-good-form__row2">
              <Input name="shipperName" placeholder="Shipper's name"
                     label="Shipper's name*"/>
              <MultiSelect
                  options={countryListAllIsoData}
                  isMulti={false}
                  name="shipperCountry"
                  placeholder="Shipper's country"
                  label="Shipper's country*"
              />
              <MultiSelect
                  name="shipperCity"
                  options={shipperCitiesOptions}
                  onInputChange={(value)=>handleCitiesUnChangeDebounced(value, values.shipperCountry, "shipperCity")}
                  isMulti={false}
                  label="Shipper's City*"
                  placeholder="Select shipper's City"
              />
              <Input name="shipperAddress" placeholder="Shipper's address"
                     label="Shipper's address*"/>
              <Input name="shipperVatNumber" placeholder="Shipper's VAT number"
                     label="Shipper's VAT number*"/>
              <Input name="shipperPhoneNumber" placeholder="Shipper's phone number"
                     label="Shipper's phone number*"/>
            </div>
            <div className="add-dangerous-good__form-submission-btns">
              <Button className="discard-btn" iconLeft={chevronLeft} onClick={() => {
                setStepForm(1)
              }}>Back
              </Button>
              {
                isEditingRow ? (
                    <Button type={"submit"}>Change Information</Button>
                ) : (
                    <Button iconLeft={plusIcon} type="submit">Add</Button>
                )
              }
            </div>
          </>}
        </Form>
            }

          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
          title={<h3>Are you sure you want to <span>delete all <br/>dangerous goods</span> from the list?</h3>}
          show={showConfirmDeleteAllMembersModal}
          onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
          onDeleteClick={() => {
            setShowConfirmDeleteAllMembersModal(false);
            dispatch(updateDangerousGoods({
              dangerousGoods: []
            }))
          }}
          onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
          title={<h3>Are you sure you want to delete this dangerous good?</h3>}
          show={confirmDeleteMemberModalData.showModal}
          onCancelClick={() => {
            setConfirmDeleteMemberModalData({
              index: null,
              showModal: false
            })
          }}
          onDeleteClick={() => {
            const newDangerousGoods = [...dangerousGoods.dangerousGoodsList];
          newDangerousGoods.splice(confirmDeleteMemberModalData.index, 1);
          dispatch(updateDangerousGoods({
            dangerousGoods: newDangerousGoods
          }))

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          })
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
    </div >
  );
}

export default DangerousGoodsTable;
