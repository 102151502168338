import React from 'react';
import AuthLayout from '../../layout/components/AuthLayout';
// import "../styles/signup.scss";

export default function AuthBox(props) {
  return (
    <AuthLayout>
      <div className="auth-page">
        <div className="auth-box">
          {props.children}
        </div>
      </div>
    </AuthLayout>
  );
}
