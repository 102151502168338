import SearchPortsDropdown from 'features/forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import { booleanOptionsForRadioGroup } from 'features/forms/helpers/random-form-helpers';
import { Port } from 'features/global/ports';
import Table, { DeleteModalData } from 'features/tables/components/Table';
import { Form, Formik, FormikProps } from "formik";
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import PageHeader from '../../../../layout/components/PageHeader';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { selectHealthDeclaration, updateHealthDeclaration } from '../slices/healthSlice';
import { searchForPort } from '../slices/portCallSlice';
import "../styles/health.scss";
import { lastPortInThirtyDaysColumns } from '../utils/healthUtils';
import PortModal from './PortModal';
// @ts-ignore
import { DatePickerTimeInput } from "@navozyme/uikit/dist/molecules/DatePicker";
// @ts-ignore
import { Field } from "@navozyme/uikit/dist/atoms/Field";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const FirstHealthStep = () => {
  const formRef = useRef<FormikProps<any>>(null);
  const { formContextRef } = useContext(FormRefContext);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const healthDeclaration = useSelector(selectHealthDeclaration);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditingTable, setIsEditingTable] = useState<boolean>(false);
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState<boolean>(false);
  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState<DeleteModalData>({
    index: null,
    showModal: false
  });

  const [placeOfIssuance, setPlaceOfIssuance] = useState<Port | null>(null);
  const [infectedAreaPort, setInfectedAreaPort] = useState<Port | null>(null);
  const [sanitationCertIssueDate, setSanitationCertIssueDate] = useState<Date | null>(null);

  const getDefaultValues = () => {
    return {
      ...healthDeclaration,
      issuingDate: healthDeclaration.issuingDate ? new Date(healthDeclaration.issuingDate) : null,
      expiringDate: healthDeclaration.expiringDate ? new Date(healthDeclaration.expiringDate) : null,
      infectedAreaDate: healthDeclaration.infectedAreaDate ? new Date(healthDeclaration.infectedAreaDate) : null,
      dropdownInfectedAreaPort: null,
      dropdownPlaceOfIssuance: null,
    }
  };

  const [initialValues, setInitialValues] = useState(getDefaultValues());

  useEffect(() => {
    setInitialValues(getDefaultValues());
  }, [healthDeclaration]);

  useEffect(() => {
    if (healthDeclaration.infectedAreaPort) {
      dispatch(searchForPort(
        { unlocode: healthDeclaration.infectedAreaPort }
      ) as any).then((response: any) => {
        if (response.payload.length > 0) {
          setInfectedAreaPort({
            ...response.payload[0]
          });
        }
      });
    }
  }, [healthDeclaration.infectedAreaPort]);

  useEffect(() => {
    if (healthDeclaration.placeOfIssuance) {
      dispatch(searchForPort(
        { unlocode: healthDeclaration.placeOfIssuance }
      ) as any).then((response: any) => {
        if (response.payload.length > 0) {
          setPlaceOfIssuance({
            ...response.payload[0]
          });
        }
      });
    }
  }, [healthDeclaration.placeOfIssuance]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      dropdownInfectedAreaPort: infectedAreaPort,
      dropdownPlaceOfIssuance: placeOfIssuance
    });
  }, [placeOfIssuance, infectedAreaPort]);

  const validationSchema = Yup.object({
    sanitationCertificate: Yup.boolean(),
    reinspectionRequired: Yup.boolean(),
    hasShipVisitedInfectedArea: Yup.boolean()
  });

  const isEditingRow = editingRow !== null;

  const handlePortChanges = (data: any) => {
    let ports = [...healthDeclaration.portsVisitedWithinWHO];

    if (isEditingRow) {
      ports[editingRow as number] = data;
    } else {
      ports.push(data);
    }

    dispatch(updateHealthDeclaration({
      uid: params.uid || '',
      healthData: {
        portsVisitedWithinWHO: ports
      }
    }) as any);
  };

  const handleSubmit = async (values: any) => {
    if (values.infectedAreaDate) values.infectedAreaDate = new Date(values.infectedAreaDate).toISOString().split('T')[0];
    if (values.issuingDate) values.issuingDate = new Date(values.issuingDate).toISOString();
    if (values.expiringDate) values.expiringDate = new Date(values.expiringDate).toISOString();
    if (values.dropdownPlaceOfIssuance instanceof Object)
      values.placeOfIssuance = values.dropdownPlaceOfIssuance.unlocode;
    if (values.dropdownInfectedAreaPort instanceof Object)
      values.infectedAreaPort = values.dropdownInfectedAreaPort.unlocode;

    let portsVisitedWithinWHOCopy = JSON.parse(JSON.stringify(values.portsVisitedWithinWHO));

    portsVisitedWithinWHOCopy.forEach((port: any) => {
      const newDateFrom = new Date(port.dateFrom);
      const newDateTo = new Date(port.dateTo);
      if (!isNaN(newDateFrom.getTime())) {
        port.dateFrom = newDateFrom.toISOString();
      }
      if (!isNaN(newDateTo.getTime())) {
        port.dateTo = newDateTo.toISOString();
      }
    });

    delete values.dropdownPlaceOfIssuance;
    delete values.dropdownInfectedAreaPort;
    values.portsVisitedWithinWHO = portsVisitedWithinWHOCopy;

    dispatch(updateHealthDeclaration({
      uid: params.uid || '',
      healthData: {
        ...values
      }
    }) as any);
  };

  const handleDeleteSingleMember = () => {
    const portsVisitedWithinWHO = [...healthDeclaration.portsVisitedWithinWHO];
    portsVisitedWithinWHO.splice(confirmDeleteMemberModalData.index as number, 1);

    dispatch(updateHealthDeclaration({
      uid: params.uid as string,
      healthData: {
        portsVisitedWithinWHO: portsVisitedWithinWHO
      }
    }) as any);

    setConfirmDeleteMemberModalData({
      index: null,
      showModal: false
    });
  };

  const handleDeleteAllMembers = () => {
    setShowConfirmDeleteAllMembersModal(false);

    dispatch(updateHealthDeclaration({
      uid: params.uid as string,
      healthData: {
        portsVisitedWithinWHO: []
      }
    }) as any);
  };

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, healthDeclaration]);

  return (
    <div className="health-wrapper">
      <PortModal
        isEditingRow={isEditingRow}
        editingRow={editingRow}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setEditingRow={setEditingRow}
        setData={handlePortChanges}
      />
      <PageHeader
        title={"Maritime declaration of health"}
        subtitle={"Please, answer the following questions"}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to <span>delete all the <br />ports visited</span> from the list?</h3>}
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => handleDeleteAllMembers()}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this port visited?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          });
        }}
        onDeleteClick={() => handleDeleteSingleMember()}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={validationSchema}>
        {({ values, setValues, errors }) => (
          <Form className="health-declaration-form">
            <div className="health-declaration-form__controls">
              <div className="health-declaration-form__row">
                <RadioButtonsCustom
                  name="hasValidSanitationCertificateCarriedOnBoard"
                  label="Valid Sanitation Control Exemption/Control Certificate carried on board?"
                  options={booleanOptionsForRadioGroup()} />
              </div>
              {values.hasValidSanitationCertificateCarriedOnBoard && (
                <div className="health-declaration-form__row three">
                  <SearchPortsDropdown
                    label="Port"
                    name="dropdownPlaceOfIssuance"
                    onChange={(port: Port) => {
                      setInitialValues({
                        ...values,
                        dropdownPlaceOfIssuance: port,
                      });
                    }}
                    dropdownID="healthDeclaration.placeOfIssuance"
                    placeholder="Type One"
                    getSpecificValue='unlocode' />
                  <Field label="Issuing date" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.issuingDate + "issuingDate"}
                      name="issuingDate"
                      sizeInput="large"
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={
                        initialValues?.issuingDate
                      }
                      action={({ isoDate }: any) => {
                        setSanitationCertIssueDate(new Date(isoDate));
                        setValues({ ...values, issuingDate: new Date(isoDate) });
                      }}
                      hasError={errors?.issuingDate}
                      errorMessage={errors?.issuingDate}
                    />
                  </Field>
                  <Field label="Expiring date" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.expiringDate + "expiringDate"}
                      name="expiringDate"
                      sizeInput="large"
                      minDate={sanitationCertIssueDate || initialValues.issuingDate}
                      showTime={false}
                      defaultValue={initialValues.expiringDate || sanitationCertIssueDate}
                      action={({ isoDate }: any) => {
                        setValues({ ...values, expiringDate: new Date(isoDate) });
                      }}
                      hasError={errors?.expiringDate}
                      errorMessage={errors?.expiringDate}
                    />
                  </Field>
                </div>
              )}
              <div className="health-declaration-form__row">
                <RadioButtonsCustom
                  name="reinspectionRequired"
                  label="Re-inspection required?"
                  options={booleanOptionsForRadioGroup()} />
              </div>
              <div className="health-declaration-form__row">
                <RadioButtonsCustom
                  name="hasShipVisitedInfectedArea"
                  label="Has ship/vessel visited an affected area identified by World Health Organization?"
                  options={booleanOptionsForRadioGroup()} />
              </div>
              {values.hasShipVisitedInfectedArea && (
                <div className="health-declaration-form__row two">
                  <SearchPortsDropdown
                    label="Port"
                    name="dropdownInfectedAreaPort"
                    onChange={(port: Port) => {
                      setInitialValues({
                        ...values,
                        dropdownInfectedAreaPort: port,
                      });
                    }}
                    dropdownID="healthDeclaration.infectedAreaPort"
                    placeholder="Type One"
                    getSpecificValue='unlocode' />
                  <Field label="Date of visit" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.infectedAreaDate + "infectedAreaDate"}
                      name="infectedAreaDate"
                      sizeInput="large"
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={
                        initialValues?.infectedAreaDate
                      }
                      action={({ isoDate }: any) =>
                        setValues({ ...values, infectedAreaDate: new Date(isoDate) })
                      }
                      hasError={errors?.infectedAreaDate}
                      errorMessage={errors?.infectedAreaDate}
                    />
                  </Field>
                </div>
              )}
              <div className="health-declaration-form__row">
                <label className="radio-buttons-custom__label">
                  List of port calls from commencement of voyage or within past thirty days, whichever is shorter:
                </label>
                <Table
                  title={'Ports visited within the World Health Organization'}
                  addButtonText={'Add another port'}
                  data={healthDeclaration.portsVisitedWithinWHO || []}
                  columns={lastPortInThirtyDaysColumns}
                  editingMode={isEditingTable}
                  setEditingMode={setIsEditingTable}
                  setIsAddModalOpen={setIsModalOpen}
                  setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
                  onEditButtonClick={(rowIndex) => {
                    setEditingRow(rowIndex);
                    setIsModalOpen(true);
                  }}
                  onDeleteButtonClick={(rowIndex) => {
                    setConfirmDeleteMemberModalData({
                      showModal: true,
                      index: rowIndex
                    });
                  }}
                />
              </div>
            </div>
            <SkipAndNextButtons
              onClick={() => navigate(`/${params.uid}/health/second-step`)}
              next={`/${params.uid}/health/third-step`}
              back={`/${params.uid}/marpol/tables`}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FirstHealthStep;
