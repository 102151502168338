import { configureStore } from '@reduxjs/toolkit';
import globalErrorMiddleware from '../features/captains-form/components/errors/middlewares/globalErrorMiddleware';
import authReducer from '../features/auth/slices/authSlice';
import vesselsReducer from '../features/port-clearance-form-request/slices/vesselsSlice';
import portsReducer from '../features/port-clearance-form-request/slices/portsSlice';
import portCallRequestReducer from '../features/port-clearance-form-request/slices/portCallRequestSlice';
import companyReducer from '../features/captains-form/slices/companySlice';
import portCallReducer from '../features/captains-form/slices/portCallSlice';
import marpolReducer from "../features/captains-form/components/marpol/slices/marpolSlice";
import globalErrorReducer from "../features/captains-form/components/errors/slices/globalErrorSlice";
import placeOfBirthReducer from '../features/forms/components/place-of-birth/slices/placeOfBirthSlice';
import documentsReducer from "../features/captains-form/components/documents/slices/documentsSlice";
import searchPortsReducer from '../features/forms/components/search-ports-dropdown/slices/searchPortsSlice';
import searchPortFacilitiesReducer from '../features/forms/components/search-ports-dropdown/slices/searchPortFacilitiesSlice';


const jwt = localStorage.getItem("JWT");

const initialState = {
  auth: {
    jwt
  }
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    companies: companyReducer,
    documents: documentsReducer,
    globalError: globalErrorReducer,
    marpol: marpolReducer,
    placeOfBirth: placeOfBirthReducer,
    portCallRequest: portCallRequestReducer,
    portCall: portCallReducer,
    ports: portsReducer,
    searchPorts: searchPortsReducer,
    searchPortFacilities: searchPortFacilitiesReducer,
    vessels: vesselsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(globalErrorMiddleware),
  preloadedState: initialState
});
