import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  updateIsps,
  selectSolas,
} from '../../../slices/portCallSlice';
import Table from '../../../../tables/components/Table';
import Button from '../../../../layout/components/Button';
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import Modal from "../../../../layout/components/Modal";
import Input from '../../../../forms/components/Input';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import "../styles/solas-security-measures.scss";
import DatePicker, {DEFAULT_DATETIME_MASK} from "../../../../forms/components/DatePicker";
import MultiSelect from "../../../../forms/components/MultiSelect";
import {removeNullUndefined} from "../../../../global/utils/cleanEmptyData";
import {
  DEFAULT_FORMAT_DATETIME,
  formatDateTimeInTable, isCrossedDates,
  isDateFromOrDateToOverlapWithAnyDate
} from "../../../../global/utils/datetimes";
import {value} from "lodash/seq";
import {DatePickerTimeInput} from "@navozyme/uikit/dist/molecules/DatePicker";
import {Field} from "@navozyme/uikit/dist/atoms/Field";


function SolasSecurityMeasuresTable({disabled}) {
  const dispatch = useDispatch();
  const params = useParams();
  const solas = useSelector(selectSolas);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [lastPortCalls, setLastPortCalls] = useState([])

  const resetFormValue = {description: null, date: null, portcall: null}

  useEffect(() => {
    if (solas?.data?.last10PortCalls){
      setLastPortCalls(solas?.data?.last10PortCalls.filter(portcall=> portcall?.arrivalDate && portcall?.departureDate).map(portcall=>{
        return {
          label: `${portcall?.portName}, ${portcall?.portCountry}`,
          value: portcall
        }
      }))
    }
  }, [solas?.data?.last10PortCalls]);

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      Cell: formatDateTimeInTable
    },
    {
      Header: "Security measure",
      accessor: "description"
    }
  ]

  let initialValues = {
    number: solas?.data?.securityMeasures?.length + 1
  };

  const validationSchema = Yup.object({

  })

  const handleSubmit = async (values, onSubmitProps) => {
    if (isEditingRow) {
      const newMembers = solas.data.securityMeasures.map((member, i) => {
        if (i === editingRow) {
          return removeNullUndefined({...values, number: i+1, date: values?.date ? values?.date.toISOString() : null, portcall: null})
        }
        return removeNullUndefined({...member, number: i+1, date: member?.date ? new Date(member?.date).toISOString() : null, portcall: null})
      })


      dispatch(updateIsps({
        ispsData: {
          securityMeasures: newMembers
        },
        uid: params.uid
      }));

      setIsModalOpen(false);
      setEditingRow(null);
    } else {
      let securityMeasures = [];
      if (solas.data.securityMeasures) {
        securityMeasures = solas.data.securityMeasures.concat(removeNullUndefined({...values, number: solas?.data?.securityMeasures?.length + 1, date: values?.date ? values?.date.toISOString() : null , portcall: null}));
      } else {
        securityMeasures.push(removeNullUndefined({...values, number: securityMeasures?.length + 1, date: values?.date ? values?.date.toISOString() : null, portcall: null}));
      }


      dispatch(updateIsps({
        ispsData: {
          securityMeasures
        },
        uid: params.uid
      }));
      setIsModalOpen(false);
    }
  };



  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const row = isEditingRow ? solas.data.securityMeasures[editingRow] : {};

  initialValues = {
    description: row.description ? row.description : "",
    number: row.number ? editingRow + 1 : solas?.data?.securityMeasures?.length + 1,
    date: row.date ? new Date(row.date) : null,
    portcall: lastPortCalls.find(portcall => isDateFromOrDateToOverlapWithAnyDate(new Date(row?.date), new Date(portcall?.value?.arrivalDate), new Date(portcall?.value?.departureDate)))?.value
  };

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);

  const validateDates = (values, setValues)=>{
    if (!values?.portcall?.arrivalDate || !values?.portcall?.departureDate) setValues({...values, date: new Date(values?.date)});
    if (!isDateFromOrDateToOverlapWithAnyDate(new Date(values?.date), new Date(values?.portcall?.arrivalDate), new Date(values?.portcall?.departureDate))){
      setValues({...values, date: new Date(values?.portcall?.arrivalDate)})
    }else setValues({...values, date: new Date(values?.date)})
  }


  return (
    <div className="solas-security-measures-table">
      <Table
        disabled={disabled}
        useRowNumbers
        title={'Security measures list'}
        addButtonText={'Add security measure'}
        data={solas.data.securityMeasures || []}
        columns={columns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex
          })
        }}
      />
      <Modal
        className={`solas-security-measures-modal`}
        title={"Add security measure"}
        showModal={isModalOpen}
        onCloseModal={() => {
          setIsModalOpen(false);
          setEditingRow(null)
          initialValues = { number: solas.data.securityMeasures.length + 1, ...resetFormValue}
        }}>
        <div className="solas-security-measures-modal__content">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}>
            {({ values, setValues, errors }) => {
              return (
                <Form className="solas-security-measures-form">
                  <div className="solas-security-measures-form__row1">
                    <Input name="number" label="Number" disabled={true}/>
                    <MultiSelect
                        key={values?.portcall?.portName}
                        name="portcall"
                        options={lastPortCalls}
                        isMulti={false}
                        label="Select port call"
                        placeholder="Choose one"
                        onChange={(value)=>{
                          if (value?.value?.arrivalDate) {
                            setValues({...values, date: new Date(value?.value?.arrivalDate)})
                          }
                        }}
                    />
                    <Field label="Date From" className="field_date_picker">
                      <DatePickerTimeInput
                          key={initialValues?.date + initialValues?.portcall?.departureDate + initialValues?.portcall?.arrivalDate + "date"}
                          name="date"
                          sizeInput="large"
                          maxDate={new Date(values?.portcall?.departureDate)}
                          minDate={new Date(values?.portcall?.arrivalDate)}
                          showTime={true}
                          defaultValue={
                            initialValues?.date
                          }
                          action={({ isoDate }) =>
                            validateDates({...values, date: new Date(isoDate)}, setValues)
                          }
                          hasError={errors?.date}
                          errorMessage={errors?.date}
                      />
                    </Field>
                  </div>
                  <div className="solas-security-measures-form__row2">
                    <Input name="description" placeholder="Type one" label="Security Measure"/>
                  </div>
                  {
                    isEditingRow ? (
                        <div className="add-member__form-submission-btns">
                          <div className="discard-btn" onClick={() => {
                            setIsModalOpen(false);
                            setTimeout(() => setEditingRow(null), 300)

                          }}>Discard
                          </div>
                          <Button type="submit">Change Information</Button>
                        </div>
                    ) : (
                        <div className="add-member__form-submission-btns">
                          <div className="discard-btn" onClick={() => {
                            setIsModalOpen(false);
                            setTimeout(() => setEditingRow(null), 300)

                          }}>Discard
                          </div>
                          <Button iconLeft={plusIcon} type="submit">Add</Button>
                        </div>
                    )
                  }
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
          title={<h3>Are you sure you want to <span>delete all the <br/>security measures</span> from the list?</h3>}
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          dispatch(updateIsps({
            ispsData: {
              securityMeasures: []
            },
            uid: params.uid
          }))
          setShowConfirmDeleteAllMembersModal(false);
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this security measure?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          })
        }}
        onDeleteClick={() => {
          let securityMeasures = [...solas.data.securityMeasures];
          securityMeasures.splice(confirmDeleteMemberModalData.index, 1);
          securityMeasures = securityMeasures.map((value, i)=>{
            return {...value, number: i +1}
          })

          dispatch(updateIsps({
            ispsData: {
              securityMeasures
            },
            uid: params.uid
          }))

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          });
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />

    </div >
  );
}

export default SolasSecurityMeasuresTable;
