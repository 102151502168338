import React from 'react';
import { useSelector } from 'react-redux';
import { selectGeneralData, selectCrew } from '../../../slices/portCallSlice';
import { DOCUMENT_CATEGORIES, DOCUMENT_TYPE_IDS } from "../../documents/constants";
import DefaultCrewTable from './DefaultCrewTable';

function DepartureCrewTable(props) {
  const generalPortcallData = useSelector(selectGeneralData);
  const portUnlocode = generalPortcallData?.unlocode;
  const crew = useSelector(selectCrew);
  const crewList = crew.crewList.map((crewMember, index) => { return { ...crewMember, index } });
  const departureCrewList = crewList.filter(crewMember => crewMember.portOfDisembarkation !== portUnlocode);

  return (
    <DefaultCrewTable
      title={'Departure'}
      crewList={crewList}
      sectionCrewList={departureCrewList}
      documentCategory={DOCUMENT_CATEGORIES.PRE_DEPARTURE_DOCUMENTS}
      documentTypeId={DOCUMENT_TYPE_IDS.UPLOAD_DEPARTURE_CREW}
      crewListDocumentLink={crew.crewListDocumentLink}
    />
  );
}

export default DepartureCrewTable;
