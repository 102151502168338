import React from 'react'
import Select from 'react-select'
import _ from 'lodash';
import { getStyles } from '../helpers/random-form-helpers';

const ReactSelectPorts = ({
  field,
  form,
  options,
  placeholder = 'Select',
  onInputChange,
  isLoading,
  disabled,
  onChange,
  getSpecificValue,
  ...rest
}) => {
  const getValue = () => {
    if (options.length) {
      return options.find((option) => option.unlocode === (field.value && field.value.unlocode));
    } else {
      return !_.isEmpty(field.value) ? field.value : null;
    }
  };

  const { setFieldValue, handleBlur, errors, touched } = form;
  const customStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: '0.7rem', // change this value to the desired font size
    }),
  };

  return (
    <Select
      classNamePrefix="react-select"
      menuPosition="fixed"
      styles={customStyles}
      options={options}
      name={field.name}
      getOptionLabel={(option) => option.label || (option.name + ", " + option.countryCode)}
      getOptionValue={(option) => option.unlocode}
      isLoading={isLoading}
      className={(touched[field.name] && getStyles(errors, field.name)) ? "react-select-container error" : "react-select-container"}
      value={getValue()}
      onChange={(option) => {
        if (onChange) {
          onChange(option)
        }
        getSpecificValue ? setFieldValue(field.name, option[getSpecificValue]) : setFieldValue(field.name, option)
      }}
      onInputChange={onInputChange}
      onBlur={() => handleBlur({ target: { name: field.name } })}
      placeholder={placeholder}
      isDisabled={disabled}
      {...rest}
    />
  )
}

export default ReactSelectPorts;
