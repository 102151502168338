import React, { useRef } from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import ArrivalPassengersTable from './ArrivalPassengersTable';


const validationSchema = Yup.object({
  passengersChangesOptions: Yup.boolean()
});

const passengersChangesOptions = booleanOptionsForRadioGroup();

function ArrivalPassengersChanges() {
  const formRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  const initalValues = {
    passengersChangesOptions: true
  };


  const handleSubmit = async (values, onSubmitProps) => {
    navigate(`/${params.uid}/passengers/departure-passengers`);
  };

  return (
    <div className="passengers-changes">
      <PageHeader title={"Passengers"} />
      <Formik
        enableReinitialize
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initalValues}
        validationSchema={validationSchema}>
        {({ values }) => {
          return (
            <>
              < Form className="passengers-changes-form" >
                <RadioButtonsCustom
                  name="passengersChangesOptions"
                  label="Any change of passengers since the last port call?"
                  options={passengersChangesOptions} />
              </Form>
              <ArrivalPassengersTable disabled={!values.passengersChangesOptions} />
            </>
          )
        }}

      </Formik>
      <SkipAndNextButtons
        onClick={() => formRef.current.submitForm()}
        next={`/${params.uid}/passengers/departure-passengers`}
        back={`/${params.uid}/passengers/number`} />
    </div >
  );
}

export default ArrivalPassengersChanges;
