import React, { createContext, useRef, useState } from 'react';

const FormRefContext = createContext();

export const FormRefProvider = ({ children }) => {
  const formContextRef = useRef();
  const multiFormRef = useRef();
  const [skipOrBack, setSkipOrBack] = useState(false);
  const [noOfPage, setNoOfPage] = useState(5);
  return (
    <FormRefContext.Provider value={{ formContextRef, multiFormRef, skipOrBack, setSkipOrBack, noOfPage, setNoOfPage }}>
      {children}
    </FormRefContext.Provider>
  );
};

export { FormRefContext };
