import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { selectOtherWaste } from "../slices/marpolSlice";
import { emptyHandleSubmit } from "./MarpolTables";
import WasteRow from "./WasteRow";
import WasteHeader from "./WasteHeader";

export default function OtherWasteTable(props) {
  const otherWaste = useSelector(selectOtherWaste);
  const [initialValues, setInitialValues] = useState(otherWaste || {});

  useEffect(() => {
    setInitialValues(otherWaste || {});
  }, [props.disableWasteDelivered, otherWaste]);

  return (
    <div className="marpol-table annex-other-table" style={{ display: props.isDisplayed ? "flex" : "none" }}>
      <WasteHeader wasteName="Other waste not covered by MARPOL" />
      <Formik
        innerRef={props.formRef}
        onSubmit={emptyHandleSubmit}
        initialValues={initialValues}
        enableReinitialize>
        {({values}) => (
          <Form className="cargo-information-form">
            <div className="marpol-table__body">
              <WasteRow wasteName="Passively fished waste" imoWasteCode="passivelyFishedWaste" isWasteToBeDeliveredDisabled={props.disableWasteDelivered} { ...values?.passivelyFishedWaste } />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
