import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import '../styles/modal.scss';

ReactModal.setAppElement('#root');

const Modal = (props) => {
  return (
    <div className="modal">
      <ReactModal
        className={"modal-wrapper" + (props.className ? " " + props.className : "")}
        overlayClassName={"modal-overlay"}
        isOpen={props.showModal}
        onRequestClose={props.onCloseModal}
        ariaHideApp={false}
        onAfterOpen={() => {
          if (props.onAfterOpen) props.onAfterOpen();
        }}
        closeTimeoutMS={props.closeTimeoutMS || 200}>
        <div className={`modal modal__children`} onClick={() => {
          if (props.onClick) {
            props.onClick()
          };
        }}>
          {props.hideCloseBtn ? null : <div className="modal__close-button" size="lg" onClick={props.onCloseModal}>Close</div>}
          {(props.title || props.icon) && <div className="modal__header-container">
            {props.icon && (
              <img className="modal__header-container__icon" src={props.icon} alt="btn-icon" />
            )}
            <div className='modal__header-container__text'>
              <span className="modal__header-container__text__title">{props.title}</span>
              <span className='modal__header-container__text__subtitle'>{props.subtitle}</span>
            </div>
            
          </div>}
          {props.children}
        </div>
      </ReactModal>
    </div>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Modal;
