
export const CrossCircleIcon = (props: any)=> {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        fill={props.fill}
        viewBox="0 0 24 25"
        preserveAspectRatio="xMidYMid meet"
        stroke={props.stroke}
        {...props}
    >
        <path
            fill="inherit"
            fillRule="evenodd"
            d="M2.25 12.365c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75-9.75-4.365-9.75-9.75Zm7.28-3.53a.75.75 0 0 0-1.06 1.06l2.47 2.47-2.47 2.47a.75.75 0 1 0 1.06 1.06l2.47-2.47 2.47 2.47a.75.75 0 1 0 1.06-1.06l-2.47-2.47 2.47-2.47a.75.75 0 0 0-1.06-1.06L12 11.305l-2.47-2.47Z"
            clipRule="evenodd"
        />
    </svg>
}

