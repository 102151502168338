import React, { useRef, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPortCall, selectGeneralData } from '../../captains-form/slices/portCallSlice';
import Sidebar from './Sidebar';
import Header from './Header';
import Modal from "./Modal";
import { clearErrors, selectGlobalError } from '../../captains-form/components/errors/slices/globalErrorSlice';
import { getRequiredDocuments } from '../../captains-form/components/documents/slices/documentsSlice';
import "../styles/layout.scss";

export default function Layout(props) {
  const formRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalError = useSelector(selectGlobalError);
  const generalPortcallData = useSelector(selectGeneralData);
  const mandatoryDocumentsFormRef = useRef();
  const params = useParams();
  const { isOpenToMaster } = generalPortcallData;

  useEffect(() => {
    dispatch(getRequiredDocuments({
      uid: params.uid
    }));
    if (params.uid) {
      dispatch(getPortCall(params.uid));
    }
  }, [])

  const haveToRedirectToBlockPage = () => {
    return isOpenToMaster != null && !isOpenToMaster
  }

  useEffect(() => {
    if (haveToRedirectToBlockPage()) navigate(`/blocked`)
  }, [isOpenToMaster])
  
  return (
    <div className="layout">
      <Header formRef={formRef} mandatoryDocumentsFormRef={mandatoryDocumentsFormRef} />
      <main>
        <Sidebar />
        {/* {props.children} */}
        {React.Children.map(props.children, (child) => {
          return React.cloneElement(child, { context: { formRef, mandatoryDocumentsFormRef } });
        })}
      </main>
      <Modal
        className={`global-error-modal`}
        title={"Error"}
        // showModal={isModalOpen}
        closeTimeoutMS={1}
        showModal={globalError.hasErrors}
        onCloseModal={() => {
          dispatch(clearErrors())
          // setIsModalOpen(false);
        }}>
        <div className="add-cargo-modal__content">
          <p>There is an error:</p>
          <pre>{globalError.errors[0]}</pre>
          {globalError.errors.map((error) => {
            return (
              <pre>
                  {error}
              </pre>
              
            )
          })}
        </div>
      </Modal>
    </div>
  );
}
