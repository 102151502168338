import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, Navigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { getDeliveryWaste } from '../slices/marpolSlice';

function Marpol() { 
  const params = useParams();
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState("50");
  const [pageNumber, setPageNumber] = useState("1");
  const location = useLocation();

  const fetchData = async () => {
    await dispatch(getDeliveryWaste(params.uid));
  };

  useEffect(() => {
    fetchData();
    if (location.pathname === `/${params.uid}/marpol/confirm-waste`) {
      setPercentage("50");
      setPageNumber("1");
    } else if (location.pathname === `/${params.uid}/marpol/tables`) {
      setPercentage("100");
      setPageNumber("2");
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/marpol`) {
    return <Navigate to={`/${params.uid}/marpol/confirm-waste`} replace />;
  }

  return (
    <Page>
      <div className="marpol">
        <ProgressBar percentage={percentage} pageNumber={pageNumber} totalPages={"2"} />
        <Outlet />
      </div>
    </Page>
  );
}

export default Marpol;
