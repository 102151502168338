
export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export const shipTypeOptions: string[] = [
  "Accommodation Barge",
  "Accommodation Jack Up",
  "Accommodation Platform",
  "Accommodation Ship",
  "Accommodation Vessel",
  "Aggregates Carrier",
  "Air Cushion Patrol Vessel",
  "Anchor Handling Vessel",
  "Anti-Pollution",
  "Anti-Pollution Vessel",
  "Articulated Pusher Tug",
  "Asphalt or Bitumen Tanker",
  "Backhoe Dredger",
  "Barge Carrier",
  "Bucket Dredger",
  "Bucket Dredger Pontoon",
  "Bucket Ladder Dredger",
  "Bulk Carrier",
  "Bulker",
  "Bunkering Tanker",
  "Buoy-Laying Vessel",
  "Cable Layer",
  "Cabu Carrier",
  "Cargo",
  "Cargo - Hazardous A",
  "Cargo - Hazardous B",
  "Cargo - Hazardous C",
  "Cargo - Hazardous D",
  "Cargo Barge",
  "Cargo or Containership",
  "Cement Carrier",
  "Chemical Tanker",
  "CO2 Tanker",
  "Combat Vessel",
  "Command Vessel",
  "Construction Support Vessel",
  "Container Ship",
  "Crane Barge",
  "Crane Ship",
  "Crew Boat",
  "Crude Oil",
  "Crude Oil Tanker",
  "Cutter Suction Dredger",
  "Deck Cargo Pontoon",
  "Deck Cargo Ship",
  "Dive Ops",
  "Dive Vessel",
  "Diving Support Platform",
  "Diving Support Vessel",
  "Dredger",
  "Dredging Pontoon",
  "Drill Barge",
  "Drill Ship",
  "Drilling Jack Up",
  "Drilling Rig",
  "Edible Oil Tanker",
  "Exhibition Ship",
  "Factory Trawler",
  "Fire Fighting Vessel",
  "Fish Carrier",
  "Fish Factory",
  "Fishery Patrol Vessel",
  "Fishery Research Vessel",
  "Fishery Support Vessel",
  "Fishing Vessel",
  "Floating Crane",
  "Floating Hotel or Restaurant",
  "Floating Sheerleg",
  "Floating Storage or Production",
  "Fruit Juice Tanker",
  "General Cargo",
  "Grab Dredger",
  "Grab Dredger Pontoon",
  "Grab Hopper Dredger",
  "Heavy Lift Vessel",
  "Heavy Load Carrier",
  "High Speed Craft",
  "Hopper Barge",
  "Hopper Dredger",
  "Hospital Ship",
  "Houseboat",
  "Hovercraft",
  "HSC",
  "Hydrofoil",
  "Icebreaker",
  "Inland Cargo",
  "Inland Dredger",
  "Inland Passenger Ship",
  "Inland Ro-Ro Cargo Ship",
  "Inland Supply Vessel",
  "Inland Tanker",
  "Inland Tug",
  "Inland, Barge, Tanker, Chemical",
  "Inland, Bulk Carrier maritime",
  "Inland, Bunkership",
  "Inland, Container Vessel",
  "Inland, Cruise Ship",
  "Inland, Fast Ship",
  "Inland Ferry",
  "Inland, Ferry",
  "Inland, Fishing Boat",
  "Inland, Freightbarge",
  "Inland, Freightbarge with Containers",
  "Inland, Gas Tanker",
  "Inland, General Cargo maritime",
  "Inland, Hydrofoil",
  "Inland, Liquified Gas Tanker",
  "Inland, Maintainance Craft, Cableship, Dredger",
  "Inland, Motor Freighter",
  "Inland, Motor Freighter Pushing Freighter(s)",
  "Inland, Motor Freighter pushing Tank-ship(s)",
  "Inland, Motor Freighter with Ships Alongside",
  "Inland, Motor Freighter with Tanker",
  "Inland, Motor Freighter, Tug",
  "Inland, Motor Tanker",
  "Inland, Motor Tanker, dry cargo as if liquid",
  "Inland, Motor Tanker, liquid cargo, type C",
  "Inland, Motor Tanker, liquid cargo, type N",
  "Inland, Motor Tanker, Tug",
  "Inland, Object, not otherwise specified",
  "Inland, Object, Towed, not otherwise specified",
  "Inland, Passenger Ship without Accommodation",
  "Inland, Passenger Ship, Ferry, Cruise ship",
  "Inland, Pleasure Craft, >20 metres",
  "Inland, Pushboat, Single",
  "Inland, Pushtow, eigth cargo barges",
  "Inland, Pushtow, five barges at least one tanker",
  "Inland, Pushtow, five cargo barges",
  "Inland, Pushtow, four barges at least one tanker",
  "Inland, Pushtow, four cargo barges",
  "Inland, Pushtow, nine or more barges",
  "Inland, Pushtow, nine+ barges at least one tanker",
  "Inland, Pushtow, one cargo barge",
  "Inland, Pushtow, one tank or gas barge",
  "Inland, Pushtow, seven cargo barges",
  "Inland, Pushtow, six barges at least one tanker",
  "Inland, Pushtow, six cargo barges",
  "Inland, Pushtow, three barges at least one tanker",
  "Inland, Pushtow, three cargo barges",
  "Inland, Pushtow, two barges at least one tanker",
  "Inland, Pushtow, two cargo barges",
  "Inland, Red Cross Ship",
  "Inland, Service Vessel, Police Patrol",
  "Inland, Tanker",
  "Inland, Tankbarge",
  "Inland, Tankbarge, dry cargo as if liquid",
  "Inland, Tankbarge, Gas",
  "Inland, Tankbarge, liquid cargo, type C",
  "Inland, Tankbarge, liquid cargo, type N",
  "Inland, Tug Freighter, coupled",
  "Inland, Tug, assisting a vessel or combination",
  "Inland, Tug, Freighter",
  "Inland, Tug, Freighter or Tanker, coupled",
  "Inland, Tug, one or more tows",
  "Inland, Tug, single",
  "Inland, Tug, Tanker",
  "Inland, Unit Carrier maritime",
  "Inland, Unknown",
  "Isolated Danger",
  "Kelp Dredger",
  "Landing Craft",
  "Law Enforce",
  "Law Enforcement",
  "Light Vessel - LANBY - Rig",
  "Limestone Carrier",
  "Livestock Carrier",
  "LNG Tanker",
  "Local Vessel",
  "Logistics Naval Vessel",
  "LPG or Chemical Tanker",
  "LPG Tanker",
  "Maintenance Platform",
  "Maintenance Vessel",
  "Manned VTS",
  "Medical Trans",
  "Military Ops",
  "Mine Hunter",
  "Minesweeper",
  "Mining Vessel",
  "Mooring Vessel",
  "Motor Hopper",
  "Multi Purpose Offshore Vessel",
  "Museum Ship",
  "Naval Auxiliary Vessel",
  "Naval Craft",
  "Naval or Naval Auxiliary Vessel",
  "Naval Patrol Vessel",
  "Naval Research Vessel",
  "Naval Salvage Vessel",
  "Navigation Aid",
  "Nuclear Fuel Carrier",
  "Obo Carrier",
  "Offshore Construction Jack Up",
  "Offshore Construction Vessel",
  "Offshore Safety Vessel",
  "OffShore Structure",
  "Offshore Supply Ship",
  "Oil or Chemical Tanker",
  "Oil Products",
  "Oil Products Tanker",
  "Ore Carrier",
  "Ore or Oil Carrier",
  "Other",
  "Pallet Carrier",
  "Passenger",
  "Passenger Landing Craft",
  "Passenger or Cargo Ship",
  "Passenger Ship",
  "Patrol Vessel",
  "Pearl Shells Carrier",
  "Pile Driving Vessel",
  "Pilot Ship",
  "Pilot Vessel",
  "Pipe Burying Vessel",
  "Pipe Layer",
  "Pipe Layer Platform",
  "Pipelay Crane Vessel",
  "Platform",
  "Pleasure Craft",
  "Pollution Control Vessel",
  "Pontoon",
  "Port Hand Mark",
  "Port Tender",
  "Powder Carrier",
  "Power Station Vessel",
  "Production Testing Vessel",
  "Pusher Tug",
  "RACON",
  "Rail or Vehicles Carrier",
  "Reefer",
  "Reefer or Containership",
  "Reference Point",
  "Repair Ship",
  "Replenishment Vessel",
  "Research or Survey Vessel",
  "Reserved",
  "Ro-Ro Cargo",
  "Ro-Ro or Container Carrier",
  "Ro-Ro or Passenger Ship",
  "Safe Water",
  "Sailing Vessel",
  "Salvage or Rescue Vessel",
  "Sand Suction Dredger",
  "SAR",
  "SAR Aircraft",
  "Sealer",
  "Search and Rescue Aircraft",
  "Search and Rescue Vessel",
  "Self Discharging Bulk Carrier",
  "Shuttle Tanker",
  "Spare",
  "Special Craft",
  "Special Mark - Sea Farm",
  "Special Vessel",
  "Standby Safety Vessel",
  "Starboard Hand Mark",
  "Stone Carrier",
  "Suction Dredger",
  "Suction Hopper Dredger",
  "Supply Jack Up",
  "Supply Tender",
  "Supply Vessel",
  "Support Jack Up",
  "Tank Barge",
  "Tank-Cleaning Vessel",
  "Tanker",
  "Tanker - Hazardous A",
  "Tanker - Hazardous B",
  "Tanker - Hazardous C",
  "Tanker - Hazardous D",
  "Tender",
  "Timber Carrier",
  "Torpedo Recovery Vessel",
  "Towing Vessel",
  "Trailing Suction Dredger",
  "Trailing Suction Hopper Dredger",
  "Training Ship",
  "Trans Shipment Barge",
  "Trans Shipment Vessel",
  "Trawler",
  "Trenching Support Vessel",
  "Troopship",
  "Tug",
  "Tug or Ice Breaker",
  "Tug or Supply Vessel",
  "Unspecified",
  "Utility Vessel",
  "Vehicles Carrier",
  "Waste Disposal Vessel",
  "Water Jet Dredging Pontoon",
  "Water Tanker",
  "Well Stimulation Vessel",
  "Whaler",
  "WIG",
  "Wine Tanker",
  "Wing In Grnd",
  "Wood Chips Carrier",
  "Work Pontoon",
  "Work Vessel",
  "Yacht",
];

