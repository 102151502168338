import auth from 'config/auth';
import { axiosInstance } from 'config/axios';
import rules from './mandatoryFields.json';

interface SectionConfig {
  summaryText: string;
  [key: string]: any;
}

interface DataObject {
  [key: string]: any;
}

export class MandatoryFieldRules {
  public async getWarningSummaries(portcallUid: string): Promise<string[]> {
    const headers = auth.getHeaders();

    // Fetch all the data concurrently using Promise.all
    const [
      portcallMainData,
      portcallDataResponse,
      berthStaysData,
      crewData,
      cargoData,
      dangerousGoodsData,
      ispsData,
      marpolWasteData,
      generalDeclarationData,
      documentsData,
    ] = await Promise.all([
      this.fetchData(`portcalls/${portcallUid}`, headers),
      this.fetchData(`portcalls/${portcallUid}/portcall-data`, headers),
      this.fetchData(`portcalls/${portcallUid}/berth`, headers),
      this.fetchData(`portcalls/${portcallUid}/crew`, headers),
      this.fetchData(`portcalls/${portcallUid}/cargo`, headers),
      this.fetchData(`portcalls/${portcallUid}/dangerous-goods-data`, headers),
      this.fetchData(`portcalls/${portcallUid}/isps`, headers),
      this.fetchData(`portcalls/${portcallUid}/delivery-waste`, headers),
      this.fetchData(`portcalls/${portcallUid}/general-declaration`, headers),
      this.fetchData(`portcalls/${portcallUid}/documents`, headers),
    ]);

    const portcallData = portcallDataResponse.portcallData;

    const combinedData: DataObject = {
      portcallMainData,
      portcallData,
      berthStaysData,
      crewData,
      cargoData,
      dangerousGoodsData,
      ispsData,
      marpolWasteData,
      generalDeclarationData,
      documentsData,
    };

    const missingFields = this.checkMandatoryFields(combinedData, rules);
    return missingFields;
  }

  private async fetchData(url: string, headers: any): Promise<DataObject> {
    try {
      const response = await axiosInstance.get(url, { headers });
      return response.data.data;
    } catch (error) {
      console.error(`Error fetching data from ${url}: `, error);
      return {};
    }
  }

  private checkMandatoryFields(data: DataObject, rules: DataObject): string[] {
    const missingFieldsSummary: string[] = [];

    Object.keys(rules).forEach((section) => {
      const sectionConfig = rules[section] as SectionConfig;
      const sectionData = data[section];
      this.validateSection(sectionData, sectionConfig, section, missingFieldsSummary);
    });

    return Array.from(new Set(missingFieldsSummary));
  }

  private validateSection(
    sectionData: DataObject,
    sectionConfig: SectionConfig,
    section: string,
    missingFieldsSummary: string[]
  ): void {
    if (!sectionData) {
      missingFieldsSummary.push(sectionConfig.summaryText);
      return;
    }

    let hasMissingField = false;
    if (['portcallMainData', 'portcallData', 'generalDeclarationData', 'cargoData'].includes(section)) {
      hasMissingField = this.validateNormalFields(sectionData, sectionConfig);
    } else if (['dangerousGoodsData'].includes(section)) {
      this.validateDangerousGoodsFields(sectionData, sectionConfig, missingFieldsSummary);
    } else if (['berthStaysData'].includes(section)) {
      hasMissingField = this.validateBerthFields(sectionData, sectionConfig);
    } else if (['crewData'].includes(section)) {
      hasMissingField = this.validateCrewFields(sectionData, sectionConfig);
    } else if (['ispsData'].includes(section)) {
      hasMissingField = this.validateIspsFields(sectionData, sectionConfig);
    } else if (['marpolWasteData'].includes(section)) {
      hasMissingField = this.validateWasteFields(sectionData, sectionConfig);
    } else if (['documentsData'].includes(section)) {
      this.validateDocumentsFields(sectionData, sectionConfig, missingFieldsSummary);
    }

    if (hasMissingField) {
      missingFieldsSummary.push(sectionConfig.summaryText);
    }
  }

  private validateNormalFields(sectionData: DataObject, sectionConfig: SectionConfig): boolean {
    let hasMissingField = false;

    Object.keys(sectionConfig).forEach((field) => {
      if (field === 'summaryText') return;

      const expectedType = sectionConfig[field];
      const fieldValue = sectionData ? sectionData[field] : undefined;

      if (typeof expectedType === 'object' && !Array.isArray(expectedType)) {
        const nestedHasMissingField = this.validateNormalFields(fieldValue, expectedType);
        if (nestedHasMissingField) {
          hasMissingField = true;
        }
      } else if (!this.validateKeyData(fieldValue, expectedType)) {
        hasMissingField = true;
      }
    });

    return hasMissingField;
  }

  private validateDangerousGoodsFields(
    sectionData: DataObject,
    sectionConfig: SectionConfig,
    missingFieldsSummary: string[]
  ): void {
    Object.keys(sectionConfig).forEach((field) => {
      if (field === 'summaryText') return;

      if (field === 'isTransportingHeavyOils') {
        const expectedType = sectionConfig[field];
        const fieldValue = sectionData ? sectionData[field] : undefined;
        if (!this.validateKeyData(fieldValue, expectedType)) {
          missingFieldsSummary.push(sectionConfig.summaryText);
        }
      } else if (field === 'dangerousGoodsStatus') {
        const dangerousGoodsStatusConfig = sectionConfig.dangerousGoodsStatus;
        const dangerousGoodsStatusData = sectionData.dangerousGoodsStatus || {};

        Object.keys(dangerousGoodsStatusConfig).forEach((key) => {
          if (key === 'summaryText') return;

          const expectedType = dangerousGoodsStatusConfig[key];
          const actualValue = dangerousGoodsStatusData[key];

          if (!this.validateKeyData(actualValue, expectedType)) {
            missingFieldsSummary.push(dangerousGoodsStatusConfig.summaryText);
          }
        });
      } else if (field === 'internationalSafetyManagementCertificate') {
        const certConfig = sectionConfig.internationalSafetyManagementCertificate;
        const certData = sectionData.internationalSafetyManagementCertificate || {};

        const hasISM = certData.shipHasInternationalSafetyManagement;
        if (hasISM === undefined) {
          missingFieldsSummary.push(certConfig.summaryText);
        } else if (hasISM) {
          Object.keys(certConfig).forEach((key) => {
            if (key === 'summaryText') return;

            if (!this.validateKeyData(certData[key], certConfig[key])) {
              missingFieldsSummary.push(certConfig.summaryText);
            }
          });
        } else {
          if (!this.validateKeyData(certData['reasonWhyNot'], 'string')) {
            missingFieldsSummary.push(certConfig.summaryText);
          }
        }
      }
    });
  }

  private validateBerthFields(sectionData: DataObject, sectionConfig: SectionConfig): boolean {
    const berthId = 1; // Always have to check for first stay
    let hasMissingField = false;

    Object.keys(sectionConfig).forEach((field) => {
      if (field === 'summaryText' || field === 'berthId') return;

      const expectedType = sectionConfig[field];
      const fieldValue = sectionData ? sectionData[field] : undefined;

      if (Array.isArray(expectedType) && Array.isArray(fieldValue)) {
        expectedType.forEach((expectedObj, index) => {
          const matchingFieldValueObj = fieldValue.find((obj: DataObject) => obj['berthId'] === berthId);

          if (matchingFieldValueObj) {
            const nestedHasMissingField = this.validateBerthFields(matchingFieldValueObj, expectedObj);
            if (nestedHasMissingField) {
              hasMissingField = true;
            }
          } else {
            hasMissingField = true;
          }
        });
      } else if (!this.validateKeyData(fieldValue, expectedType)) {
        hasMissingField = true;
      }
    });

    return hasMissingField;
  }

  private validateCrewFields(sectionData: DataObject, sectionConfig: SectionConfig): boolean {
    let hasMissingField = false;

    sectionData.crewList.forEach((crewMember: DataObject) => {
      if (crewMember['rank'] === 'Master') {
        if (!this.validateKeyData(crewMember.telephoneNumber, 'string')) {
          hasMissingField = true;
        }
      } else {
        const idConfig = sectionConfig.crewList.find(
          (config: DataObject) => config.IDNumber !== undefined && config.IDExpiryDate !== undefined
        );

        if (idConfig) {
          const isIDNumberValid = this.validateKeyData(crewMember.IDNumber, idConfig.IDNumber);
          const isExpiryDateValid = this.validateKeyData(crewMember.IDExpiryDate, idConfig.IDExpiryDate);

          if (!isIDNumberValid || !isExpiryDateValid) {
            hasMissingField = true;
          }
        }
      }
    });

    return hasMissingField;
  }

  private validateIspsFields(sectionData: DataObject, sectionConfig: SectionConfig): boolean {
    let hasMissingField = false;

    Object.keys(sectionConfig).forEach((field) => {
      if (field === 'summaryText' || field === 'last10PortCalls') return;

      const expectedType = sectionConfig[field];
      const fieldValue = sectionData[field];

      if (!this.validateKeyData(fieldValue, expectedType)) {
        return true;
      }
    });

    // Validate last 10 port calls
    const last10PortCalls = sectionData.last10PortCalls;
    if (!Array.isArray(last10PortCalls) || last10PortCalls.length !== 10) {
      return true;
    } else {
      last10PortCalls.forEach((portCall, index) => {
        const portFacilityName = portCall.portFacilityName;
        if (!this.validateKeyData(portFacilityName, 'string')) return true;
      });
    }

    return hasMissingField;
  }

  private validateWasteFields(sectionData: DataObject, sectionConfig: SectionConfig): boolean {
    let hasMissingField = false;

    if (typeof sectionData.wasteToDischarge !== 'boolean') return true;

    const wasteArray = sectionData.waste;
    if (sectionData.wasteToDischarge && wasteArray.length === 0) {
      return true;
    } else if (sectionData.wasteToDischarge && wasteArray.length > 0) {
      wasteArray.forEach((wasteItem: any, index: any) => {
        if (!this.validateKeyData(wasteItem.onboardQuantity, 'number')) return true;
      });
    }

    return hasMissingField;
  }

  private validateDocumentsFields(
    sectionData: DataObject,
    sectionConfig: SectionConfig,
    missingFieldsSummary: string[]
  ): void {
    const keysToValidate = Object.keys(sectionConfig);

    keysToValidate.forEach((key) => {
      const sectionArray = sectionData[key];
      const configArray = sectionConfig[key];

      if (Array.isArray(sectionArray) && sectionArray.length > 0) {
        sectionArray.forEach((item, index) => {
          if (!(this.validateKeyData(item.documentUid, 'string') && this.validateKeyData(item.name, 'string'))) {
            const summaryText = configArray[0].summaryText;
            if (!missingFieldsSummary.includes(summaryText)) {
              missingFieldsSummary.push(summaryText);
            }
          }
        });
      }
    });
  }

  // Function to validate if a field exists and is of the correct type
  private validateKeyData(value: any, expectedType: string): boolean {
    if (value === null || value === undefined || value === '') {
      return false;
    }

    if (expectedType === 'boolean') {
      return typeof value === 'boolean';
    } else if (expectedType === 'string') {
      return typeof value === 'string';
    } else if (expectedType === 'number') {
      return typeof value === 'number';
    } else if (expectedType === 'object') {
      return typeof value === 'object';
    }

    return false;
  }
}
