import React from 'react';
import "../styles/card.scss";

export default function Card(props) {
  const className = `card ${props.theme ? 'card--' + props.theme : 'card--default'}`;
  return (
    <div className={className}>
      {props.children}
    </div>
  );
}
