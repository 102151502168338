import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { selectSearchResults } from '../slices/vesselsSlice';
import { selectIsGenerateFormModalOpen, createPortCallRequest, selectPortCallCreationStatus, selectPortcallUiid } from '../slices/portCallRequestSlice';
import { searchPort, selectPortsSearchResults, selectPortsLoadingState } from '../slices/portsSlice';
import Page from '../../layout/components/Page';
import CollapsibleBox from '../../layout/components/CollapsibleBox';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Card from '../../layout/components/Card';
import PageTitle from '../../layout/components/PageTitle';
import { useOutletContext, useParams } from "react-router-dom";
import { toggleGenerateFormModal } from '../slices/portCallRequestSlice';
import Input from '../../forms/components/Input';
import CheckboxGroupCustom from '../../forms/components/CheckboxGroupCustom';
import Modal from "../../layout/components/Modal";
import Button from "../../layout/components/Button";
import MultiSelect from '../../forms/components/MultiSelect';
import successCheckmark from "../styles/images/successCheckmark.svg";
import successCheckmarkWhite from "../styles/images/successCheckmarkWhite.svg";
import "../styles/port-clearance-form-request-selected-vessel.scss";

const generalInfoFormInitialValues = {
  treatment: "",
  vesselMasterFullName: "",
  callingPort: ""
};


const mandatoryDocumentsFormInitialValues = {
  mandatoryDocuments: []
};


const validationSchema = Yup.object({
  treatment: Yup.string().required("*This field is required"),
  vesselMasterFullName: Yup.string().required("*This field is required"),
  callingPort: Yup.string().required("*This field is required")
})

const mandatoryDocumentsFormValidationSchema = Yup.object({
  mandatoryDocuments: Yup.array().min(1, "*This field is required")
})

const checkboxOptions = [
  { key: "Civil Liability Certificate for Damage caused by Ship’s Bunker", value: "CheckboxOption1" },
  { key: "Civil Liability Certificate for Damage caused by marine pollution", value: "CheckboxOption2" },
  { key: "Port Call Information", value: "CheckboxOption3" },
  { key: "DECAL Safe Embark and Disembark Conditions", value: "CheckboxOption4" },
  { key: "OPPE CAE (Pilots)", value: "CheckboxOption5" },
  { key: "Maritime Declaration of Health", value: "CheckboxOption6" },
];

function PortClearanceFormRequestSelectedVessel(props) {
  const { mmsi } = useParams();
  const { formRef, mandatoryDocumentsFormRef } = useOutletContext();

  const dispatch = useDispatch();
  const searchResults = useSelector(selectSearchResults);
  const portCallCreationStatus = useSelector(selectPortCallCreationStatus);

  const isGenerateFormModalOpen = useSelector(selectIsGenerateFormModalOpen);
  const vessel = searchResults.find(vessel => vessel.mmsi === mmsi);
  

  const portcallUiid = useSelector(selectPortcallUiid);


  const ports = useSelector(selectPortsSearchResults);
  const shouldShowLoadingInDropdown = useSelector(selectPortsLoadingState);
  const multiSelectDropdownOptions = ports;

  const handleInputChange = _.debounce((value) => {
    if (value && value.length > 2) {
      dispatch(searchPort({
        portName: value
      }));
    };
  }, 500);

  const handleInputChangeDebounced = (value) => {
    if (handleInputChange.cancel) {
      handleInputChange.cancel(); // If there is an existing debounce, cancel it
    }

    handleInputChange(value); // Start a new debounce with the latest value
  };

  const handleSubmit = async (values, onSubmitProps) => {
    const generalInfoValues = values;
    const mandatoryDocumentsValues = mandatoryDocumentsFormRef.current.values;

    const finalValues = {
      ...generalInfoValues,
      ...mandatoryDocumentsValues,
      ...values
    }

    const port = _.find(multiSelectDropdownOptions, { value: finalValues.callingPort });

    const body = {
      "vesselIMO": vessel.imo,
      "countryCode": port.countryCode,
      "unlocode": generalInfoValues.callingPort,
      "masterTreatment": generalInfoValues.treatment,
      "masterName": generalInfoValues.vesselMasterFullName
    };

    dispatch(createPortCallRequest(body));
  };

  const [linkCopied, setLinkCopied] = useState(false);

  function renderFormGenerateSuccess() {
    return (
      <div className="form-generated-success">
        <div className="form-generated-success__label">
          <img src={successCheckmark} alt="successCheckmark" />
          Link Generated
        </div>
        <CopyToClipboard
          text={`${process.env.REACT_APP_CLIENT_APP_URL}/${portcallUiid}/welcome`}
          onCopy={() => { setLinkCopied(true) }}>
          <div className="link-to-copy">{`${process.env.REACT_APP_CLIENT_APP_URL}/${portcallUiid}/welcome`}</div>
        </CopyToClipboard>
        <div className="form-generated-success__buttons">
          <div className="form-generated-success__close" onClick={() => dispatch(toggleGenerateFormModal())}>Close</div>
          <div className="copy-link-btn">
            <CopyToClipboard
              text={`${process.env.REACT_APP_CLIENT_APP_URL}/${portcallUiid}/welcome`}
              onCopy={() => { setLinkCopied(true) }}>
              {linkCopied ? <Button><img src={successCheckmarkWhite} alt="successCheckmarkWhite" />Link Copied</Button> :
                <Button>Copy Link</Button>}
            </CopyToClipboard>
          </div>
        </div>
      </div>
    )
  }

  const masterTreatmentOptions = [
    {
      value: "Captain",
      label: "Captain"
    },
    {
      value: "Master",
      label: "Master"
    },
    {
      value: "Mr.",
      label: "Mr."
    },
    {
      value: "Ms.",
      label: "Ms."
    }
  ];


  function renderPageContent() {
    return (
      <>
        <CollapsibleBox initialOpen={true} title={"General Information"}>
          <div className="general-information">
            <div className="general-information__section">
              <Formik
                innerRef={formRef}
                initialValues={generalInfoFormInitialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                <Form className="general-information-form">
                  <Card theme="blue">
                    <div className="general-information-form__form-elements">
                      <MultiSelect
                        name="treatment"
                        options={masterTreatmentOptions}
                        isMulti={false}
                        label="Treatment"
                        placeholder="Treatment" />

                      <Input name="vesselMasterFullName" placeholder="Vessel’s Master Full Name" label="Vessel’s Master Full Name" />
                      <MultiSelect
                        name="callingPort"
                        onInputChange={handleInputChangeDebounced}
                        options={multiSelectDropdownOptions}
                        isMulti={false}
                        label="Calling Port"
                        isLoading={shouldShowLoadingInDropdown}
                        placeholder="Calling Port" />
                    </div>
                  </Card>
                </Form>
              </Formik>
            </div>
            <div className="general-information__section">
              <Card theme="blue">
                <div className="general-information__info-row">
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Ship Name</div>
                    <div>{vessel.shipName}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">IMO number</div>
                    <div>{vessel.imo}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Ship Type</div>
                    <div>{vessel.vesselType}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Flag State</div>
                    <div>{vessel.flagState}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Ship owner or operator</div>
                    <div>{vessel.shipOwner || "-"}</div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="general-information__section">
              <Card theme="blue">
                <div className="general-information__info-row">
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Call sign</div>
                    <div>{vessel.callsign}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">MMSI Number</div>
                    <div>{vessel.mmsi}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Date of build</div>
                    <div>{vessel.yearBuild}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Gross Tonnage</div>
                    <div>{vessel.grossTonnage}</div>
                  </div>
                  <div className="general-information__info-column">
                    <div className="general-information__info-heading">Deadweight tonnage</div>
                    <div>{vessel.deadweight}</div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </CollapsibleBox>
        <CollapsibleBox initialOpen={true} title={"Mandatory documents for local formalities"}>
          <div className="mandatory-documents">
            <Formik
              innerRef={mandatoryDocumentsFormRef}
              initialValues={mandatoryDocumentsFormInitialValues}
              validationSchema={mandatoryDocumentsFormValidationSchema}>
              <Form className="general-information-form">
                <CheckboxGroupCustom name="mandatoryDocuments" label="" options={checkboxOptions} />
              </Form>
            </Formik>
          </div>
        </CollapsibleBox>
        <Modal
          className="port-call-modal"
          hideCloseBtn
          showModal={isGenerateFormModalOpen}
          onCloseModal={() => { dispatch(toggleGenerateFormModal()) }}>
          <div className="generate-form-modal">
            {portCallCreationStatus === "creating" && (
              <div className="generate-form-modal__in-progress">
                <FontAwesomeIcon spin icon={faSpinner} size="3x" style={{ color: "#5b8fe9" }} />
              </div>
            )}
            {portCallCreationStatus === "success" && renderFormGenerateSuccess()}
          </div>
        </Modal>
      </>
    )
  }


  return (
    <Page>
      <div className="port-clearance-form-request">
        <PageTitle>Port Clearance Form Request</PageTitle>
        {vessel && renderPageContent()}
      </div>
    </Page>
  );
}

export default PortClearanceFormRequestSelectedVessel;
