export const ANNEXES_KEYS = {
    ANNEX_ONE: 'annexOne',
    ANNEX_TWO: 'annexTwo',
    ANNEX_FOUR: 'annexFour',
    ANNEX_FIVE: 'annexFive',
    ANNEX_SIX: 'annexSix',
    OTHER_ANNEX: 'otherWaste',
};

export const WASTE_DISPOSAL_APPROACH_KEYS = {
    ALL: 'All',
    PARTIALLY: 'Partially' 
}