import React from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { useSelector } from 'react-redux';
import PageHeader from '../../../../layout/components/PageHeader';
import Page from '../../../../layout/components/Page';
import UploadDownloadBox from '../../local-formalities/components/UploadDownloadBox';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { selectShipCertificatesByTitle } from '../../documents/slices/documentsSlice';

function ShipCertificates() {
  let navigate = useNavigate();
  const params = useParams();
  const shipCertificatesByTitle = useSelector(selectShipCertificatesByTitle);

  const renderBody = () => {
    return _.isEmpty(shipCertificatesByTitle) ? renderEmptyCertificates() : renderCertificates();
  };

  const renderCertificates = () => {
    return (
      <>
        <PageHeader title={'Ship Certificates'} />
        {Object.keys(shipCertificatesByTitle).map((title, i) => {
          return (
            <div key={`doc-category-${i}`}>
              <h3>{title}</h3>
              <p>Please provide the requested ship certificates:</p>
              <div className='documents-list'>
                {shipCertificatesByTitle[title].map((document, i) => {
                  return (
                    <UploadDownloadBox
                      key={`doc-${i}`}
                      category='requestedCertificates'
                      document={document}
                      documentUid={document.documentUid}
                      isShipCertificate='true'>
                      {document.documentType.name}
                    </UploadDownloadBox>
                  );
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderEmptyCertificates = () => {
    return <PageHeader title={'Ship Certificates'} subtitle={'No certificates requested.'} />;
  };

  return (
    <Page>
      <div className='safety-documents'>
        <ProgressBar percentage={'100'} pageNumber={1} totalPages={'1'} />
        {renderBody()}
        <SkipAndNextButtons
          onClick={() => navigate(`/${params.uid}/general-declaration`)}
          next={`/${params.uid}/general-declaration`}
          back={`/${params.uid}/local-formalities`}
        />
      </div>
    </Page>
  );
}

export default ShipCertificates;
