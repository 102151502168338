import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import "../styles/radio-buttons.scss";

export default function RadioButtons(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control radio-buttons">
      <label className="radio-buttons__label">{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return (
            options.map((option, i) => {
              return (
                <div className={"radio-button" + (field.value === option.value ? " checked" : "")} key={option.key}>
                  <input
                    type="radio"
                    id={name + `-${i}`}
                    {...field}
                    value={option.value}
                    checked={field.value === option.value} />
                  <label htmlFor={name + `-${i}`}>{option.key}</label>
                </div>
              )
            })
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
