import React, { useContext, useEffect, useRef, useState } from 'react';
import { filterFilledValuesOfWasteItems, validationWasteSchema, validationDeliveryWasteSchema } from '../validators';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectGroupedWaste, selectMarpol, updateDeliveryWaste } from '../slices/marpolSlice';
import { AlertIcon } from '../styles/AlertIcon';
import { AnnexesTypesList, parseDataForDeliveryWasteEndpoint, wasteDisposalApproachOptions } from '../utils/waste';
import { Form, Formik } from 'formik';
import { NONE_WASTE_DISPOSAL_APPROACH } from '../utils/marpolUtils';
import PageHeader from '../../../../layout/components/PageHeader';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import AnnexOneTable from './AnnexOneTable';
import AnnexTwoTable from './AnnexTwoTable';
import AnnexFourTable from './AnnexFourTable';
import AnnexFiveTable from './AnnexFiveTable';
import AnnexSixTable from './AnnexSixTable';
import OtherWasteTable from './OtherWasteTable';
import '../styles/marpol.scss';
import { FormRefContext } from '../../../../global/context/FormRefContext';

export const emptyHandleSubmit = async () => {};

function MarpolTables() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);
  const firstAnnexIndex = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const marpolData = useSelector(selectMarpol);
  const currentWaste = useSelector(selectGroupedWaste);
  const [selectedAnnex, setSelectedAnnex] = useState(firstAnnexIndex);
  const initialValues = {
    wasteDisposalApproach: marpolData.wasteDisposalApproach,
  };

  const annexOneFormRef = useRef(null);
  const annexTwoFormRef = useRef(null);
  const annexFourFormRef = useRef(null);
  const annexFiveFormRef = useRef(null);
  const annexSixFormRef = useRef(null);
  const otherWasteFormRef = useRef(null);

  const showFieldsToDischargeWaste = () => {
    return marpolData.wasteToDischarge === null || marpolData.wasteToDischarge;
  };

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, currentWaste, marpolData]);

  const handleSubmit = async (values) => {
    try {
      const newWaste = parseDataForDeliveryWasteEndpoint({
        currentWaste,
        annexOneFormRef,
        annexTwoFormRef,
        annexFourFormRef,
        annexFiveFormRef,
        annexSixFormRef,
        otherWasteFormRef,
      });
      const filteredNewWaste = filterFilledValuesOfWasteItems(marpolData.wasteToDischarge, newWaste);
      const wasteSchema = validationWasteSchema(marpolData.wasteToDischarge, filteredNewWaste);
      await wasteSchema.validate({}, { abortEarly: false });

      dispatch(
        updateDeliveryWaste({
          marpolData: {
            waste: filteredNewWaste,
            wasteDisposalApproach: values.wasteDisposalApproach || NONE_WASTE_DISPOSAL_APPROACH,
          },
          uid: params.uid,
        })
      );
      navigate(`/${params.uid}/health/first-step`);
    } catch (validationError) {
      const error_div = document.querySelector('.error.marpol-error');
      error_div.innerHTML = '';
      validationError.inner?.forEach((error) => {
        if (error.message === '* You should fill at least one row.') {
          error_div.innerHTML += `<p style="color: #006AFF; font-size: 0.9rem;">${error.message}</p><br>`;
        } else {
          error_div.innerHTML += `<p>${error.message}</p><br>`;
        }
      });
    }
  };

  return (
    <div className='select-annexes marpol-tables'>
      <PageHeader title={'Marpol waste'} subtitle={`Complete the annexes.`} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationDeliveryWasteSchema(marpolData.wasteToDischarge)}>
        <Form className='select-annexes-form'>
          {showFieldsToDischargeWaste() && (
            <>
              <RadioButtonsCustom
                name='wasteDisposalApproach'
                label='Indicate the waste disposal approach:'
                options={wasteDisposalApproachOptions}
              />
            </>
          )}
          <div className='annex-tabs'>
            {AnnexesTypesList.map((annex, i) => {
              const className = `annex-tab-item${selectedAnnex === i + 1 ? ' active' : ''}`;
              return (
                <div
                  className={className}
                  key={`annex-${i}`}
                  onClick={() => {
                    setSelectedAnnex(i + 1);
                  }}>
                  {annex.label}
                  {marpolData.wasteToDischarge && <AlertIcon />}
                </div>
              );
            })}
          </div>
          {
            <AnnexOneTable
              isDisplayed={selectedAnnex === 1}
              formRef={annexOneFormRef}
              disableWasteDelivered={!marpolData.wasteToDischarge}
            />
          }
          {
            <AnnexTwoTable
              isDisplayed={selectedAnnex === 2}
              formRef={annexTwoFormRef}
              disableWasteDelivered={!marpolData.wasteToDischarge}
            />
          }
          {
            <AnnexFourTable
              isDisplayed={selectedAnnex === 3}
              formRef={annexFourFormRef}
              disableWasteDelivered={!marpolData.wasteToDischarge}
            />
          }
          {
            <AnnexFiveTable
              isDisplayed={selectedAnnex === 4}
              formRef={annexFiveFormRef}
              disableWasteDelivered={!marpolData.wasteToDischarge}
            />
          }
          {
            <AnnexSixTable
              isDisplayed={selectedAnnex === 5}
              formRef={annexSixFormRef}
              disableWasteDelivered={!marpolData.wasteToDischarge}
            />
          }
          {
            <OtherWasteTable
              isDisplayed={selectedAnnex === 6}
              formRef={otherWasteFormRef}
              disableWasteDelivered={!marpolData.wasteToDischarge}
            />
          }
          <div className='error marpol-error'></div>
          <SkipAndNextButtons
            onClick={() => handleSubmit()}
            next={`/${params.uid}/health/first-step`}
            back={`/${params.uid}/marpol/confirm-waste`}
          />
        </Form>
      </Formik>
    </div>
  );
}

export default MarpolTables;
