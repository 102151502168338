import React from "react";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import auth from "config/auth";
import { axiosInstance } from "config/axios";

export const searchForPort: AsyncThunk<any, {unlocode?: string, portName?: string}, {}> = createAsyncThunk(
    'portCall/searchPortOfEmbarkation',
    async (searchData: {unlocode?: string, portName?: string}) => {
        let endpoint = `/ports/`;
        if (searchData.unlocode) {
          endpoint += `?unlocode=${searchData.unlocode}`
        } else if (searchData.portName) {
          endpoint += `?name=${searchData.portName}`
        }
    
        const headers = auth.getHeaders();
        const response = await axiosInstance.get(endpoint, {
          headers
        });
        return response.data.data.map((port: any) => {
            return {
              value: port.unlocode,
              unlocode: port.unlocode,
              countryCode: port.countryCode,
              label: port.name + ", " + port.countryCode,
              name: port.name,
            }
          })
      }
  );