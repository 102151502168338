import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useParams, Navigate } from 'react-router-dom';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { getCrew, getCrewListDocumentLink } from '../../../slices/portCallSlice';
import { useDispatch } from 'react-redux';

function Crew() {
  const dispatch = useDispatch();
  const params = useParams();
  const [percentage, setPercentage] = useState("20");
  const [pageNumber, setPageNumber] = useState("");

  useEffect(() => {
    dispatch(getCrew(params.uid));
  }, []);

  useEffect(() => {
    dispatch(getCrewListDocumentLink(params.uid));
  }, []);


  let location = useLocation();
  useEffect(() => {
    if (location.pathname === `/${params.uid}/crew/confirm`) {
      setPercentage("20");
      setPageNumber("1");
    } else if (location.pathname === `/${params.uid}/crew/number`) {
      setPercentage("40");
      setPageNumber("2");
    } else if (location.pathname === `/${params.uid}/crew/arrival-crew`) {
      setPercentage("60");
      setPageNumber("3");
    } else if (location.pathname === `/${params.uid}/crew/departure-crew`) {
      setPercentage("80");
      setPageNumber("4");
    }
    else if (location.pathname === `/${params.uid}/crew/effects`) {
      setPercentage("100");
      setPageNumber("5");
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/crew`) {
    return <Navigate to={`/${params.uid}/crew/confirm`} replace />;
  }


  return (
    <Page>
      <div className="crew">
        <ProgressBar percentage={percentage} pageNumber={pageNumber} totalPages={"5"} />
        <Outlet />
      </div>
    </Page>
  );
}

export default Crew;
