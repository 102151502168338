import { Field, ErrorMessage } from 'formik';
import TextError from '../../TextError';
import '../../../../captains-form/styles/mandatory-check.scss'; // Ensure the styles are imported
import '../../../styles/multi-select.scss';
import ReactSelectPorts from '../../ReactSelectPorts';

export default function PortsDropdown(props) {
  const {
    label,
    name,
    onInputChange,
    getSpecificValue,
    onChange,
    hasError = false,
    errorMessage = '',
    ...rest
  } = props;

  return (
    <div className={'form-control multi-select'}>
      {label && (
        <label className='input__label' htmlFor={name}>
          {label}
        </label>
      )}
      {props.sublabel && <span className='sublabel'>{props.sublabel}</span>}
      <Field
        name={name}
        id={name}
        onChange={onChange}
        isMulti={true}
        component={ReactSelectPorts}
        onInputChange={onInputChange}
        getSpecificValue={getSpecificValue}
        className={hasError ? 'input-error-border' : ''}
        {...rest}
      />
      {hasError && <div className='error-dropdown'>{errorMessage}</div>}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
