import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useParams, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getIsps } from '../../../slices/portCallSlice';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';

function Solas() {
  const params = useParams();
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState("50");
  const [pageNumber, setPageNumber] = useState("");

  useEffect(() => {
    dispatch(getIsps(params.uid));
  }, [])

  let location = useLocation();

  useEffect(() => {
    if (location.pathname === `/${params.uid}/solas-and-isps-code/questions`) {
      setPercentage("25");
      setPageNumber("1");
    } else if (location.pathname === `/${params.uid}/solas-and-isps-code/port-calls`) {
      setPercentage("70");
      setPageNumber("2");
    } else if (location.pathname === `/${params.uid}/solas-and-isps-code/security-measures`) {
      setPercentage("100");
      setPageNumber("3");
    }
  }, [location.pathname]);

  if (location.pathname === `/${params.uid}/solas-and-isps-code`) {
    return <Navigate to={`/${params.uid}/solas-and-isps-code/questions`} replace />;
  }


  return (
    <Page>
      <div className="solas">
        <ProgressBar percentage={percentage} pageNumber={pageNumber} totalPages={"3"} />
        <Outlet />
      </div>
    </Page>
  );
}

export default Solas;
