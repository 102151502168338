import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import PageHeader from '../../../../layout/components/PageHeader';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { stateParticularColumns } from '../utils/healthUtils';
import { booleanOptionsForRadioGroup } from 'features/forms/helpers/random-form-helpers';
import Input from 'features/forms/components/Input';
import Table, { DeleteModalData } from 'features/tables/components/Table';
import StateParticularModal from './StateParticularModal';
import "../styles/health.scss";
import { useDispatch, useSelector } from 'react-redux';
import { selectHealthDeclaration, updateHealthDeclaration } from '../slices/healthSlice';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import { FormRefContext } from '../../../../global/context/FormRefContext';

interface FormValues {
  familyName: string;
  givenName: string;
  classOrRating: string;
  age: number | null;
  gender: string;
  nationality: string;
  portOfEmbarkation: string | null;
  dateOfEmbarkation: string;
  natureOfIllness: string;
  dateOfOnsetOfSymptoms: string;
  reportedToPortMedicalOfficer: boolean;
  state: string;
  disposalOfCase: string;
  evacuatedAtPort: string;
  treatmentGiven: string;
  comments: string;
}

const initialValues: FormValues = {
  familyName: "",
  givenName: "",
  classOrRating: "",
  age: null,
  gender: "",
  nationality: "",
  portOfEmbarkation: null,
  dateOfEmbarkation: "",
  natureOfIllness: "",
  dateOfOnsetOfSymptoms: "",
  reportedToPortMedicalOfficer: false,
  state: "",
  disposalOfCase: "",
  evacuatedAtPort: "",
  treatmentGiven: "",
  comments: "",
}


const SecondHealthStep = () => {
  const formRef = useRef<FormikProps<any>>(null);
  const {formContextRef} = useContext(FormRefContext)
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const healthDeclaration = useSelector(selectHealthDeclaration);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditingTable, setIsEditingTable] = useState<boolean>(false);

  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState<boolean>(false);
  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState<DeleteModalData>({
    index: null,
    showModal: false
  });

  const validationSchema = Yup.object({
    deaths: Yup.boolean(),
    deathsNumber: Yup.number().nullable(),
    infections: Yup.boolean(),
    notNormalIllPassangersNumber: Yup.boolean(),
    illPeople: Yup.number().nullable(),
    illPersonOnBoard: Yup.boolean(),
    medicalPractitionerConsulted: Yup.boolean(),
    conditionsForInfection: Yup.boolean(),
  });
  const isEditingRow = editingRow !== null;

  const handleSubmit = async (values: any) => {

    dispatch(updateHealthDeclaration({
      uid: params.uid as string,
      healthData: {
        ...values,
        stateParticulars: healthDeclaration.stateParticulars
      }
    }) as any);
  };

  useEffect(() => {
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef,healthDeclaration])

  const handleStateParticularData = (data: any) => {
    let stateParticulars = [...healthDeclaration.stateParticulars];

    if (isEditingRow) {
      stateParticulars[editingRow as number] = data;
    } else {
      stateParticulars.push(data);
    }

    dispatch(updateHealthDeclaration({
      uid: params.uid || '',
      healthData: {
        stateParticulars: stateParticulars
      }
    }) as any)
  }

  const handleDeleteAllMembers = () => {
    setShowConfirmDeleteAllMembersModal(false);

    dispatch(updateHealthDeclaration({
      uid: params.uid as string,
      healthData : {
        stateParticulars: []
      }
    }) as any)
  }

  const handleDeleteSingleMember = () => {
    const stateParticulars = [...healthDeclaration.stateParticulars];
    stateParticulars.splice(confirmDeleteMemberModalData.index as number, 1);

    dispatch(updateHealthDeclaration({
        uid: params.uid as string,
        healthData : {
          stateParticulars: stateParticulars
        }
      }) as any)

    setConfirmDeleteMemberModalData({
      index: null,
      showModal: false
    })
  }
  
  const parseReportedToPortMedicalOfficer = (data: any) => {
    const copy = JSON.parse(JSON.stringify(data));
    copy.forEach((particular: any) => {
    particular.reportedToPortMedicalOfficer = particular.reportedToPortMedicalOfficer ? "Reported" : "Not reported";
    })
    return copy
  }

  return (
    <div className="health-wrapper">
      <StateParticularModal
        isEditingRow={isEditingRow}
        editingRow={editingRow}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setEditingRow={setEditingRow}
        setData={ handleStateParticularData }
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to <span>delete all the <br />particulars</span> from the list?</h3>}
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => handleDeleteAllMembers()}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this particular?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false
          })
        }}
        onDeleteClick={() => handleDeleteSingleMember()}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <PageHeader 
        title={"Maritime declaration of health"}
        subtitle={"Please, answer the following questions"}
      />
      <Formik
      innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={healthDeclaration}
        validationSchema={validationSchema}>
        {({ values }) => (
          <Form className="health-declaration-form">
            <div className="health-declaration-form__controls">
              <div className="health-declaration-form__row">
                <RadioButtonsCustom 
                  name="hasAnyPersonDiedOtherwiseThanAccident" 
                  label="1. Has any person died on board during the voyage otherwise than as a result of accident?"
                  description="If yes, state particulars in the table below."
                  options={booleanOptionsForRadioGroup()} />
                  {values.hasAnyPersonDiedOtherwiseThanAccident && (
                    <Input name="howManyDeadPeople" type="number" placeholder="Ex: 2" label="Total nº of deaths" />
                  )}
              </div>
              <div className="health-declaration-form__row">
                <RadioButtonsCustom 
                  name="hasAnyCaseOfInfectiousDisease" 
                  label="2. Is there on board or has there been during the international voyage any case of disease which you suspect to be of an infectious nature?" 
                  description="If yes, state particulars in the table below."
                  options={booleanOptionsForRadioGroup()} />
              </div>
              <div className="health-declaration-form__row">
                <RadioButtonsCustom 
                  name="hasTheTotalNumberOfIllPassengersGreaterThanNormal" 
                  label="3. Has the total of number of ill passengers during voyage been greater than normal/expected?" 
                  options={booleanOptionsForRadioGroup()} />
                {values.hasTheTotalNumberOfIllPassengersGreaterThanNormal && (
                  <Input name="howManyIllPeople" type="number" placeholder="Ex: 2" label="How many ill people?" />
                )}
              </div>
              <div className="health-declaration-form__row">
                <RadioButtonsCustom 
                  name="isThereAnyIllPeopleOnBoard" 
                  label="4. Is there any ill person on board?" 
                  description="If yes, state particulars in the table below."
                  options={booleanOptionsForRadioGroup()} />
              </div>
              <div className="health-declaration-form__row">
                <RadioButtonsCustom 
                  name="wasAMedicalPractitionerConsulted" 
                  label="5. Was a medical practitioner consulted?" 
                  description="If yes, state particulars of medical treatment or advice provided in the table below."
                  options={booleanOptionsForRadioGroup()} />
              </div>
              <div className="health-declaration-form__row">
                <RadioButtonsCustom 
                  name="areYouAwareOfAnyConditionWhichMayLeadToSpreadOfDisease" 
                  label="6. Are you aware of any condition on board which may lead to infection or spread of disease?" 
                  description="If yes, state particulars in the table below."
                  options={booleanOptionsForRadioGroup()} />
              </div>
              <div className="health-declaration-form__row">
                <Table
                  title={'Attachment to model of Maritime Declaration of Health'}
                  addButtonText={'State particular'}
                  data={parseReportedToPortMedicalOfficer(healthDeclaration.stateParticulars)}
                  columns={stateParticularColumns}
                  editingMode={isEditingTable}
                  setEditingMode={setIsEditingTable}
                  setIsAddModalOpen={setIsModalOpen}
                  setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
                  onEditButtonClick={(rowIndex) => {
                    setIsModalOpen(true);
                    setEditingRow(rowIndex);
                  }}
                  onDeleteButtonClick={(rowIndex) => {
                    setConfirmDeleteMemberModalData({
                      showModal: true,
                      index: rowIndex
                    });
                  }}
                />
              </div>
            </div>
            <SkipAndNextButtons
              onClick={()=>navigate(`/${params.uid}/health/third-step`)}
              next={`/${params.uid}/health/third-step`}
              back={`/${params.uid}/health/first-step`}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SecondHealthStep;
