import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectVesselBunkeringDetailsData,
  getVesselBunkeringDetails,
  updateVesselBunkeringDetails,
} from '../../../slices/portCallSlice';
import { booleanOptionsForRadioGroup } from '../../../../forms/helpers/random-form-helpers';
import SkipAndNextButtons from './SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import RadioButtons from '../../../../forms/components/RadioButtonsCustom';
import '../../../styles/bunkering-detail.scss';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import Input from '../../../../forms/components/Input';
import { removeNullUndefined } from '../../../../global/utils/cleanEmptyData';
import BunkeringCheckboxGroup from '../../../../forms/components/BunkeringCheckboxGroup';

const validationSchema = Yup.object({
  purposeOfCall: Yup.string(),
});

const booleanOptionsForRadio = booleanOptionsForRadioGroup();
const verticalBunkeringManifoldOpts = [
  { key: 'Aft', value: 'AFT' },
  { key: 'Mid', value: 'MID' },
  { key: 'Forward', value: 'FORWARD' },
  { key: 'Superstr.', value: 'SUPERSTRUCTURE' },
];
const horizontalBunkeringManifoldOpts = [
  { key: 'Port', value: 'PORT' },
  { key: 'Centre', value: 'CENTRE' },
  { key: 'Starb.', value: 'STARBOARD' },
];

function BunkeringDetail() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  let bunkeringDetailsData = useSelector(selectVesselBunkeringDetailsData);

  useEffect(() => {
    dispatch(getVesselBunkeringDetails(params.uid));
  }, [dispatch, params.uid]);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, bunkeringDetailsData]);

  let initialValues = {
    ...bunkeringDetailsData,
  };

  const handleSubmit = async (values, onSubmitProps) => {
    dispatch(
      updateVesselBunkeringDetails({
        uid: params.uid,
        data: removeNullUndefined(values),
      })
    );
  };

  return (
    <div className='bunkering-detail'>
      <PageHeader
        title={'Bunkering Details'}
        subtitle={'Provide the below information for a safe bunkering operation.'}
      />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form className='bunkering-detail-form'>
          <div className='bunkering-detail-form__controls'>
            <div className='bunkering-detail-form__first-row'>
              <div className='bunkering-detail-form__first-column'>
                <Input
                  name='rateReceptionPerHour'
                  placeholder='Rate reception per hour (m3/h)'
                  label='Rate reception per hour (m3/h)'
                />
                <Input
                  name='diameterConnection'
                  placeholder='Diameter connection (cm)'
                  label='Diameter connection (cm)'
                />
                <Input
                  name='lengthBetweenPerpendiculars'
                  placeholder='Length between perpendiculars (m)'
                  label='Length between perpendiculars (m)'
                />
                <RadioButtons
                  name='canReceiveDifferentGradesSimultaneously'
                  label='Can you receive grades simultaneously?'
                  options={booleanOptionsForRadio}
                />
                <RadioButtons
                  name='canReceiveDifferentProductsSimultaneously'
                  label='Can you receive different products simultaneously?'
                  options={booleanOptionsForRadio}
                />
                <RadioButtons
                  name='haveCertifiedPersonnelToAccessBarge'
                  label='Do you have a certified personnel transfer to access the barge?'
                  options={booleanOptionsForRadio}
                />
                <BunkeringCheckboxGroup
                  name='bunkerManifold.situation.manifoldPosition2'
                  label='Vertical manifold position'
                  options={verticalBunkeringManifoldOpts}
                />
                <Input
                  name='bunkerManifold.situation.heightAboveWaterline'
                  placeholder='Height above waterline'
                  label='Height above waterline'
                />
                <Input
                  name='bunkerManifold.sizeOfConnectionGradeAndType'
                  placeholder='Size of connection for each grade and type (Flange ANSI/DIN)'
                  label='Size of connection for each grade and type (Flange ANSI/DIN)'
                />
              </div>
              <div className='bunkering-detail-form__second-column'>
                <Input name='maxPressure' placeholder='Max pressure (bar)' label='Max pressure (bar)' />
                <Input
                  name='bollardsLocationFromStern'
                  placeholder='Bollards location from stern'
                  label='Bollards location from stern'
                />
                <Input
                  name='freeBoardOnArrival'
                  placeholder='Free board on arrival (m)'
                  label='Free board on arrival (m)'
                />
                <RadioButtons
                  name='isDeckCraneAvailable'
                  label='Do you have a deck crane available to lift barage hoses?'
                  options={booleanOptionsForRadio}
                />
                <RadioButtons
                  name='isVesselFittedWithScrubber'
                  label='Is the vessel fitted with a scrubber?'
                  options={booleanOptionsForRadio}
                />
                <BunkeringCheckboxGroup
                  name='bunkerManifold.situation.manifoldPosition1'
                  label='Horizontal manifold position'
                  options={horizontalBunkeringManifoldOpts}
                />
                <Input
                  name='bunkerManifold.situation.distanceFromStern'
                  placeholder='Distance from stern'
                  label='Distance from stern'
                />
                <Input
                  name='bunkerManifold.situation.distanceFromPortSide'
                  placeholder='Distance from port side'
                  label='Distance from port side'
                />
              </div>
            </div>
          </div>
          <SkipAndNextButtons
            next={`/${params.uid}/port-call-data/drafts`}
            back={`/${params.uid}/port-call-data/purpose`}
            onClick={() => navigate(`/${params.uid}/port-call-data/drafts`)}
          />
        </Form>
      </Formik>
    </div>
  );
}

export default BunkeringDetail;
