import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import DeparturePassengersTable from './DeparturePassengersTable';
import { selectPassengers, setDeparturePassengersChanged, updatePassengers } from '../../../slices/portCallSlice';


const passengersChangesOptions = booleanOptionsForRadioGroup();

const validationSchema = Yup.object({
  passengersChanges: Yup.boolean()
})

function DeparturePassengersChanges() {
  const dispatch = useDispatch();
  const formRef = useRef();
  let navigate = useNavigate();
  const params = useParams();
  const passengers = useSelector(selectPassengers);

  const initalValues = {
    passengersChanges: passengers.app.departurePassengersChanged
  };

  const handleSubmit = async (values, onSubmitProps) => {
    navigate(`/${params.uid}/cargo/information`);
  };


  return (
    <div className="passengers-changes">
      <PageHeader title={"Passengers"} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initalValues}
        validationSchema={validationSchema}>
        {({ values }) => {
          return (
            <>
              <Form className="passengers-changes-form">
                <RadioButtonsCustom
                  onOptionClick={(val) => {
                    dispatch(setDeparturePassengersChanged({
                      departurePassengersChanged: val
                    }));

                    if (!val) {
                      dispatch(updatePassengers({
                        uid: params.uid,
                        data: {
                          departure: passengers.arrival
                        }
                      }));
                    }
                  }}
                  name="passengersChanges"
                  label="Any change of passengers during port stay?"
                  options={passengersChangesOptions} />
              </Form>

              <DeparturePassengersTable disabled={!values.passengersChanges} />
            </>
          )
        }}
      </Formik>
      <SkipAndNextButtons
        onClick={() => formRef.current.submitForm()}
        next={`/${params.uid}/cargo/information`}
        back={`/${params.uid}/passengers/arrival-passengers`} />
    </div>
  );
}

export default DeparturePassengersChanges;
