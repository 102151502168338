import React from 'react';
import Button from './Button';
import xIcon from "../styles/images/xIcon.svg";

export default function DiscardButton(props) {
  const { onClick, ...rest } = props;
  return (
    <Button theme="danger" onClick={onClick} iconLeft={xIcon} {...rest}>Discard changes</Button>
  );
}
