import React from 'react';
import "../styles/button.scss";

export default function Button(props) {
  const { onClick, children, iconLeft, iconMiddle, iconRight, theme, ...rest } = props;
  let { className } = props;

  if (theme) {
    className = className ? (className + ` ${theme}`) : theme;
  }

  return (
    <button
      onClick={onClick ? onClick : undefined}
      type="button"
      {...rest}
      className={`button${className ? (" " + className) : ""}`}
    >
      {iconLeft && <img className="button__icon-left" src={iconLeft} alt="btn-icon" />}
      {iconMiddle && <img className="button__icon-middle" src={iconMiddle} alt="btn-icon" />}
      {children}
      {iconRight && <img className="button__icon-right" src={iconRight} alt="btn-icon" />}
    </button>
  );
}
