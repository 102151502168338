export const JWT = 'JWT';

//Authentication helper file used to store/delete token
const Auth = {
	storeToken(token) {
		return localStorage.setItem(JWT, token);
	},

	clearSession() {
		localStorage.removeItem(JWT);
	},

	getToken() {
		return localStorage.getItem(JWT);
	},

	getHeaders() {
		const token = this.getToken();
		return {
			Authorization: `Bearer ${token}`
		};
	}
}

export default Auth;
