import React from 'react';
import "../styles/page-title.scss";

export default function PageTitle(props) {
  return (
    <h2 className="page-title">
      {props.children}
    </h2>
  );
}

