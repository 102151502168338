import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { theme } from "@navozyme/uikit/dist/theme";
import { NavozymeProvider } from "@navozyme/uikit/dist/NavozymeProvider";
import { store } from './app/store';
import Routes from './Routes';
import * as Sentry from "@sentry/react";
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [new Sentry.BrowserTracing()],

    tracesSampleRate: 1.0,
});

root.render(
  <React.StrictMode>
      <NavozymeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </NavozymeProvider>
  </React.StrictMode>
);
