import React from 'react';
import Collapsible from 'react-collapsible';
import ArrowCollapse from "../styles/images/arrow-collapse.svg";
import ArrowExpand from "../styles/images/arrow-expand.svg";
import "../styles/collapsible-box.scss";

function CollapsibleBoxTrigger(props) {
  return (
    <div className="collapsible-box__trigger">
      {props.icon}
      <h5>{props.title}</h5>
    </div>
  )
}

function CollapsibleBox(props) {
  return (
    <Collapsible
      classParentString="collapsible-box"
      transitionTime={150}
      open={props.initialOpen || false}
      trigger={<CollapsibleBoxTrigger title={props.title} icon={<img src={ArrowCollapse} alt="ArrowCollapse" />} />}
      triggerWhenOpen={<CollapsibleBoxTrigger title={props.title} icon={<img src={ArrowExpand} alt="ArrowExpand" />} />}
    >
      {props.children}
    </Collapsible>
  );
}

export default CollapsibleBox;
