import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Modal from "../../../../layout/components/Modal";
import Button from "../../../../layout/components/Button";
import RoundedCheckIcon from "../../../../layout/styles/images/rounded-check-icon.svg";
import "../styles/instructions.scss";


const SendConfirmationModal = function (props) {
  const navigate = useNavigate();

  const onCloseClick = () => {
    props.setIsConfirmationModalOpen(false);
    navigate(`/blocked`)
  };

  return (
    <Modal
      className={`form-sent-confirmation-modal`}
      hideCloseBtn={true}
      showModal={props.isConfirmationModalOpen}
      onCloseModal={() => { props.setIsConfirmationModalOpen(false); }}
    >
      <ReactSVG src={RoundedCheckIcon} />
      <p>Port Clearance form sent!</p>
      <Button className="generate-form-btn" onClick={onCloseClick} >
        Close
      </Button>
    </Modal>
  );
}

export default SendConfirmationModal;