import React from 'react';
import AskIcon from "../../forms/styles/images/ask-icon.svg"
import Button from '../../layout/components/Button';
import "../../layout/styles/blocked-page-layout.scss";

export default function BlockedPage(props) {
  const goToSupportWeb = () => {
    window.location.href = `https://nmap-support.freshdesk.com/support/tickets/new`;
  };

  return (
    <div className="blocked-page-layout">
      <main>
        <header>
          <h3>Validation in progress.</h3>
          <p>Give your agent time to check the data provided.</p>
        </header>
        <footer>
          <Button onClick={goToSupportWeb} iconLeft={AskIcon}>
            Need help? Contact support.
          </Button>
        </footer>
      </main>
    </div>
  );
}
