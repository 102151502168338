import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../../config/axios';
import auth from '../../../../../config/auth';


const initialState = {
	searchResults: {},
	loading: {},
};


export const searchPlaceOfBirth = createAsyncThunk(
	'placeOfBirth/searchPlaceOfBirth',
	async ({ dropdownID, value, countryCode, location }) => {
		const headers = auth.getHeaders();
		let endpoint = `/unlocodes`
		if (value){
			endpoint += `?locationName=${value}`;
		}
		if (location){
			endpoint += `?location=${location}`;
		}
		if (countryCode){
			endpoint += `&country=${countryCode}`;
		}
		let response = await axiosInstance.get(endpoint, {
			headers
		});

		const data = response.data.data.map(({country,location,name}) => {
			return {
				label: name + ", " + country,
				value: `${country}${location}`
			}
		});

		return {
			dropdownID,
			data
		}
	}
);


export const placeOfBirthSlice = createSlice({
	name: 'placeOfBirth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(searchPlaceOfBirth.pending, (state, { meta }) => {
				const { dropdownID } = meta.arg;
				state.loading[dropdownID] = true;
			})
			.addCase(searchPlaceOfBirth.fulfilled, (state, action) => {
				const { dropdownID, data } = action.payload;
				state.searchResults[dropdownID] = data;
				state.loading[dropdownID] = false;
			})
			.addCase(searchPlaceOfBirth.rejected, (state, { meta }) => {
				const { dropdownID } = meta.arg;
				state.loading[dropdownID] = false;
			})
	},
});

export const { } = placeOfBirthSlice.actions;


export const selectPlaceOfBirthSearchResults = (state, dropdownId) => state.placeOfBirth.searchResults[dropdownId];
export const selectPlaceOfBirthLoadingState = (state, dropdownId) => state.placeOfBirth.loading[dropdownId];


export default placeOfBirthSlice.reducer;
