import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../../../forms/components/Input";
import { selectAnnexTwo } from "../slices/marpolSlice";
import { emptyHandleSubmit } from "./MarpolTables"
import WasteRow from "./WasteRow";
import WasteHeader from "./WasteHeader";

export default function AnnexTwoTable(props) {
  const annexTwo = useSelector(selectAnnexTwo);
  const [initialValues, setInitialValues] = useState(annexTwo || {});

  useEffect(() => {
    setInitialValues(annexTwo || {});
  }, [props.disableWasteDelivered, annexTwo]);

  return (
    <div className="marpol-table annex-two-table" style={{ display: props.isDisplayed ? "flex" : "none" }}>
      <WasteHeader />
      <Formik
        innerRef={props.formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={emptyHandleSubmit}>
        {({values}) => (
          <Form className="cargo-information-form">
            <div className="marpol-table__body">
              <WasteRow 
                wasteName="Category X substance"
                imoWasteCode="categoryXSubstance"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                additionalComponent={<Input name={`categoryXSubstance.name`} placeholder="Specify" />}
                { ...values?.categoryXSubstance }
              />
              <WasteRow 
                wasteName="Category Y substance"
                imoWasteCode="categoryYSubstance"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                additionalComponent={<Input name={`categoryYSubstance.name`} placeholder="Specify" />}
                { ...values?.categoryYSubstance }
              />
              <WasteRow 
                wasteName="Category Z substance"
                imoWasteCode="categoryZSubstance"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                additionalComponent={<Input name={`categoryZSubstance.name`} placeholder="Specify" />}
                { ...values?.categoryZSubstance }
              />
              <WasteRow 
                wasteName="OS – Other substances"
                imoWasteCode="osOtherSubstances"
                isWasteToBeDeliveredDisabled={props.disableWasteDelivered}
                additionalComponent={<Input name={`osOtherSubstances.name`} placeholder="Specify" />}
                { ...values?.osOtherSubstances }
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
