import MultiSelect from 'features/forms/components/MultiSelect';
import { Port } from 'features/global/ports';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import Input from '../../../../forms/components/Input';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import Button from '../../../../layout/components/Button';
import Modal from "../../../../layout/components/Modal";
import plusIcon from "../../../../tables/styles/images/plusIcon.svg";
import { selectHealthDeclaration } from '../slices/healthSlice';
import { searchForPort } from '../slices/portCallSlice';
import "../styles/health.scss";
import { sanitaryMeasureTypes } from '../utils/healthUtils';
// @ts-ignore
import { DatePickerTimeInput } from "@navozyme/uikit/dist/molecules/DatePicker";
// @ts-ignore
import { Field } from "@navozyme/uikit/dist/atoms/Field";

interface SanitaryMeasureModalProps {
  isEditingRow: boolean;
  isModalOpen: boolean;
  editingRow: number | null;
  setIsModalOpen: (newValue: boolean) => void;
  setEditingRow: (newValue: number | null) => void;
  setData: (newValue: any) => void;
}

interface FormValues {
  portName: string;
  unlocode: string;
  typeOfSanitaryMeasure: string;
  dateFrom: Date | string | null;
  dateTo: Date | string | null;
  portNameDropdown: Port | null;
}

export const SanitaryMeasureModal: React.FC<SanitaryMeasureModalProps> = ({
  isEditingRow,
  isModalOpen,
  editingRow,
  setIsModalOpen,
  setEditingRow,
  setData
}) => {

  const [initialValues, setInitialValues] = useState<FormValues>({
    portName: "",
    unlocode: "",
    typeOfSanitaryMeasure: "",
    dateFrom: null,
    dateTo: null,
    portNameDropdown: null
  });
  const [fromDate, setFromDate] = useState(initialValues?.dateFrom);
  const dispatch = useDispatch();

  const healthDeclaration = useSelector(selectHealthDeclaration);

  const validationSchema = Yup.object({
    portName: Yup.string().required("* This field is required"),
    dateFrom: Yup.string().required("* This field is required"),
    dateTo: Yup.string().required("* This field is required"),
    typeOfSanitaryMeasure: Yup.string().required("* Please select one")
  });

  const handleSubmit = async (values: FormValues) => {
    const finalValues: FormValues = { ...values };
    if (values.dateFrom instanceof Date) {
      finalValues.dateFrom = values.dateFrom.toISOString();
    }
    if (values.dateTo instanceof Date) {
      finalValues.dateTo = values.dateTo.toISOString();
    }

    setData(finalValues)
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isEditingRow) {
      healthDeclaration.sanityMeasures.forEach((sanityMeasure: any, index: number) => {
        if (index === editingRow) {
          const dateFrom = new Date(sanityMeasure.dateFrom);
          const dateTo = new Date(sanityMeasure.dateTo);

          dispatch(searchForPort(
            { unlocode: healthDeclaration.sanityMeasures[editingRow as number].unlocode }
          ) as any).then((response: any) => {
            const portOfEmbarkation = response.payload[0];

            setInitialValues({
              portName: sanityMeasure.portName,
              unlocode: sanityMeasure.unlocode,
              typeOfSanitaryMeasure: sanityMeasure.typeOfSanitaryMeasure,
              dateFrom: dateFrom,
              dateTo: dateTo,
              portNameDropdown: portOfEmbarkation
            })
          })
        }

      })
    }
  }, [isEditingRow, editingRow])

  return (
    <Modal
      title={"Sanitary measure"}
      className={`health-modal`}
      showModal={isModalOpen}
      onCloseModal={() => {
        setIsModalOpen(false);
      }}>
      <div className="health-modal__content">
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ values, setValues, errors }) => {
            return (
              <Form className="health-form">
                <div className="health-form__row">
                  <SearchPortsDropdown
                    label="Port"
                    name="portNameDropdown"
                    onChange={(port: Port) => {
                      setInitialValues({
                        ...values,
                        portName: port.name,
                        unlocode: port.unlocode
                      });
                    }}
                    dropdownID="healthDeclaration.port"
                    placeholder="Type One" />
                  <Input
                    value={values.unlocode}
                    name="portUnLoCode"
                    disabled
                    label="UNLOCODE" />
                </div>
                <div className="health-form__row">
                  <MultiSelect
                    name="typeOfSanitaryMeasure"
                    label="Type of sanitary measure"
                    options={sanitaryMeasureTypes}
                    isMulti={false}
                    placeholder="Choose one" />
                  <Field label="Date From" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.dateFrom + "dateFrom"}
                      name="dateFrom"
                      sizeInput="large"
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={
                        initialValues?.dateFrom
                      }
                      action={({ isoDate }: any) => {
                        setFromDate(new Date(isoDate))
                        setValues({ ...values, dateFrom: new Date(isoDate) })
                      }}
                      hasError={errors?.dateFrom}
                      errorMessage={errors?.dateFrom}
                    />
                  </Field>
                  <Field label="Date To" className="field_date_picker">
                    <DatePickerTimeInput
                      key={initialValues?.dateTo + "dateTo"}
                      name="dateTo"
                      sizeInput="large"
                      showTime={false}
                      defaultValue={
                        initialValues?.dateTo
                      }
                      action={({ isoDate }: any) =>
                        setValues({ ...values, dateTo: new Date(isoDate) })
                      }
                      minDate={fromDate || initialValues?.dateFrom}
                      hasError={errors?.dateTo}
                      errorMessage={errors?.dateTo}
                    />
                  </Field>
                </div>
                {
                  isEditingRow ? (
                    <div className="add-member__form-submission-btns">
                      <div className="discard-btn" onClick={() => {
                        setIsModalOpen(false);
                        setTimeout(() => setEditingRow(null), 300)

                      }}>Discard</div>
                      <Button type="submit">Change Information</Button>
                    </div>
                  ) : (
                    <div className="add-member__form-submission-btns">
                      <Button iconLeft={plusIcon} type="submit">Add</Button>
                    </div>
                  )
                }
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal >
  );
};


export default SanitaryMeasureModal;
